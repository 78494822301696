import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import compose from 'recompose/compose';
import Dropzone from 'react-dropzone';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { takeImportFile, setImportFile, setImportFileSuccess, removeImportFile } from 'containers/Merchandise/actions';
import { setPopup } from 'containers/Popup/actions';
import Close from 'assets/quit.svg';
import AttachBlueIcon from 'assets/attach-file.svg';
import FileIcon from 'assets/file.svg';
import ErrorIcon from 'assets/error.svg';
import STYLE from 'styles/_constants.scss';
import './style.scss';
import { UPLOAD_CSV } from 'helpers/Const';
import DownloadBtn from './components/DownloadBtn';
const styles = () => ({
  root: {
    width: '800px',
    margin: 0,
    maxWidth: 'unset',
    borderRadius: '4px',
    backgroundColor: '#FFFFFF'
  },
  dialogTitle: {
    marginTop: '56px',
    padding: '0px',
    fontSize: '40px',
    color: '#3c3c3c',
    lineHeight: '56px',
    fontFamily: "'Sawarabi Mincho', serif",
    display: 'flex',
    justifyContent: 'center'
  },
  btn: {
    minHeight: '32px',
    fontWeight: 'bold',
    width: '148px',
    fontSize: '12px',
    padding: '0px 0px',
    fontFamily: `${STYLE.fontFamily}`
  },
  descriptionBtn: {
    width: '112px'
  },
  exportBtn: {
    marginLeft: '16px',
    width: '135px'
  }
});
class ImportPopup extends React.Component {
  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
    this.state = {
      error: false
    };
  }

  handleClose = () => {
    this.setState({ error: false });
    this.props.setPopup('importCSV', false);
    this.props.setImportFileSuccess();
  };

  handleInput = () => {
    this.setState({ error: false });
    this.props.removeImportFile();
    this.fileInput.current.click();
  };

  onFileInput = e => {
    e.preventDefault();
    e.stopPropagation();
    const file = _.get(e, 'target.files');
    if (!_.isEmpty(file)) {
      const fileNameArr = _.get(e, 'target.files[0].name', '').split('.');
      const extension = fileNameArr[fileNameArr.length - 1];
      const size = _.get(e, 'target.files[0].size');
      if (extension !== UPLOAD_CSV.EXTENSION && size >= UPLOAD_CSV.FILE_SIZE) {
        return this.setState({ error: true });
      }
      this.props.setFile(e.target.files[0]);
    }
  };

  onDrop = (acceptedFiles, rejectedFiles) => {
    this.setState({ error: false });
    if (Array.isArray(rejectedFiles) && rejectedFiles.length > 0) {
      this.setState({ error: true });
    }
    this.props.setFile(acceptedFiles[0]);
  };

  onSubmit = () => {
    const { merchandiseFile } = this.props;
    if (merchandiseFile) {
      this.props.setPopup('importCSV', false);
      this.props.submitFile({ file: merchandiseFile });
    }
  };
  render() {
    const { classes, isImportPopup = false, merchandiseFile } = this.props;
    const { error } = this.state;
    return (
      <Dialog classes={{ paper: classes.root }} className="csv-popup" fullWidth open={isImportPopup}>
        <img onClick={this.handleClose} alt="close-btn" className="close-btn" src={Close} />
        <div>
          <DialogTitle disableTypography={true} classes={{ root: classes.dialogTitle }}>
            {UPLOAD_CSV.IMPORT_TITLE}
          </DialogTitle>
          <DialogContent className="dialog-content">
            <Dropzone multiple={false} onDrop={this.onDrop} maxSize={UPLOAD_CSV.FILE_SIZE} accept={'.csv'}>
              {({ getRootProps, getInputProps }) => {
                return (
                  <div {...getRootProps()} className={`csv-drop-field ${error ? 'error-file' : 'valid-file'}`}>
                    <img alt="attach-icon" src={AttachBlueIcon} onClick={this.handleInput} />
                    <div className="size-info-div">{UPLOAD_CSV.LIMIT_UPLOAD_TEXT}</div>
                    {error && (
                      <div className="error-content">
                        <img alt="error-icon" className="error-icon" src={ErrorIcon} />
                        <div className="error-text">{UPLOAD_CSV.INVALID_FILE}</div>
                      </div>
                    )}
                    <input
                      {...getInputProps()}
                      type="file"
                      ref={this.fileInput}
                      style={{ display: 'none' }}
                      onChange={this.onFileInput}
                      accept={'.csv'}
                    />
                    {merchandiseFile && (
                      <div className="file-name-display">
                        <img alt="file-icon" src={FileIcon} />
                        <div>{_.get(merchandiseFile, 'name')}</div>
                      </div>
                    )}
                  </div>
                );
              }}
            </Dropzone>
            <div className="instruction-csv-container">
              <div className="instruction-line"> {UPLOAD_CSV.TEMPLATE_DOWNLOAD} </div>
              <div className="instruction-btn-container">
                <DownloadBtn
                  link="/CSV_Import_Instruction.pdf"
                  text="説明ファイル"
                  className={classes.descriptionBtn}
                />
                <DownloadBtn link="/example.csv" text="CSVテンプレート" className={classes.exportBtn} />
              </div>
            </div>
            <div className="btn-container">
              <Button className="custom-btn back-btn" onClick={this.handleClose}>
                {UPLOAD_CSV.CANCEL_BTN}
              </Button>
              <Button className="custom-btn calc-btn" onClick={this.onSubmit}>
                {UPLOAD_CSV.UPLOAD_BTN}
              </Button>
            </div>
          </DialogContent>
        </div>
      </Dialog>
    );
  }
}

ImportPopup.propTypes = {
  setPopup: PropTypes.func,
  isImportPopup: PropTypes.bool,
  classes: PropTypes.object,
  merchandiseFile: PropTypes.any,
  setFile: PropTypes.func,
  submitFile: PropTypes.func,
  importCsv: PropTypes.func,
  setImportFileSuccess: PropTypes.func,
  removeImportFile: PropTypes.func
};

const mapStateToProps = state => ({
  isImportPopup: _.get(state, 'popup.importCSV'),
  merchandiseFile: _.get(state, 'merchandiseReducer.importFile')
});

const mapDispatchToProps = dispatch => ({
  setImportFileSuccess: () => dispatch(setImportFileSuccess()),
  removeImportFile: () => dispatch(removeImportFile()),
  submitFile: file => dispatch(setImportFile(file)),
  setFile: file => dispatch(takeImportFile(file)),
  setPopup: (formName, isOpen) => dispatch(setPopup(formName, isOpen))
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(ImportPopup);
