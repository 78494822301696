import React from 'react';
import _ from 'lodash';
import SuccessIcon from 'assets/success-csv.svg';
import FailIcon from 'assets/fail-csv.svg';
import { withStyles } from '@material-ui/core';
import PropsTypes from 'prop-types';

const styles = () => ({
  statusCircle: {
    width: '160px',
    height: '160px',
    borderRadius: '50%',
    position: 'relative',
    marginBottom: '20px'
  },
  statusIcon: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    margin: 'auto'
  },
  successStatus: {
    border: 'solid 8px #8fc31f',
    backgroundColor: '#ffffff'
  },
  failStatus: {
    border: 'solid 8px #f5bb00',
    backgroundColor: '#ffffff'
  }
});
const StatusCircle = props => {
  const { classes = {} } = props;
  const circleType = _.get(props, 'circleType', '');
  let icon = '';
  if (circleType === 'success') {
    icon = SuccessIcon;
  }
  if (circleType === 'fail') {
    icon = FailIcon;
  }
  return (
    <div className={`${classes[`${circleType}Status`]} ${classes.statusCircle}`}>
      <img alt="upload-status" className={classes.statusIcon} src={icon} />
    </div>
  );
};

StatusCircle.propTypes = {
  classes: PropsTypes.object
};

export default withStyles(styles)(StatusCircle);
