import React from 'react';
import { TextField } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import STYLE from 'styles/_constants.scss';

const styles = {
  title: {
    fontSize: '14px',
    color: '#3c3c3c',
    fontWeight: '500',
    fontFamily: `${STYLE.fontFamily}`
  },
  longfield: {
    border: 'solid 1px #dddddd',
    borderRadius: '4px',
    width: '100%',
    overflowWrap: 'break-word',
    whiteSpace: 'pre-wrap',
    padding: '10px',
    height: 'auto',
    minHeight: '122px',
    marginBottom: '20px',
    marginTop: '7px',
    backgroundColor: '#f5f6fa',
    fontSize: '16px',
    color: '#3c3c3c',
    fontFamily: `${STYLE.fontFamily}`
  }
};

export const TextLongFieldCustom = withStyles(styles)(({ classes, label, source = '', ...props }) => (
  <div>
    <label className={classes.title}>{label}</label>
    <TextField source={source} className={classes.longfield} {...props} fullWidth />
  </div>
));
