import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { translate } from 'ra-core';
import { TextInput } from 'react-admin';

const searchFilterStyles = createStyles({
  input: {
    padding: 0,
    height: '24px'
  },
  customSearch: {
    marginTop: '0px !important',
    padding: '12px 16px'
  }
});

const SearchInput = ({ classes, placeholder, ...props }) => (
  <TextInput
    label={false}
    placeholder={placeholder}
    InputLabelProps={{
      shrink: true
    }}
    InputProps={{
      disableUnderline: true,
      className: classes.customSearch,
      classes: {
        input: classes.input
      }
    }}
    {...props}
  />
);

SearchInput.propTypes = {
  classes: PropTypes.object,
  translate: PropTypes.func,
  placeholder: PropTypes.string
};

const enhance = compose(translate, withStyles(searchFilterStyles));

export default enhance(SearchInput);
