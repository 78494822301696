import React from 'react';
import { Layout } from 'react-admin';
import CustomMenu from './CustomMenu';
import AppBar from './AppBar';
import { withStyles } from '@material-ui/core/styles';
import { Logo } from './Logo';

const styles = {
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  spacer: {
    flex: 1
  },
  icon: {
    width: '242px',
    height: '48px'
  },
  appBar: {
    backgroundColor: '#fff',
    position: 'sticky',
    top: 0
  },
  backgroundContent: {
    backgroundColor: '#f5f6fa'
  },
  menuButton: {
    display: 'none'
  },
  content: {
    padding: '32px 48px 17px 48px'
  }
};
const CustomAppBar = withStyles(styles)(({ classes, ...props }) => (
  <AppBar classes={{ menuButton: classes.menuButton }} className={classes.appBar} {...props}>
    <Logo className={classes.icon} />
    <span className={classes.spacer} />
  </AppBar>
));
const CustomLayout = withStyles(styles)(({ classes, ...props }) => (
  <Layout
    classes={{ appFrame: classes.backgroundContent, content: classes.content }}
    {...props}
    appBar={CustomAppBar}
    menu={CustomMenu}
  />
));

export default CustomLayout;
