import { GET_ONE, GET_LIST, DELETE_MANY } from 'react-admin';
import _ from 'lodash';
import { GET_PENDING_MEMBERS, SEND_ADMIN_INVITE, DELETE_PEDNING_ADMIN } from 'containers/Admin/constants';

export default async (axiosInstance, type, params) => {
  switch (type) {
    case GET_ONE: {
      return axiosInstance.post('/functions/getCurrentUser').then(response => {
        const data = _.get(response, 'data.result', {});
        _.set(data, 'id', params.id);
        return Promise.resolve({
          data
        });
      });
    }
    case GET_LIST: {
      let { page, perPage } = params.pagination;
      let field = _.get(params, 'sort.field');
      let order = _.get(params, 'sort.order');

      perPage = perPage > 1 ? perPage : 10;
      let data = {
        ...params.filter,
        page: page,
        limit: perPage,
        orderBy: field,
        order
      };
      return axiosInstance.post('/functions/getAdminListByOwner', data).then(response => {
        const data = _.get(response, 'data.result.data', []);
        const total = _.get(response, 'data.result.total', 0);
        data.forEach(item => {
          item.id = item.objectId;
          if (item.roleName === 'SUBMEMBER') {
            item.roleName = 'アドミン';
          }
        });
        return Promise.resolve({
          data,
          total
        });
      });
    }
    case GET_PENDING_MEMBERS: {
      return axiosInstance.post('/functions/getPendingAdmin').then(response => {
        const pendingAdmin = _.get(response, 'data.result.pendingAdmin');
        return Promise.resolve({
          pendingAdmin
        });
      });
    }
    case SEND_ADMIN_INVITE: {
      return axiosInstance
        .post('/functions/sendMailInviteAdmin', params)
        .then(response => {
          const result = _.get(response, 'data.result');
          return Promise.resolve(result);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    }
    case DELETE_PEDNING_ADMIN: {
      return axiosInstance
        .post('/functions/deletePendingAdmin', { adminAddress: params.adminAddress, adminName: params.adminName })
        .then(response => {
          const pendingAdmin = _.get(response, 'data.result.pendingAdmin');
          return Promise.resolve({
            pendingAdmin
          });
        });
    }
    case DELETE_MANY: {
      const { ids = [] } = params;
      return axiosInstance.post('functions/deleteAdmin', { ids }).then(() => {
        return Promise.resolve({ data: [] });
      });
    }
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }
};
