import React from 'react';
import _ from 'lodash';
import { SELLSTATE } from 'helpers/Const';
import SuspendedIcon from 'assets/icon-status-suspended.svg';
import SellingIcon from 'assets/icon-status-onsale.svg';
import NegotiateIcon from 'assets/icon-status-negotiate.svg';
import ProcessingIcon from 'assets/icon-status-inprogress.svg';
import CompletedIcon from 'assets/icon-status-complete.svg';
import PendingIcon from 'assets/icon-status-pending.svg';
import PropTypes from 'prop-types';
import './style.scss';

const SellStateField = ({ source, record = {} }) => {
  const sellState = _.get(record, `${source}`);
  let subClassName = '';
  let icon = '';
  if (!sellState) {
    return null;
  }
  switch (sellState) {
    case SELLSTATE.SELLING:
      subClassName = 'selling';
      icon = SellingIcon;
      break;
    case SELLSTATE.SUSPENDED:
      subClassName = 'suspended';
      icon = SuspendedIcon;
      break;
    case SELLSTATE.NEGOTIATE:
      subClassName = 'negotiate';
      icon = NegotiateIcon;
      break;
    case SELLSTATE.PROCESSING:
      subClassName = 'processing';
      icon = ProcessingIcon;
      break;
    case SELLSTATE.COMPLETED:
      subClassName = 'completed';
      icon = CompletedIcon;
      break;
    case SELLSTATE.PENDING:
      subClassName = 'pending';
      icon = PendingIcon;
      break;
    default:
      break;
  }
  return (
    <div className={`list-status-btn ${subClassName}-state`}>
      <img alt="status" className="status-icon" src={icon} />
      <div className="status-text">{sellState}</div>
    </div>
  );
};

SellStateField.propTypes = {
  source: PropTypes.string,
  record: PropTypes.object
};
export default SellStateField;
