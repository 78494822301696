import moment from 'moment';
export const validate = values => {
  const errors = {};
  const startDate = moment(values.startDate, 'YYYY/MM/DD');
  const endDate = moment(values.endDate, 'YYYY/MM/DD');
  if (startDate.diff(endDate) > 0) {
    errors.endDate = 'Invalid date';
  }
  return errors;
};
