import _ from 'lodash';
// eslint-disable-next-line
const depreciationRate = [
  0, 0, // eslint-disable-line
  1.000, 0.667, 0.500, 0.400, 0.333, 0.286, 0.250, 0.222, 0.200, 0.182, // eslint-disable-line
  0.167, 0.154, 0.143, 0.133, 0.125, 0.118, 0.111, 0.105, 0.100, 0.095, // eslint-disable-line
  0.091, 0.087, 0.083, 0.080, 0.077, 0.074, 0.071, 0.069, 0.067, 0.065, // eslint-disable-line
  0.063, 0.061, 0.059, 0.057, 0.056, 0.054, 0.053, 0.051, 0.050, 0.049, // eslint-disable-line
  0.048, 0.047, 0.045, 0.044, 0.043, 0.043, 0.042, 0.041, 0.040, 0.039, // eslint-disable-line
  0.038, 0.038, 0.037, 0.036, 0.036, 0.035, 0.034, 0.034, 0.033, 0.033, // eslint-disable-line
  0.032, 0.032, 0.031, 0.031, 0.030, 0.030, 0.029, 0.029, 0.029, 0.028, // eslint-disable-line
  0.028, 0.027, 0.027, 0.027, 0.026, 0.026, 0.026, 0.025, 0.025, 0.025, // eslint-disable-line
  0.024, 0.024, 0.024, 0.024, 0.023, 0.023, 0.023, 0.022, 0.022, 0.022, // eslint-disable-line
  0.022, 0.022, 0.021, 0.021, 0.021, 0.021, 0.020, 0.020, 0.020, // eslint-disable-line
];

// eslint-disable-next-line
const revisedDepricationRate = [
  0, 0, // eslint-disable-line
  0.000, 1.000, 1.000, 0.500, 0.334, 0.334, 0.334, 0.250, 0.250, 0.200, // eslint-disable-line
  0.200, 0.167, 0.167, 0.143, 0.143, 0.125, 0.112, 0.112, 0.112, 0.100, // eslint-disable-line
  0.100, 0.091, 0.084, 0.084, 0.084, 0.077, 0.072, 0.072, 0.072, 0.067, // eslint-disable-line
  0.067, 0.063, 0.063, 0.059, 0.059, 0.056, 0.056, 0.053, 0.053, 0.050, // eslint-disable-line
  0.050, 0.048, 0.046, 0.046, 0.044, 0.044, 0.044, 0.042, 0.042, 0.040, // eslint-disable-line
  0.039, 0.039, 0.038, 0.038, 0.038, 0.036, 0.035, 0.035, 0.034, 0.034, // eslint-disable-line
  0.033, 0.033, 0.032, 0.032, 0.031, 0.031, 0.030, 0.030, 0.030, 0.029, // eslint-disable-line
  0.029, 0.027, 0.027, 0.027, 0.027, 0.027, 0.027, 0.026, 0.026, 0.026, // eslint-disable-line
  0.024, 0.024, 0.024, 0.024, 0.023, 0.023, 0.023, 0.022, 0.022, 0.022, // eslint-disable-line
  0.022, 0.022, 0.021, 0.021, 0.021, 0.021, 0.020, 0.020, 0.020  // eslint-disable-line
];

// eslint-disable-next-line
const guaranteeRate = [
  0, 0, //eslint-disable-line
  0.000, 0.11089, 0.12499, 0.10800, 0.09911, 0.08680, 0.07909, 0.07126, 0.06552, 0.05992, //eslint-disable-line
  0.05566, 0.05180, 0.04854, 0.04565, 0.04294, 0.04038, 0.03884, 0.03693, 0.03486, //eslint-disable-line
  0.03335, 0.03182, 0.03052, 0.02969, 0.02841, 0.02716, 0.02624, 0.02568, 0.02463, //eslint-disable-line
  0.02366, 0.02286, 0.02216, 0.02161, 0.02097, 0.02051, 0.01974, 0.01950, 0.01882, //eslint-disable-line
  0.01860, 0.01791, 0.01741, 0.01694, 0.01664, 0.01664, 0.01634, 0.01601, 0.01532, //eslint-disable-line
  0.01499, 0.01475, 0.01440, 0.01422, 0.01422, 0.01370, 0.01370, 0.01337, 0.01288, //eslint-disable-line
  0.01281, 0.01281, 0.01240, 0.01240, 0.01201, 0.01201, 0.01165, 0.01165, 0.01130, //eslint-disable-line
  0.01130, 0.01097, 0.01097, 0.01065, 0.01034, 0.01034, 0.01006, 0.01063, 0.01035, //eslint-disable-line
  0.01007, 0.00980, 0.00954, 0.00929, 0.00929, 0.00907, 0.00884, 0.00929, 0.00907, //eslint-disable-line
  0.00885, 0.00864, 0.00885, 0.00864, 0.00844, 0.00863, 0.00844, 0.00825, 0.00807, //eslint-disable-line
  0.00790, 0.00807, 0.00790, 0.00773, 0.00757, 0.00773, 0.00757, 0.00742 //eslint-disable-line
];

const fixedDepreciationRate = [
  0, 0, //eslint-disable-line
  0.5, 0.334, 0.25, 0.2, 0.167, 0.143, 0.125, 0.112, 0.1, 0.091, //eslint-disable-line
  0.084, 0.077, 0.072, 0.067, 0.063, 0.059, 0.056, 0.053, 0.05, 0.048, //eslint-disable-line
  0.046, 0.044, 0.042, 0.04, 0.039, 0.038, 0.036, 0.035, 0.034, 0.033, //eslint-disable-line
  0.032, 0.031, 0.03, 0.029, 0.028, 0.028, 0.027, 0.026, 0.025, 0.025, //eslint-disable-line
  0.024, 0.024, 0.023, 0.023, 0.022, 0.022, 0.021, 0.021, 0.02, 0.02, //eslint-disable-line
  0.02, 0.019, 0.019, 0.019, 0.018, 0.018, 0.018, 0.017, 0.017, 0.017, //eslint-disable-line
  0.017, 0.016, 0.016, 0.016, 0.016, 0.015, 0.015, 0.015, 0.015, 0.015, //eslint-disable-line
  0.014, 0.014, 0.014, 0.014, 0.014, 0.013, 0.013, 0.013, 0.013, 0.013, //eslint-disable-line
  0.013, 0.013, 0.012, 0.012, 0.012, 0.012, 0.012, 0.012, 0.012, 0.011, //eslint-disable-line
  0.011, 0.011, 0.011, 0.011, 0.011, 0.011, 0.011, 0.011, 0.01 //eslint-disable-line
];

//not need currently
// eslint-disable-next-line
function calcRateValue(purchasePrice, serviceLife, yearActive = 0, actualVal, actualRate, depFlag = false, histArray) {
  let guaranteeVal = Math.round(purchasePrice * guaranteeRate[serviceLife] + 0.9);
  if (actualVal <= actualRate || yearActive === serviceLife) {
    histArray.push({ year: yearActive + 1, begin: actualVal, rate: actualVal - 1, end: 1 });
    return histArray;
  } else {
    if (!depFlag) {
      actualRate = Math.round(actualVal * depreciationRate[serviceLife] + 0.9);
      if (actualRate < guaranteeVal) {
        actualRate = Math.round(actualVal * revisedDepricationRate[serviceLife] + 0.9);
        depFlag = true;
      }
    }
    histArray.push({ year: yearActive + 1, begin: actualVal, rate: actualRate, end: actualVal - actualRate });
    return calcRateValue(
      purchasePrice,
      serviceLife,
      yearActive + 1,
      actualVal - actualRate,
      actualRate,
      depFlag,
      histArray
    );
  }
}

function calcAmountValue(purchasePrice, serviceLife, yearActive = 0, actualVal = 0, histArray) {
  let depreciateVal = Math.floor(purchasePrice * fixedDepreciationRate[serviceLife] + 0.9);
  if (actualVal <= depreciateVal || yearActive >= serviceLife) {
    histArray.push({ year: yearActive + 1, begin: actualVal, rate: actualVal - 1, end: 1 });
    return histArray;
  } else {
    histArray.push({ year: yearActive + 1, begin: actualVal, rate: depreciateVal, end: actualVal - depreciateVal });
    return calcAmountValue(purchasePrice, serviceLife, yearActive + 1, actualVal - depreciateVal, histArray);
  }
}

//USE THIS FOR CARRYING VALUE
export default function getCarryingValue(purchasePrice, serviceLife, year) {
  if (serviceLife <= 0 || serviceLife > 100) {
    return '';
  }
  let hist = calcAmountValue(purchasePrice, serviceLife, 0, purchasePrice, []);
  return year < serviceLife ? _.get(hist[year - 1], 'end') : 1;
}
