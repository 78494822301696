const { ERROR_MESSAGE } = require('helpers/Const');
const { validateEmail } = require('helpers/Utils');
const validate = values => {
  const errors = {};
  if (!values.adminName || !values.adminName.trim()) {
    errors.adminName = [ERROR_MESSAGE.REQUIRED];
  } else if (values.adminName.length > 20) {
    errors.adminName = [ERROR_MESSAGE.INVALID];
  }
  if (!values.adminAddress || !values.adminAddress.trim()) {
    errors.adminAddress = [ERROR_MESSAGE.REQUIRED];
  } else if (!validateEmail(values.adminAddress)) {
    errors.adminAddress = [ERROR_MESSAGE.INVALID];
  }
  if (values.adminAddressConfirm !== values.adminAddress) {
    errors.adminAddressConfirm = [ERROR_MESSAGE.INVALID];
  }
  return errors;
};

export default validate;
