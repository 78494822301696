const {
  HOLDING_CATEGORY,
  TARGET_OF_CALIBRATION,
  CALIBRATION_RESULT,
  FIXED_ASSETS
} = require('../containers/Merchandise/MerchandiseCreate/constants');

module.exports = {
  RESOURCES: {
    USER: {
      name: 'user',
      label: 'Users'
    },
    CATEGORY: {
      name: 'category',
      label: 'Categories'
    },
    MANUFACTURER: {
      name: 'manufacturer',
      label: 'Manufacturers'
    },
    MERCHANDISE: {
      name: 'merchandise',
      label: '登録機器一覧'
    },
    TRANSACTION: {
      name: 'transaction',
      label: '取引一覧'
    },
    MERCHANDISE_FOR_SALE: {
      name: 'merchandiseForSale',
      label: '出品一覧'
    },
    MERCHANDISE_ERROR: {
      name: 'merchandiseError',
      label: '出品エラーリスト'
    },
    COMMENT: {
      name: 'comment',
      label: 'Comment'
    },
    ADMIN: {
      name: 'admin',
      label: '管理画面'
    }
  },
  USER_ROLE: {
    ADMIN: 'admin',
    OPERATOR: 'operator',
    MEMBER: 'member'
  },
  STATUS: {
    INACTIVE: 'INACTIVE',
    ACTIVE: 'ACTIVE'
  },
  SELLSTATE: {
    SELLING: '出品中',
    SUSPENDED: '未出品',
    NEGOTIATE: '商談中',
    PROCESSING: '売約済',
    COMPLETED: '取引完了',
    PENDING: '未出品'
  },
  ADJUSTMENT: {
    CALIBRATED: '校正済み',
    UNCALIBRATED: '校正が別途必要',
    INAPPLICABLE: '校正対象外',
    UNKNOWN: '校正要否不明'
  },
  ACCESSORY: {
    NO_ACCESS: '付属品が欠けている',
    YES_ACCESS: 'すべての付属品がそろっている',
    INAPPLICABLE: '購入時の付属品はない'
  },
  TIMESPAN: {
    EARLY: '1週間以内',
    NORUSH: '2週間以内',
    LATE: '1ヶ月以内',
    DELAYED: '1ヶ月以上'
  },
  ORDERSTATE: {
    ORDER_SELLING: '出品中',
    ORDER_CONFIRMING: '取引申請',
    ORDER_PAYING: 'お支払い',
    ORDER_PAYCONFIRM: '入金確認',
    ORDER_DELIPREPARE: '配送中',
    ORDER_DELIVERING: '受領',
    ORDER_RECEIVED: '検収',
    ORDER_REVIEWED: '評価',
    ORDER_COMPLETED: '取引完了',
    ORDER_REJECTED: '拒否'
  },
  SERVICE_YEARS: [
    { id: 1, name: '１年未満' },
    { id: 2, name: '１年以上、２年未満' },
    { id: 3, name: '２年以上、３年未満' },
    { id: 4, name: '3年以上、4年未満' },
    { id: 5, name: '4年以上、5年未満' },
    { id: 6, name: '５年以上' }
  ],
  VALIDATION: {
    VALIDATION_FALSE: '動作確認せず',
    VALIDATION_TRUE: '動作確認ずみ'
  },
  WARRANTY: {
    WARRANTY_FALSE: 'メーカー保証なし',
    WARRANTY_TRUE: 'メーカー保証有り'
  },
  NUMBER_FORMAT: {
    JAPAN: { CODE: 'ja-JP', CURRENCY: 'JPY', TAXES: process.env.TAX_RATE_FLOAT || 0.1, SURCHARGE: 0.15 }
  },
  ACTIONS: {
    CREATED: '取引申請がなされました',
    CONFIRMED: '様とのお取引が開始されました',
    REJECTED: 'こちらのお取引をキャンセルしました',
    PAYMENT: '様がお支払いをしました',
    PAYCONFIRMED: 'オペレータが支払いを受け取りました',
    SHIPMENT: '商品が運送会社に引渡されました',
    RECEIVED: '様に商品が届きました',
    INSPECTED: '様が商品を受領しました',
    COMPLETED: 'お取引は完了しました'
  },
  BANK_TYPE: [
    { id: '普通', name: '普通' },
    { id: '当座', name: '当座' }
  ],
  DATE_FORMAT: {
    DIV: 'YYYY/MM/DD',
    NODIV: 'YYYYMMDD',
    SIMPLE: 'YYYY/MM',
    FULL: 'YYYY/MM/DD-HH:mm',
    ERROR: 'Invalid date',
    JAP: 'YYYY年MM月DD日',
    TZ: 'Asia/Tokyo',
    YEARS: 'YYYY'
  },
  MANUFACTURER: {
    OTHER: 'その他'
  },
  FILE_TYPE: {
    MERCHANDISE: 'MERCHANDISE'
  },
  MERCHANDISE: {
    IMAGES_LIMIT: 10,
    IMAGES_SIZE: 2 * 1024 * 1024, //limit 2 MB
    HOLDING_CATEGORIES: [
      {
        id: HOLDING_CATEGORY.IN_HOUSE_PURCHASE,
        name: HOLDING_CATEGORY.IN_HOUSE_PURCHASE
      },
      { id: HOLDING_CATEGORY.RENTAL, name: HOLDING_CATEGORY.RENTAL },
      { id: HOLDING_CATEGORY.LEASE, name: HOLDING_CATEGORY.LEASE }
    ],
    TARGET_OF_CALIBRATIONS: [
      {
        id: TARGET_OF_CALIBRATION.TARGET,
        name: TARGET_OF_CALIBRATION.TARGET
      },
      {
        id: TARGET_OF_CALIBRATION.EXCLUDED,
        name: TARGET_OF_CALIBRATION.EXCLUDED
      }
    ],
    CALIBRATION_RESULTS: [
      { id: CALIBRATION_RESULT.PASS, name: CALIBRATION_RESULT.PASS },
      { id: CALIBRATION_RESULT.FAILURE, name: CALIBRATION_RESULT.FAILURE }
    ],
    FIXED_ASSETSES: [
      { id: FIXED_ASSETS.TARGET, name: FIXED_ASSETS.TARGET },
      { id: FIXED_ASSETS.EXCLUDED, name: FIXED_ASSETS.EXCLUDED }
    ]
  },
  UPLOADER_BASE_URL: process.env.REACT_APP_API_URL || 'http://localhost:1337',
  TERMS_OF_USE_URL: '/terms',
  POLICY_URL: '/privacy',
  LABEL: {
    ID: 'ID',
    HOLDING_CATEGORY: '保有区分',
    CONTROL_NUMBER: '機器管理番号',
    NAME: '機器名称',
    MAIN_CATEGORY: '機器カテゴリー（大）',
    SUB_CATEGORY: '機器カテゴリー(小)',
    MAKER: 'メーカー名',
    OTHER_MAKER: 'その他',
    MAKER_MODEL_NUMBER: 'メーカー型番',
    SERIAL_NUMBER: 'シリアル番号',
    OFFICER: '管理担当者',
    USER: '使用者',
    DATE_OF_USE: '使用日',
    STORAGE_AND_AVAILABLE_AREA: '保管、使用場所',
    TARGET_OF_CALIBRATION: '校正対象 ',
    CALIBRATION_CYCLE: '校正周期',
    CALIBRATION_APPLIED_DATE: '校正実施日',
    CALIBRATION_DATE: '校正期日',
    CALIBRATION_RESULT: '校正結果',
    FIXED_ASSETS: '固定資産対象',
    PURCHASED_DATE: '購入日',
    ORIGINAL_PRICE: '購入価格 (円)',
    SERVICE_LIFE: '耐用年数',
    REAL_MARKET_VALUE: '簿価（円）定額法',
    MERCHANDISE_IMAGE: '写真',
    STATUS_OF_ADJUSTMENT: '校正状況',
    EXPIRATION_OF_ADJUSTMENT: '校正期限',
    DESCRIPTION: '商品説明',

    USAGED: '使用履歴',
    DELIVERY: '出荷可能時期',
    PRICE: '価格 ※半角数字',
    EMAIL_ADDRESS: 'メールアドレス',
    COMPANY_NAME: '事業所名・部署名',
    STATUS: 'ステータス',
    CREATED_DATE: '登録日',
    REPRESENTATIVE: '登録者',
    CREATED_BY: 'Created By',
    EXPORT_BTN: 'CSVをインポート',
    SEARCH_FILTER: '購入者名で検索/商品名で検索',
    SEARCH_FILTER_PLACEHOLDER: '登録者名で検索/商品名で検索',
    SELECT_FILTER: 'カテゴリー',
    LIST_PRICE: '購入金額、定価 (¥)',
    ACCESSORY: '付属品',
    VALIDATION: '動作確認',
    CALIBRATION: '校正情報',
    WARRANTY: 'メーカー保証期間',
    SERVICE_YEARS: '入力値',
    LOG_ID_TITLE: '注文ID',
    LOG_ID_DETAIL: '取引ID',
    TRADING_DATE: '取引日',
    SELLER_COMPANY: '出品者(会社名)',
    BUYER_COMPANY_TITLE: '購入者',
    BUYER_COMPANY_DETAIL: '購入者（会社名）',
    MERCHANDISE_NAME: '商品名',
    TRANSACTION_IMAGE: 'メイン画像',
    SELLER_AGENCY_CODE: '出品者代理店コード',
    PAYMENT_DATE: '入金日',
    USER_NAME: '名前',
    AUTHORITY: '権限',
    TRANSACTION_PRICE: '取引金額',
    UPDATE_DATE: '更新日',
    PHONE_NUMBER: '電話番号',
    SHIPPING_ADDRESS: '配送先住所',
    YEAR: '年式',
    STORAGE_LOCATION: '保管場所（都道府県）'
  },
  HOLDING_CATEGORY: [
    {
      id: HOLDING_CATEGORY.IN_HOUSE_PURCHASE,
      name: HOLDING_CATEGORY.IN_HOUSE_PURCHASE
    },
    { id: HOLDING_CATEGORY.RENTAL, name: HOLDING_CATEGORY.RENTAL },
    { id: HOLDING_CATEGORY.LEASE, name: HOLDING_CATEGORY.LEASE }
  ],
  TARGET_OF_CALIBRATION: [
    {
      id: TARGET_OF_CALIBRATION.TARGET,
      name: TARGET_OF_CALIBRATION.TARGET
    },
    {
      id: TARGET_OF_CALIBRATION.EXCLUDED,
      name: TARGET_OF_CALIBRATION.EXCLUDED
    }
  ],
  CALIBRATION_RESULT: [
    { id: CALIBRATION_RESULT.PASS, name: CALIBRATION_RESULT.PASS },
    { id: CALIBRATION_RESULT.FAILURE, name: CALIBRATION_RESULT.FAILURE }
  ],
  FIXED_ASSETS: [
    { id: FIXED_ASSETS.TARGET, name: FIXED_ASSETS.TARGET },
    { id: FIXED_ASSETS.EXCLUDED, name: FIXED_ASSETS.EXCLUDED }
  ],
  ORDERSTATE_CHOICES: [
    { id: '出品中', name: '出品中' },
    { id: '取引申請', name: '取引申請' },
    { id: 'お支払い', name: 'お支払い' },
    { id: '入金確認', name: '入金確認' },
    { id: '配送中', name: '配送中' },
    { id: '受領', name: '受領' },
    { id: '検収', name: '検収' },
    { id: '評価', name: '評価' },
    { id: '取引完了', name: '取引完了' },
    { id: '拒否', name: '拒否' }
  ],
  MESSAGE: {
    MARK_FOR_SALE: 'Ekuippへ出品するには☑を入れてください'
  },
  DELETE_MESSAGE: {
    DELETE: '削除',
    CANCEL: 'キャンセルして戻る',
    CONFIRMED: '商品を削除しますか？',
    TITLE: '商品の削除',
    SUB_TITLE: '商品の削除'
  },
  ERROR_MESSAGE: {
    INVALID: '無効',
    REQUIRED: '必須',
    NEW_PASSWORD_AND_OLD_PASSWORD_VALIDATE: '新しいパスワードと現在のパスワードは異なる必要があります',
    OVER_MAXIMUM_IMAGE_SIZE: 'Image size must be < 2MB',
    FAILED_AUTH: 'パスワードが間違っています'
  },
  TERMS_OF_USE: '利用規約',
  POLICY: 'プライバシー ポリシー',
  PATH: {
    MERCHANDISE: '/merchandise'
  },
  BUTTON_LABEL: {
    CREATE: '登録する',
    CANCEL: 'キャンセルして戻る',
    CALCULATE: '計算する',
    RETRY: '再計算',
    SUBMIT: '計算する',
    SAVE: '保存する',
    INVITE_EMAIL: '招待メールを送る'
  },
  DATE_FIELD_TYPES: {
    YY: 'YY',
    YYYY: 'YYYY',
    YYYY_MM: 'YYYY_MM',
    YYYY_MM_DD: 'YYYY_MM_DD'
  },
  SUGGESTED_RANGE_TEXT: '以下の範囲でご提案いたします',
  CSV_FILES_TYPE: [
    '.csv',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel'
  ],
  UPLOAD_CSV: {
    IMPORT_TITLE: 'CSVをインポート',
    LIMIT_UPLOAD_TEXT: 'アップロード可能なファイルはCSV形式のみ、1ファイルの最大サイズは10MBになります',
    INVALID_FILE: 'アップロードできませんでした。可能なファイルサイズは10MBまでです。',
    TEMPLATE_DOWNLOAD: 'インポートファイルのテンプレートCSVをダウンロードする',
    UPLOAD_TITLE: 'ファイルのアップロード',
    UPLOADING_TEXT: `ファイルをアップロードしています。
    このままお待ちください。`,
    COMPLETE_TEXT: 'ファイルのアップロードが完了しました',
    ERROR_TEXT: `ファイルのアップロードは完了しましたが、エラーのため登録できなかった商品があります。
    以下のエラーリストをダウンロードの上、内容をご確認ください。
    お送りしたeメールからもこのファイルをダウンロードすることができます。`,
    UPLOAD_BTN: 'アップロードする',
    CANCEL_BTN: 'キャンセル',
    ERROR_LIST: 'エラーリスト',
    FILE_TYPE: 'text/csv',
    FILE_SIZE: 10485760, //10mb
    EXTENSION: 'csv'
  },
  ADMIN_MANAGEMENT: {
    DELETE_TITLE: 'メンバーの削除',
    DELETE_WARNING: `メンバーを削除すると元には戻せません
    削除してもよろしいですか？`,
    EMPTY_TEXT: `まだメンバーがいません
    新しいメンバーを追加してください`,
    REG_AND_DEL_TITLE: 'メンバー登録/削除',
    RESEND_BTN: '再送',
    INVITE_FORM_TITLE: '招待フォーム'
  },
  CUSTOM_ACTION: {
    GET_EXPORT_LIST: 'GET_EXPORT_LIST',
    SELECT_EXPORT: 'SELECT_EXPORT'
  },
  LIMIT_PER_PAGE: 50
};
