import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Logo from '../../assets/logo-login.svg';
import UnsupportedMobile from '../../assets/unsupported-mobile.svg';
import Mail from '../../assets/mail.svg';
import Phone from '../../assets/phone.svg';

const styles = theme => ({
  container: {
    width: '100vw',
    background: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  logoContainer: {
    marginTop: '80px',
    marginBottom: '64px',
    textAlign: 'center'
  },
  logo: {
    width: '240px',
    objectFit: 'contain',
    margin: '0 auto'
  },
  description: {
    textAlign: 'center',
    fontSize: '14px',
    marginBottom: '24px'
  },
  mainImage: {
    textAlign: 'center'
  },
  footer: {
    width: '100%',
    minHeight: 'calc(100vh - 380px)',
    background: '#f5f6fa',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: 'inset 0 1px 0 0 #dddddd'
  },
  footerDescription: {
    margin: '24px auto 16px auto',
    maxWidth: '312px',
    fontSize: '12px',
    textAlign: 'center'
  },
  footerContact: {
    margin: '0 auto',
    width: '310px',
    color: '#202a6a',
    padding: '24px 0 10px 0',
    borderRadius: '4px',
    border: 'solid 1px #202a6a',
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  footerContactTitle: {
    fontSize: '14px',
    fontWeight: 'bold',
    marginBottom: '16px'
  },
  contactContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    maxWidth: '227px',
    marginBottom: '6px',
    minHeight: '20px',
    alignItems: 'center',
    lineHeight: '1',
    fontSize: '14px',
    fontWeight: '500'
  },
  contactContainerImage: {
    marginRight: '16px',
    width: '16px',
    height: '10px'
  },
  contactContainerTitle: {
    width: '43px'
  },
  contactContainerContent: {
    marginLeft: '8px'
  }
});

class LoginMobile extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div className={classes.container} tabIndex="1">
          <div className={classes.logoContainer}>
            <img className={classes.logo} src={Logo} alt="login logo" />
          </div>
          <div className={classes.description}>
            <div>現在Ekuipp台帳は</div>
            <div>スマートフォンに対応していません。</div>
            <div>パソコン向けの表示でご利用いただけます。</div>
          </div>
          <div className={classes.mainImage}>
            <img src={UnsupportedMobile} alt="login logo" />
          </div>
        </div>
        <div className={classes.footer}>
          <div className={classes.footerDescription}>
            当社サービス対するご質問・ご要望などがございましたら、以下の連絡先よりお気軽にお問い合わせください。
          </div>
          <div className={classes.footerContact}>
            <div className={classes.footerContactTitle}>運営会社 Ekuipp株式会社</div>
            <div className={classes.contactContainer}>
              <img className={classes.contactContainerImage} src={Phone} alt="contact phone" />
              <div className={classes.contactContainerTitle}>Tel</div>
              <div>:</div>
              <div className={classes.contactContainerContent}>050-3199-1618</div>
            </div>
            <div className={classes.contactContainer}>
              <img className={classes.contactContainerImage} src={Mail} alt="contact mail" />
              <div className={classes.contactContainerTitle}>E-mail</div>
              <div>:</div>
              <div className={classes.contactContainerContent}>admin@ekuipp.com</div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

LoginMobile.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(LoginMobile);
