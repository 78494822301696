import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core';
import { change, submit } from 'redux-form';
import { connect } from 'react-redux';
import _ from 'lodash';
import { WARRANTY, VALIDATION } from 'helpers/Const';
import { setPopup } from 'containers/Popup/actions';
import PropTypes from 'prop-types';
import PriceSuggestionForm from './PriceSuggestionForm';
import getSuggestPrice from 'helpers/SuggestPriceCalculation';
import Close from 'assets/quit.svg';
import './style.scss';
const styles = () => ({
  root: {
    width: '1048px',
    margin: 0,
    maxWidth: 'unset',
    borderRadius: '4px',
    backgroundColor: '#FFFFFF'
  },
  dialogTitle: {
    marginTop: '56px',
    padding: '0px',
    fontSize: '40px',
    color: '#3c3c3c',
    lineHeight: '56px',
    fontFamily: "'Sawarabi Mincho', serif",
    display: 'flex',
    justifyContent: 'center'
  }
});
class PriceSuggestionPopup extends Component {
  handleSubmit = async values => {
    let data = { ...values };
    if (values.assurances === WARRANTY.WARRANTY_TRUE) {
      data.assurances = true;
    } else data.assurances = false;
    if (values.validation === VALIDATION.VALIDATION_TRUE) {
      data.validation = true;
    } else data.validation = false;
    const value = await getSuggestPrice(data);
    const changeFormValue = _.get(this.props, 'changeFormValue');
    changeFormValue('suggesting-price', 'result', value.result);
    changeFormValue('suggesting-price', 'resultLowRange', value.resultLowRange);
    changeFormValue('suggesting-price', 'resultUpRange', value.resultUpRange);
  };
  handleClose = () => {
    this.props.setPopup('priceSuggestionPoup', false);
  };
  render() {
    const { priceSuggestionPopup = false, classes = {} } = this.props;
    return (
      <Dialog classes={{ paper: classes.root }} className="price-suggestion" fullWidth open={priceSuggestionPopup}>
        <img onClick={this.handleClose} alt="close-btn" className="close-btn" src={Close} />
        <div>
          <DialogTitle disableTypography={true} classes={{ root: classes.dialogTitle }}>
            機器の状態や情報を入力してください
          </DialogTitle>
          <DialogContent className="dialog-content">
            <div className="field-container">
              <PriceSuggestionForm handleClose={this.handleClose} onSubmit={this.handleSubmit} />
            </div>
          </DialogContent>
        </div>
      </Dialog>
    );
  }
}
PriceSuggestionPopup.propTypes = {
  priceSuggestionPopup: PropTypes.bool,
  classes: PropTypes.object,
  setPopup: PropTypes.func
};
const mapDispatchToProps = dispatch => {
  return {
    changeFormValue: (formName, fieldName, value) => dispatch(change(formName, fieldName, value)),
    submit: formName => dispatch(submit(formName)),
    setPopup: (formName, state) => dispatch(setPopup(formName, state))
  };
};
const mapStateToProps = state => {
  return {
    priceSuggestionPopup: _.get(state, 'popup.priceSuggestionPoup')
  };
};
export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(PriceSuggestionPopup);
