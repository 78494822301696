import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { propTypes, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import LoginForm from './LoginForm';
import LogoForm from '../../assets/logo-login.svg';
import LogoIcon from '../../assets/icon-login.svg';
import VideoIntro from '../../assets/intro.mp4';
import LoginMobile from './LoginMobile';

import { Input, Modal, Backdrop, Fade } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

import { translate, userLogin, Notification } from 'react-admin';
import { Link } from 'ra-ui-materialui';

import { getAuthToken } from '../../helpers/Auth';
import { checkIsMobileDevice } from '../../helpers/Utils';

const styles = theme => ({
  card: {
    minWidth: 300,
    marginTop: '6em'
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center'
  },
  icon: {
    backgroundColor: theme.palette.secondary.main
  },
  hint: {
    marginTop: '1em',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.grey[500]
  },
  input: {
    marginTop: '1em'
  },
  actions: {
    padding: '0 1em 1em 1em'
  },
  main: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'no-wrap',
    color: '#3c3c3c'
  },
  leftPane: {
    width: '50%',
    boxSizing: 'border-box',
    minHeight: '100vh',
    backgroundColor: '#f5f6fa',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '20px'
  },
  logo: {
    display: 'flex',
    alignItems: 'center'
  },
  logoImage: {
    width: '284px',
    height: 'auto'
  },
  title: {
    display: 'inline-block',
    color: '#fff',
    fontSize: 20,
    paddingLeft: 16
  },
  header: {
    color: '#fff',
    fontSize: '2em',
    margin: 0
  },
  rightPaneContainer: {
    width: '500px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '@media(max-width: 1150px)': {
      paddingRight: '20px'
    }
  },
  rightPane: {
    width: '50%',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '40px 0px',
    minHeight: '100vh'
  },
  form: {
    width: '100%',
    margin: '0 auto'
  },
  logoForm: {
    textAlign: 'center'
  },
  logoFormImage: {
    height: 75,
    width: 'auto'
  },
  formHeader: {
    fontFamily: "'Sawarabi Mincho', serif",
    fontSize: '32px',
    textAlign: 'center',
    fontWeight: 'normal',
    marginTop: '40px',
    marginBottom: 0,
    boxSizing: 'borderBox',
    '@media(max-width: 1160px)': {
      fontSize: '29px'
    },
    '@media(max-width: 1080px)': {
      fontSize: '28px'
    }
  },
  formIntro: {
    fontFamily: 'Noto Sans JP, sans-serif',
    fontSize: '16px',
    marginTop: '24px',
    marginBottom: '40px',
    textAlign: 'center'
  },
  intro: {
    marginTop: '40px',
    backgroundColor: '#f5f6fa',
    border: 'solid 1px #dddddd',
    padding: '16px 24px 24px 24px',
    boxSizing: 'border-box',
    width: '100%',
    '& h3': {
      margin: 0,
      textAlign: 'center',
      fontFamily: 'Noto Sans JP, sans-serif',
      fontSize: '16px',
      fontWeight: 'bold'
    },
    '& p': {
      marginTop: '8px',
      marginBottom: '24px',
      fontFamily: 'Noto Sans JP, sans-serif',
      fontSize: '16px',
      '@media(max-width: 1160px)': {
        fontSize: '15px'
      },
      '@media(max-width: 1080px)': {
        fontSize: '14px'
      }
    },
    '& button': {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      borderRadius: '4px',
      border: 'solid 1px #202a6a',
      backgroundColor: '#ffffff',
      cursor: 'pointer',
      padding: 0,
      '& div': {
        height: '100%',
        borderRight: 'solid 1px #202a6a',
        padding: '3px',
        '& img': {
          display: 'block',
          height: '100%',
          width: '100%'
        }
      },
      '& p': {
        textAlign: 'center',
        width: '90%',
        margin: 0,
        fontFamily: 'Noto Sans JP, sans-serif',
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#202a6a'
      }
    }
  },
  link: {
    textDecoration: 'none'
  },
  transparentsSide: {
    width: '10%',
    height: '100%'
  },
  leftPaneContainer: {
    transform: 'translateX(100px)',
    '@media(max-width: 1620px)': {
      transform: 'translateX(140px)'
    },
    '@media(max-width: 1440px)': {
      transform: 'translateX(190px)'
    },
    '@media(max-width: 1300px)': {
      transform: 'translateX(230px)'
    },
    '@media(max-width: 1130px)': {
      transform: 'translateX(270px)'
    }
  },
  titleVideo: {
    display: 'inline-block',
    fontFamily: "'Sawarabi Mincho', serif",
    fontSize: '22px',
    color: '#525961',
    backgroundColor: '#ffff',
    padding: '6px 8px',
    marginLeft: '8px',
    '@media(max-width: 1300px)': {
      fontSize: '18px'
    }
  },
  videoContainer: {
    width: '991px',
    '& video': {
      width: '100%',
      height: '100%',
      border: 'solid 8px #f5f6fa',
      cursor: 'pointer',
      '@media(max-width: 1780px)': {
        width: '915px'
      },
      '@media(max-width: 1620px)': {
        width: '800px'
      },
      '@media(max-width: 1440px)': {
        width: '680px'
      },
      '@media(max-width: 1300px)': {
        width: '600px'
      },
      '@media(max-width: 1200px)': {
        width: '560px'
      },
      '@media(max-width: 1130px)': {
        width: '500px'
      },
      '@media(max-width: 1080px)': {
        width: '480px'
      }
    }
  },
  modalVideo: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    '@media(max-width: 1580px)': {
      width: '1200px',
      height: '676px'
    },
    '@media(max-width: 1240px)': {
      width: '900px',
      height: '506px'
    }
  }
});

// see http://redux-form.com/6.4.3/examples/material-ui/
const renderInput = ({ meta: { touched, error } = {}, input: { ...inputProps }, ...props }) => (
  <Input error={!!(touched && error)} helperText={touched && error} {...inputProps} {...props} fullWidth />
);
renderInput.propTypes = {
  meta: PropTypes.object,
  input: PropTypes.any
};

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  componentDidMount() {
    const token = getAuthToken();
    if (token) {
      this.props.history.push('/');
    }
  }
  login = auth => {
    this.props.userLogin(auth, this.props.location.state ? this.props.location.state.nextPathname : '/');
  };

  handleOpen = () =>
    this.setState({
      open: true
    });

  handleClose = () =>
    this.setState({
      open: false
    });

  render() {
    const { classes, isLoading } = this.props;
    const { open } = this.state;
    return (
      <React.Fragment>
        {!checkIsMobileDevice() ? (
          <div className={classes.main}>
            <div className={classes.leftPane}>
              <div className={classes.leftPaneContainer}>
                <div className={classes.titleVideo}>精密機器などの資産を一括管理「Ekuipp台帳」</div>
                <div className={classes.videoContainer}>
                  <video autoPlay="autoplay" muted onClick={this.handleOpen}>
                    <source src={VideoIntro} type="video/mp4" />
                  </video>
                </div>
              </div>
            </div>
            <div className={classes.rightPane}>
              <div className={classes.transparentsSide}></div>
              <div className={classes.rightPaneContainer}>
                <div className={classes.logoForm}>
                  <Link to="/">
                    <img className={classes.logoFormImage} src={LogoForm} alt="LogoForm" />
                  </Link>
                </div>
                <div>
                  <h5 className={classes.formHeader}>
                    Ekuipp(エクイップ)アカウントで
                    <br />
                    ログイン
                  </h5>
                </div>
                <p className={classes.formIntro}>
                  Ekuipp 台帳をご利用いただくには、
                  <br />
                  Ekuippアカウントが必要です。
                </p>
                <div className={classes.form}>
                  <LoginForm login={this.login} disabled={isLoading} />
                </div>
                <div className={classes.intro}>
                  <h3>Ekuippとは</h3>
                  <p>
                    Ekuippは中古計測器・測定器・精密機器の買取・購入販売
                    <br />
                    の売買マッチングサービスです。登録は無料。Ekuippに登
                    <br />
                    録すると、機器の購入や販売を簡単に行うことができます。
                  </p>
                  <a href="https://ekuipp.com/" rel="noopener noreferrer" target="_blank" className={classes.link}>
                    <button>
                      <div>
                        <img src={LogoIcon} alt="logo-icon" />
                      </div>
                      <p>Ekuipp ウェブサイトへ</p>
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <Notification />
            <Modal
              className={classes.modal}
              open={open}
              onClose={this.handleClose}
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 300
              }}
            >
              <Fade in={open}>
                <iframe
                  className={classes.modalVideo}
                  frameBorder="0"
                  allowFullScreen="1"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  title="YouTube video player"
                  width="1500"
                  height="850"
                  src="https://www.youtube.com/embed/5NFfZInLAZU?autoplay=1&amp;controls=1&amp;loop=1&amp;showinfo=0&amp;rel=0&amp;fs=0&amp;modestbranding=0&amp;iv_load_policy=3&amp;enablejsapi=1"
                ></iframe>
              </Fade>
            </Modal>
          </div>
        ) : (
          <LoginMobile />
        )}
      </React.Fragment>
    );
  }
}

Login.propTypes = {
  ...propTypes,
  authProvider: PropTypes.func,
  classes: PropTypes.object,
  previousRoute: PropTypes.string,
  translate: PropTypes.func.isRequired,
  userLogin: PropTypes.func.isRequired
};

const mapStateToProps = state => ({ isLoading: state.admin.loading > 0 });

const enhance = compose(
  translate,
  reduxForm({
    form: 'signIn',
    validate: (values, props) => {
      const errors = {};
      const { translate } = props;
      if (!values.username) {
        errors.username = translate('ra.validation.required');
      }
      if (!values.password) {
        errors.password = translate('ra.validation.required');
      }
      return errors;
    }
  }),
  connect(mapStateToProps, { userLogin }),
  withStyles(styles)
);

const EnhancedLogin = enhance(Login);

// We need to put the MuiThemeProvider decoration in another component
// Because otherwise the withStyles() HOC used in EnhancedLogin won't get
// the right theme
const LoginWithTheme = props => <EnhancedLogin {...props} />;

export default LoginWithTheme;
