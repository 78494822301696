import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { addField, Labeled } from 'react-admin';
import { MERCHANDISE, ERROR_MESSAGE, LABEL } from 'helpers/Const';
import AddIcon from '../../../../assets/add.svg';
import STYLE from 'styles/_constants.scss';

const styles = () => ({
  gallery: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  image: {
    width: 180,
    height: 135,
    backgroundSize: 'cover',
    border: '1px dashed #ddd',
    borderRadius: 2,
    marginTop: 8,
    marginRight: 16,
    display: 'inline-block',
    position: 'relative',
    '&:hover .wrapper': {
      display: 'flex !important'
    }
  },
  borderError: {
    border: ' 1px dashed #9b1d20'
  },
  addIcon: {
    fontSize: 50
  },
  deleteIcon: {
    fontSize: 30
  },
  error: {
    color: 'red',
    fontSize: '0.8rem'
  },
  label: {
    fontSize: 18,
    fontFamily: `${STYLE.fontFamily}`,
    color: '#3c3c3c',
    marginTop: 8
  },
  labelMessage: {
    fontSize: 14,
    color: '#f5bb00',
    fontFamily: `${STYLE.fontFamily}`
  },
  labelMessageLimit: {
    fontSize: 14,
    color: '#f5bb00',
    fontFamily: `${STYLE.fontFamily}`,
    marginTop: 8,
    display: 'grid',
    '& span': {
      marginBottom: 8
    }
  }
});

const wrapperStyle = {
  display: 'none',
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  right: 0,
  cursor: 'pointer',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(220,220,220,0.8)'
};

class ImageUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gallery: [],
      error: null
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const value = _.get(nextProps, 'input.value') || [];
    const gallery = value.map(item => {
      if (_.has(item, 'original.url')) {
        return {
          objectId: _.get(item, 'objectId'),
          src: _.get(item, 'original.url'),
          url: _.get(item, 'original.url')
        };
      } else {
        return item;
      }
    });

    if (!_.isEqual(gallery, prevState.gallery)) {
      return {
        gallery: gallery
      };
    }
    return null;
  }

  onSelectFile = evt => {
    const selectedFile = evt.target.files[0];

    if (selectedFile.size > MERCHANDISE.IMAGES_SIZE) {
      this.setState({ error: ERROR_MESSAGE.OVER_MAXIMUM_IMAGE_SIZE });
      return;
    }

    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = file => {
        const gallery = _.clone(this.state.gallery);
        gallery.push({
          src: selectedFile,
          url: _.get(file, 'target.result')
        });
        this.setState({
          gallery: gallery,
          error: null
        });
        this.upload.value = null;
        this.props.input.onChange(gallery);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  handleDelete = deleteIndex => {
    const gallery = _.cloneDeep(this.state.gallery);
    if (_.has(gallery[deleteIndex], 'objectId')) {
      _.set(gallery[deleteIndex], 'deleted', true);
    } else {
      gallery.splice(deleteIndex, 1);
    }
    this.setState({
      gallery: gallery
    });
    this.props.input.onChange(gallery);
  };

  render() {
    let { classes, label } = this.props;
    const { gallery, error } = this.state;
    const formError = _.get(this.props, 'meta.error[0]');
    if (!label) {
      label = LABEL.MERCHANDISE_IMAGE;
    }

    let elements = gallery.map(
      (item, index) =>
        !_.has(item, 'deleted') && (
          <div key={index} className={classes.image} style={{ backgroundImage: `url(${item.url})` }}>
            <div className="wrapper" style={wrapperStyle} onClick={() => this.handleDelete(index)}>
              <Button className={classes.wrapperButton}>
                <Delete className={classes.deleteIcon} />
              </Button>
            </div>
          </div>
        )
    );
    if (gallery.length < 10) {
      elements.push(
        <Button
          key="add"
          className={`${classes.image} ${formError && classes.borderError}`}
          onClick={e => {
            e.preventDefault();
            this.upload.click();
          }}
        >
          <img alt="add" src={AddIcon} className={classes.addIcon} />
        </Button>
      );
    }
    return (
      <Labeled classes={{ label: classes.label }} label={label}>
        <div>
          {!!error && <div className={classes.error}>{error}</div>}
          <div className={classes.labelMessage}>一番目の画像が商品一覧に表示さます</div>
          <div className={classes.labelMessageLimit}>
            <span> アップロードできる画像のデータサイズは1枚2MB以下、トータル10枚までです。</span>
            <span> また対応しているファイル形式はJPEG・PNG・BMPファイルです。</span>
          </div>
          <div className={classes.gallery}>{elements}</div>
          <input
            type="file"
            ref={ref => (this.upload = ref)}
            style={{ display: 'none' }}
            onChange={e => this.onSelectFile(e)}
            accept="image/*"
          />
        </div>
      </Labeled>
    );
  }
}

ImageUploader.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  required: PropTypes.bool,
  classes: PropTypes.any,
  label: PropTypes.string
};

export default addField(withStyles(styles)(ImageUploader));
