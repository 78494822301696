import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CustomFilter from 'components/CustomFilters';
import SearchInput from 'components/SearchInput';
import { LABEL } from 'helpers/Const';
import SelectField from 'components/CustomFields/SelectField';
import '../style.scss';
import CustomDateField from 'components/CustomFields/CustomDateField';
const { DATE_FIELD_TYPES, ORDERSTATE_CHOICES } = require('helpers/Const');

class TransactionFilter extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <CustomFilter className="transaction-search-form" {...rest}>
        <SearchInput
          placeholder={LABEL.LOG_ID_TITLE}
          label=""
          title={LABEL.LOG_ID_TITLE}
          className={classes.filterInput}
          source="logId"
          alwaysOn
          containerclassname="container_160"
        />
        <SearchInput
          label=""
          placeholder="Search"
          title={LABEL.SEARCH_FILTER}
          className={classes.filterInput}
          source="merchandiseName"
          containerclassname="container_472"
          alwaysOn
        />
        <SelectField
          label="ステータス"
          InputProps={{
            disableUnderline: true,
            classes: {
              input: classes.input
            }
          }}
          choices={ORDERSTATE_CHOICES}
          classes={{
            container: classes.container,
            bootstrapFormLabel: classes.bootstrapFormLabel
          }}
          alwaysOn
          optionValue="name"
          source="status"
          containerclassname="container_160"
        />
        <CustomDateField
          classes={{
            container: classes.container,
            bootstrapFormLabel: classes.bootstrapFormLabel
          }}
          customForm={true}
          label="から"
          containerclassname="container_160"
          alwaysOn
          type={DATE_FIELD_TYPES.YYYY_MM_DD}
          source="startDate"
        />
        <CustomDateField
          classes={{
            container: classes.container,
            bootstrapFormLabel: classes.bootstrapFormLabel
          }}
          customForm={true}
          type={DATE_FIELD_TYPES.YYYY_MM_DD}
          label="まで"
          containerclassname="container_160"
          alwaysOn
          source="endDate"
        />
      </CustomFilter>
    );
  }
}

TransactionFilter.propTypes = {
  classes: PropTypes.object,
  getSubCategoriesRequest: PropTypes.func,
  subCategories: PropTypes.array
};

const styles = {
  filterInput: {
    width: '100%',
    height: '48px',
    border: 'solid 1px #dddddd',
    backgroundColor: '#ffffff',
    borderRadius: '4px',
    margin: 0
  },
  input: {
    padding: 0,
    lineHeight: '24px',
    width: '105px'
  },
  customSearch: {
    marginTop: '0px !important',
    padding: '12px 16px'
  },
  customSelect: {
    marginTop: '0px !important',
    padding: '12px 16px'
  },
  container: {
    margin: 0
  },
  bootstrapFormLabel: {
    lineHeight: '24px',
    fontSize: '16px',
    fontWeight: 500
  },
  longElem: {
    maxWidth: '160px'
  }
};

export default withStyles(styles)(TransactionFilter);
