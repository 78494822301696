import React from 'react';
import Datagrid from './Datagrid';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import DatagridRow from './DatagridRow';
import DatagridBody from './DatagridBody';
import './style.scss';
const styles = {
  rowCell: { borderRight: '1px solid rgba(224, 224, 224, 1)' },
  selectedCell: { boxShadow: '4px 0 20px 0 rgba(205, 205, 205, 0.5)' },
  boldCell: {
    fontWeight: 'bold'
  }
};

const CustomDatagridRow = withStyles(styles)(({ classes, className, ...props }) => {
  return (
    <DatagridRow
      className="list-row"
      classes={{ rowCell: classes.rowCell, selectedCell: classes.selectedCell, boldCell: classes.boldCell }}
      {...props}
    />
  );
});

const CustomDatagridBody = ({ classes, ...props }) => {
  return <DatagridBody classes={{ rowCell: classes.rowCell }} {...props} row={<CustomDatagridRow />} />;
};

export const CustomDatagrid = ({ colWidth = 120, ...props }) => {
  colWidth = colWidth < 120 ? 120 : colWidth;
  return <Datagrid colWidth={colWidth} {...props} body={<CustomDatagridBody />} />;
};

CustomDatagridRow.propTypes = {
  classes: PropTypes.object
};

CustomDatagridBody.propTypes = {
  classes: PropTypes.object
};
CustomDatagrid.propTypes = {
  colWidth: PropTypes.number
};
