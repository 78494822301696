import { DELETE_MANY, UPDATE_MANY, setListSelectedIds } from 'react-admin';
import { setPopup } from 'containers/Popup/actions';
import _ from 'lodash';
import { push } from 'react-router-redux';
import { DELETE_MESSAGE, CUSTOM_ACTION, SELLSTATE, LIMIT_PER_PAGE } from 'helpers/Const';

export const BULK_DELETE = 'BULK_DELETE';
export const BULK_UPDATE = 'BULK_UPDATE';
export const BULK_EXPORT = 'BULK_EXPORT';
export const bulkDelete = (ids, basePath, dispatch) => ({
  type: BULK_DELETE,
  payload: { ids },
  meta: {
    resource: 'merchandise',
    fetch: DELETE_MANY,
    onSuccess: {
      unselectAll: true,
      refresh: true,
      callback: () => {
        dispatch(setPopup('deletePopup', false));
      }
    },
    onFailure: {
      unselectAll: true,
      basePath: `${basePath}`,
      refresh: true,
      callback: data => {
        const errorIds = _.get(data, 'error.payload', []);
        const idString = errorIds
          .map(i => {
            return `"${i}"`;
          })
          .join(',');
        const path = `/merchandiseError?filter=%7B"ids"%3A[${idString}]%7D&order=DESC&page=1&perPage=${LIMIT_PER_PAGE}&sort=createdAt`;
        const title = DELETE_MESSAGE.SUB_TITLE;
        const content = `${errorIds.length}件の商品を削除できませんでした。
            取引中の商品は削除することができません。`;
        dispatch(setPopup('deletePopup', false));
        dispatch(
          setPopup('confirmPopup', {
            title,
            content,
            type: 'warning',
            showClose: false,
            callback: () => {
              dispatch(push(path));
            }
          })
        );
      }
    }
  }
});

export const bulkUpdate = (ids, sellState, basePath, dispatch) => ({
  type: BULK_UPDATE,
  payload: { ids, sellState },
  meta: {
    resource: 'merchandise',
    fetch: UPDATE_MANY,
    onSuccess: {
      unselectAll: true,
      basePath: `${basePath}`,
      refresh: true
    },
    onFailure: {
      unselectAll: true,
      basePath: `/merchandise`,
      refresh: true,
      callback: data => {
        const errorIds = _.get(data, 'error.payload', []);
        dispatch(setListSelectedIds('merchandise', errorIds));
        if (sellState === SELLSTATE.SELLING) {
          dispatch(
            setPopup('confirmPopup', {
              title: '出品に必要な情報が不足しています',
              content: '商品詳細画面で情報を追加してください'
            })
          );
        } else {
          dispatch(
            setPopup('confirmPopup', {
              title: '商品の更新',
              content: `${errorIds.length}つの商品のステータスが変更できませんでした`
            })
          );
        }
      }
    }
  }
});

export const selectExport = (filter, basePath, exporter, dispatch) => {
  return {
    type: BULK_EXPORT,
    payload: { filter },
    meta: {
      resource: 'merchandise',
      fetch: CUSTOM_ACTION.GET_EXPORT_LIST,
      onSuccess: {
        unselectAll: true,
        basePath: `${basePath}`,
        callback: data => {
          const exportdata = _.get(data, 'payload.data');
          exporter(exportdata, null, dispatch);
        }
      },
      onFailure: {
        unselectAll: false,
        basePath: `${basePath}`
      }
    }
  };
};
