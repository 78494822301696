import React from 'react';
import { delay } from 'redux-saga';
import { takeLatest, put, select } from 'redux-saga/effects';
import _ from 'lodash';
import { crudGetList, showNotification } from 'react-admin';
import { setPopup } from 'containers/Popup/actions';
import { UPLOAD_CSV } from 'helpers/Const';
import ProgressCircle from 'components/ProgressCircle/index';
import StatusCircle from 'components/StatusCircle/index';
import DownloadBtn from 'containers/Merchandise/MerchandiseImport/components/DownloadBtn';
import {
  getParentCategoriesSuccess,
  getParentCategoriesError,
  getSubCategoriesSuccess,
  getSubCategoriesError,
  getManufacturersSuccess,
  getManufacturersError,
  setImportFileSuccess,
  setImportFileFailure,
  getPrefecturesSuccess,
  getPrefecturesFailure
} from './actions';
import {
  GET_PARENT_CATEGORIES_REQUEST,
  GET_SUB_CATEGORIES_REQUEST,
  GET_MANUFACTURERS_REQUEST,
  SET_IMPORT_FILE,
  GET_PREFECTURES_REQUEST
} from './constants';
import dataProvider from 'providers/dataProvider';

export function* getParentCategories() {
  try {
    const result = yield dataProvider(GET_PARENT_CATEGORIES_REQUEST, 'category');
    yield put(getParentCategoriesSuccess(result));
  } catch (err) {
    yield put(getParentCategoriesError(err));
  }
}

export function* getSubCategories(action) {
  try {
    const result = yield dataProvider(GET_SUB_CATEGORIES_REQUEST, 'category', action.data);
    yield put(getSubCategoriesSuccess(result));
  } catch (err) {
    yield put(getSubCategoriesError(err));
  }
}

export function* getManufacturers(action) {
  try {
    const result = yield dataProvider(GET_MANUFACTURERS_REQUEST, 'manufacturer', action.data);
    yield put(getManufacturersSuccess(result));
  } catch (err) {
    yield put(getManufacturersError(err));
  }
}

export function* uploadImportCSV(action) {
  try {
    const file = _.get(action, 'data.file');
    yield put(
      setPopup('confirmPopup', {
        icon: <ProgressCircle percentage={0} />,
        title: UPLOAD_CSV.UPLOAD_TITLE,
        content: UPLOAD_CSV.UPLOADING_TEXT,
        disabled: true
      })
    );
    const result = yield dataProvider(SET_IMPORT_FILE, 'merchandise', file);
    const params = yield select(state => state.admin.resources.merchandise.list.params);

    const successMerchs = _.get(result, 'data.successMerchs', []);
    if (!_.isEmpty(successMerchs)) {
      yield put(crudGetList('merchandise', params));
    }
    const fileUrl = _.get(result, 'data.fileUrl');
    const pathTransitionDuration = 2;
    if (fileUrl) {
      yield put(
        setPopup('confirmPopup', {
          icon: <ProgressCircle pathTransitionDuration={pathTransitionDuration} type="fail" percentage={100} />,
          title: UPLOAD_CSV.UPLOAD_TITLE,
          content: UPLOAD_CSV.UPLOADING_TEXT,
          disabled: true
        })
      );
      yield delay(pathTransitionDuration * 1000);
      yield put(
        setPopup('confirmPopup', {
          title: UPLOAD_CSV.UPLOAD_TITLE,
          content: UPLOAD_CSV.ERROR_TEXT,
          icon: <StatusCircle circleType="fail" />,
          subBtn: <DownloadBtn download={true} text={UPLOAD_CSV.ERROR_LIST} link={fileUrl} />
        })
      );
    } else {
      yield put(
        setPopup('confirmPopup', {
          icon: <ProgressCircle pathTransitionDuration={pathTransitionDuration} type="success" percentage={100} />,
          title: UPLOAD_CSV.UPLOAD_TITLE,
          content: UPLOAD_CSV.UPLOADING_TEXT,
          disabled: true
        })
      );
      yield delay(pathTransitionDuration * 1000);
      yield put(
        setPopup('confirmPopup', {
          title: UPLOAD_CSV.UPLOAD_TITLE,
          content: UPLOAD_CSV.COMPLETE_TEXT,
          icon: <StatusCircle circleType="success" />
        })
      );
    }
    yield put(setImportFileSuccess(result));
  } catch (err) {
    yield put(setImportFileFailure(err));
  }
}

export function* getPrefectures() {
  try {
    const result = yield dataProvider(GET_PREFECTURES_REQUEST, 'merchandise');
    const payload = _.get(result, 'data.result.prefectures', []).map(prefecture => ({
      id: prefecture,
      name: prefecture
    }));
    yield put(getPrefecturesSuccess(payload));
  } catch (err) {
    yield put(showNotification(_.get(err, 'message', ''), 'warning'));
    yield put(getPrefecturesFailure(err));
  }
}

export default function* saga() {
  yield takeLatest(GET_PARENT_CATEGORIES_REQUEST, getParentCategories);
  yield takeLatest(GET_SUB_CATEGORIES_REQUEST, getSubCategories);
  yield takeLatest(GET_MANUFACTURERS_REQUEST, getManufacturers);
  yield takeLatest(SET_IMPORT_FILE, uploadImportCSV);
  yield takeLatest(GET_PREFECTURES_REQUEST, getPrefectures);
}
