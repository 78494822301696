import { GET_LIST, CREATE, GET_MANY } from 'react-admin';
import { GET_PARENT_CATEGORIES_REQUEST, GET_SUB_CATEGORIES_REQUEST } from 'containers/Merchandise/constants';
import _ from 'lodash';

export default (axiosInstance, type, params) => {
  switch (type) {
    case GET_PARENT_CATEGORIES_REQUEST: {
      return axiosInstance.post('/functions/getListParentCategories').then(response => {
        const data = _.get(response, 'data.result');
        return Promise.resolve(data);
      });
    }

    case GET_SUB_CATEGORIES_REQUEST: {
      return axiosInstance.post('/functions/getListChildCategories', params).then(response => {
        const data = _.get(response, 'data.result');
        return Promise.resolve(data);
      });
    }
    case GET_MANY:
    case GET_LIST: {
      return axiosInstance.post('/functions/getListChildCategories').then(response => {
        const data = _.get(response, 'data.result');
        data.forEach(item => {
          item.id = item.objectId;
        });
        return Promise.resolve({
          data,
          total: data.length
        });
      });
    }

    case CREATE: {
      return axiosInstance.post('/category/add', params.data).then(response => {
        const data = _.get(response, 'data.result');
        data.id = data.objectId;
        return Promise.resolve({ data });
      });
    }

    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }
};
