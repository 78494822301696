import React from 'react';
import _ from 'lodash';
import { STATUS } from 'helpers/Const';
const CategoryField = ({ source, record = {}, maxWidth = 245, className = '' }) => {
  if (_.get(record, `${source}.status`) === STATUS.ACTIVE && _.get(record, `${source}.name`)) {
    return (
      <div className={className} style={{ maxWidth: `${maxWidth}px` }}>
        {_.get(record, `${source}.name`)}
      </div>
    );
  } else return null;
};

export default CategoryField;
