import React, { Component, Fragment, isValidElement } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import { ExpandMoreIcon, ExpandUpIcon } from './GridIcon';

import classnames from 'classnames';
import { linkToRecord } from 'ra-core';
import { DatagridCell } from 'react-admin';
import _ from 'lodash';
import './style.scss';
import { RESOURCES } from 'helpers/Const';

const sanitizeRestProps = ({
  basePath,
  children,
  classes,
  className,
  rowClick,
  id,
  isLoading,
  onToggleItem,
  push,
  record,
  resource,
  selected,
  style,
  styles,
  ...rest
}) => rest;

class DatagridRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      colSpan: this.computeColSpan(props)
    };
  }

  coomponentDidUpdate = (prevProps, prevState) => {
    const colSpan = this.computeColSpan(this.props);
    if (colSpan !== prevState.colSpan) {
      this.setState({ colSpan });
    }
  };

  handleToggleExpanded = event => {
    this.setState(state => ({ expanded: !state.expanded }));
    event.stopPropagation();
  };

  handleToggle = event => {
    this.props.onToggleItem(this.props.id);
    event.stopPropagation();
  };

  handleClick = async event => {
    const { basePath, rowClick, id, record } = this.props;
    if (!rowClick) return;

    if (typeof rowClick === 'function') {
      const path = await rowClick(id, basePath, record);
      this.handleRedirection(path, event);
      return;
    }

    this.handleRedirection(rowClick, event);
  };

  handleRedirection = (path, event) => {
    const { basePath, id, push } = this.props;

    if (path === 'edit') {
      push(linkToRecord(basePath, id));
      return;
    }
    if (path === 'show') {
      push(linkToRecord(basePath, id, 'show'));
      return;
    }
    if (path === 'expand') {
      this.handleToggleExpanded(event);
      return;
    }

    push(path);
  };

  computeColSpan = props => {
    const { children, hasBulkActions } = props;
    return (
      1 + // show expand button
      (hasBulkActions ? 1 : 0) + // checkbox column
      React.Children.toArray(children).filter(child => !!child).length // non-null children
    );
  };

  render() {
    const {
      basePath,
      children,
      classes,
      className,
      expand,
      hasBulkActions,
      hover,
      id,
      record,
      resource,
      selected,
      style,
      styles,
      fixedColNumber,
      colWidth,
      currentUser,
      ...rest
    } = this.props;
    const { expanded, colSpan } = this.state;
    const userId = _.get(currentUser, 'objectId');
    const seenUsers = _.get(record, 'seenUsers');
    const isSeen = _.includes(seenUsers, userId);
    return (
      <Fragment>
        <TableRow
          className={`${className} ${expanded && 'expanding-cell'} ${(basePath === '/merchandise' ||
            basePath === '/merchandiseForSale') &&
            'clickable-row'} `}
          key={id}
          style={style}
          hover={hover}
          {...sanitizeRestProps(rest)}
          onClick={this.handleClick}
        >
          {expand && (
            <TableCell padding="none" className={`${classes.expandIconCell} ${!isSeen && 'unseen-cell'} `}>
              <IconButton
                className={classNames(classes.expandIcon, {
                  [classes.expanded]: expanded
                })}
                component="div"
                tabIndex={-1}
                aria-hidden="true"
                role="expand"
                onClick={this.handleToggleExpanded}
              >
                {this.state.expanded ? <ExpandMoreIcon /> : <ExpandUpIcon />}
              </IconButton>
            </TableCell>
          )}
          {hasBulkActions && (
            <TableCell className={classnames(fixedColNumber > 0 && 'checkbox-sticky')} padding="none">
              <Checkbox
                color="primary"
                className={`select-item ${classes.checkbox}`}
                checked={selected}
                onClick={this.handleToggle}
              />
            </TableCell>
          )}

          {React.Children.map(children, (field, index) => {
            const source = _.get(field, 'props.source');
            let subClassName = '';
            let style = {};
            if (fixedColNumber > 0 && index < fixedColNumber) {
              subClassName = hasBulkActions ? 'id-sticky' : 'id-sticky-no-bulk';
              style = {
                maxWidth: `${colWidth - 48}px`,
                minWidth: `${colWidth - 48}px`,
                left: `${colWidth * index + (hasBulkActions && 48) + (index > 1 && index - 1)}px`
              };
            }
            return isValidElement(field) ? (
              <DatagridCell
                onClick={source === 'id' ? this.handleClick : () => {}}
                style={style}
                key={`${id}-${field.props.source || index}`}
                className={classnames(
                  `column-${field.props.source}`,
                  classes.rowCell,
                  subClassName,
                  resource === RESOURCES.TRANSACTION.name && !isSeen && source !== 'status' && classes.boldCell
                )}
                record={record}
                id={id}
                {...{ field, basePath, resource }}
              />
            ) : null;
          })}
        </TableRow>

        {expand && expanded && (
          <TableRow key={`${id}-expand`}>
            <TableCell className="expand-section" colSpan={colSpan} role="expand-content">
              {React.cloneElement(expand, {
                record,
                basePath,
                resource,
                id: String(id)
              })}
            </TableCell>
          </TableRow>
        )}
      </Fragment>
    );
  }
}

DatagridRow.propTypes = {
  basePath: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  expand: PropTypes.node,
  hasBulkActions: PropTypes.bool.isRequired,
  hover: PropTypes.bool,
  id: PropTypes.any,
  onToggleItem: PropTypes.func,
  push: PropTypes.func,
  record: PropTypes.object.isRequired,
  resource: PropTypes.string,
  rowClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  selected: PropTypes.bool,
  style: PropTypes.object,
  styles: PropTypes.object,
  fixedColNumber: PropTypes.number,
  colWidth: PropTypes.number,
  currentUser: PropTypes.object
};

DatagridRow.defaultProps = {
  hasBulkActions: false,
  hover: true,
  record: {},
  selected: false
};

DatagridRow.displayName = 'DatagridRow';
const mapStateToProp = state => {
  return {
    currentUser: _.get(state, 'admin.resources.user.data.currentUser')
  };
};
export default connect(mapStateToProp, { push })(DatagridRow);
