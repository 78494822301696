import React from 'react';
import PropTypes from 'prop-types';
import { Pagination } from 'react-admin';
import List from 'components/CustomList';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import { withStyles } from '@material-ui/core/styles';
import MerchandiseFilter from 'containers/Merchandise/MerchandiseList/components/MerchandiseFilter';
import { DATE_FORMAT, RESOURCES, LABEL } from 'helpers/Const';
import moment from 'moment';
import { CustomDatagrid } from 'components/CustomDatagrid';
import DateFormatField from 'components/DateFormatField';
import _ from 'lodash';
import SellStateField from 'components/StatusField/SellState';
import TextFieldForGrid from 'components/CustomFieldForGrid/TextField';
import UnixTimeField from 'components/CustomFieldForGrid/UnixTimeField';
import './style.scss';
import PostActions from './components/CustomActions';
import { checkIsChrome } from '../../../helpers/Utils';
const styles = {
  image: { maxHeight: '1rem' },
  card: {
    minWidth: 275
  },
  toolbar: {
    display: 'unset',
    padding: 0
  }
};

const exporter = data => {
  const merchToExport = [
    {
      id: 'ID',
      holdingCategory: '保有区分',
      assetNumber: '機器管理番号',
      name: '機器名称	',
      parentCate: '機器カテゴリー(大)',
      categoryName: '機器カテゴリー(小)',
      manufacturerName: 'メーカー名',
      modelNumber: 'メーカー型番',
      serialNumber: 'シリアル番号	',
      officer: '管理担当者',
      storageAndAvailableArea: '保管、使用場所',
      targetOfCalibration: '校正対象',
      calibrationCycle: '校正周期',
      calibrationAppliedDate: '校正実施日',
      calibrationDate: '校正期日',
      calibrationResult: '校正結果',
      fixedAssets: '固定資産対象',
      purchasedDate: '購入日',
      originalPrice: '購入価格',
      serviceLife: '耐用年数',
      realMarketValue: '現在の市場価値',
      images_url: '写真',
      statusOfAdjustment: '校正状況',
      expirationOfAdjustment: '校正期限',
      description: '商品説明',
      usaged: '使用履歴',
      delivery: '出荷可能時期',
      price: '価格 ※半角数字',
      sellState: 'Status'
    }
  ];
  for (let merch of data) {
    const formatExpirationDate = merch.expirationOfAdjustment
      ? moment.unix(merch.expirationOfAdjustment).format(DATE_FORMAT.SIMPLE)
      : '';
    merchToExport.push({
      ...merch,
      categoryName: _.get(merch, 'category.name'),
      manufacturerName: _.get(merch, 'manufacturer.name'),
      images_url: merch.images ? merch.images.map(img => img.original.url) : '',
      expirationOfAdjustment: formatExpirationDate
    });
  }
  const csv = convertToCSV(
    {
      data: merchToExport,
      fields: [
        'id',
        'holdingCategory',
        'assetNumber',
        'name',
        'parentCate',
        'categoryName',
        'manufacturerName',
        'modelNumber',
        'serialNumber',
        'officer',
        'storageAndAvailableArea',
        'targetOfCalibration',
        'calibrationCycle',
        'calibrationAppliedDate',
        'calibrationDate',
        'calibrationResult',
        'fixedAssets',
        'purchasedDate',
        'originalPrice',
        'serviceLife',
        'realMarketValue',
        'images_url',
        'statusOfAdjustment',
        'expirationOfAdjustment',
        'description',
        'usaged',
        'delivery',
        'price',
        'sellState'
      ] // order fields in the export
    },
    {
      header: false //hide default fields name
    }
  );
  let tempLink = document.createElement('a');
  tempLink.setAttribute('href', 'data:text/plain;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csv));
  tempLink.setAttribute('download', 'merchandises.csv');
  if (checkIsChrome()) {
    tempLink.click();
  } else {
    tempLink.dispatchEvent(new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window }));
  }
};

const MerchandiseList = props => {
  const { classes, resource } = props;
  const listProps = _.omit(props, ['dispatch', 'classes']);
  const gridProps = _.omit(listProps, ['hasShow', 'hasList', 'hasEdit']);
  let title = '';
  if (resource === RESOURCES.MERCHANDISE.name) {
    title = RESOURCES.MERCHANDISE.label;
  }
  if (resource === RESOURCES.MERCHANDISE_FOR_SALE.name) {
    title = RESOURCES.MERCHANDISE_FOR_SALE.label;
  }
  return (
    <List
      title="台帳"
      actions={<PostActions exporter={exporter} />}
      filters={<MerchandiseFilter title={title} resource={RESOURCES.MERCHANDISE.name} />}
      exporter={exporter}
      sort={{ field: 'createdAt', order: 'DESC' }}
      classes={{ card: classes.card, toolbar: classes.toolbar }}
      pagination={<Pagination rowsPerPageOptions={[20, 50, 100]} />}
      {...listProps}
    >
      <CustomDatagrid
        tableClassName="merchandise-table"
        fixedColNumber={1}
        colWidth={155}
        {...gridProps}
        rowClick="show"
      >
        <SellStateField source="sellState" label={LABEL.STATUS} />
        <TextFieldForGrid className="cell-content" source="assetNumber" label={LABEL.CONTROL_NUMBER} />
        <TextFieldForGrid className="cell-content" source="name" label={LABEL.NAME} />
        <TextFieldForGrid className="cell-content" source="manufacturer.name" sortable={false} label={LABEL.MAKER} />
        <TextFieldForGrid className="cell-content" source="modelNumber" label={LABEL.MAKER_MODEL_NUMBER} />
        <TextFieldForGrid className="cell-content" source="serialNumber" label={LABEL.SERIAL_NUMBER} />
        <TextFieldForGrid className="cell-content" source="officer" label={LABEL.OFFICER} />
        <TextFieldForGrid
          className="cell-content"
          source="storageAndAvailableArea"
          label={LABEL.STORAGE_AND_AVAILABLE_AREA}
        />
        <TextFieldForGrid className="cell-content" source="purchasedDate" label={LABEL.PURCHASED_DATE} />
        <UnixTimeField
          className="cell-content"
          source="expirationOfAdjustment"
          label={LABEL.EXPIRATION_OF_ADJUSTMENT}
        />
        <DateFormatField
          className="cell-content"
          source="createdAt"
          label={LABEL.CREATED_DATE}
          dateFormat={DATE_FORMAT.DIV}
        />
        <TextFieldForGrid className="cell-content" source="createdUserName" label={LABEL.CREATED_BY} />
      </CustomDatagrid>
    </List>
  );
};

MerchandiseList.propTypes = {
  selectedIds: PropTypes.any,
  dispatch: PropTypes.any,
  classes: PropTypes.object,
  resource: PropTypes.string
};

export default withStyles(styles)(MerchandiseList);
