const { ADJUSTMENT, ACCESSORY, TIMESPAN } = require('./Const');
const initObj = { // eslint-disable-line
  //INPUT
  price: 60000, //input price
  year: 3,
  assurances: true,
  calibration: ADJUSTMENT.INAPPLICABLE,
  validation: true,
  delivery: TIMESPAN.LATE,
  accesory: ACCESSORY.NO_ACCESS,

  //INDEPENDENT
  rate: 0,
  rateUpperLimit: 0,
  rateLowerLimit: 10,

  //OUTPUT
  result: 0, //output price
  resultUpRange: 0,
  resultLowRange: 0
};
//all func return new copy for immutability purpose
function calcYear(obj) {
  if (obj.year === 1) {
    return { ...obj, rate: obj.rate + 70, rateUpperLimit: 70 };
  }
  if (obj.year === 2) {
    return { ...obj, rate: obj.rate + 60, rateUpperLimit: 60 };
  }
  if (obj.year === 3) {
    return { ...obj, rate: obj.rate + 55, rateUpperLimit: 55 };
  }
  if (obj.year === 4) {
    return { ...obj, rate: obj.rate + 50, rateUpperLimit: 50 };
  }
  if (obj.year === 5) {
    return { ...obj, rate: obj.rate + 40, rateUpperLimit: 40 };
  }
  if (obj.year > 5) {
    return { ...obj, rate: obj.rate + 20, rateUpperLimit: 40 };
  }
  return { ...obj, rate: obj.rate + 20, rateUpperLimit: 40 };
}

function calcAssurance(obj) {
  if (obj.assurances) {
    return { ...obj, rate: Math.min(obj.rate + 20, obj.rateUpperLimit) };
  }
  return { ...obj, rate: Math.min(obj.rate - 5, obj.rateUpperLimit) };
}

function calcCalibration(obj) {
  switch (obj.calibration) {
    case ADJUSTMENT.CALIBRATED:
      return { ...obj, rate: Math.min(obj.rate + 20, obj.rateUpperLimit) };
    case ADJUSTMENT.UNCALIBRATED:
      return { ...obj, rate: Math.min(obj.rate - 10, obj.rateUpperLimit) };
    case ADJUSTMENT.INAPPLICABLE:
      return { ...obj, rate: Math.min(obj.rate, obj.rateUpperLimit) };
    case ADJUSTMENT.UNKNOWN:
      return { ...obj, rate: Math.min(obj.rate - 10, obj.rateUpperLimit) };
    default:
      return { ...obj, rate: Math.min(obj.rate - 10, obj.rateUpperLimit) };
  }
}

function calcValidate(obj) {
  if (obj.validation) {
    return { ...obj, rate: Math.min(obj.rate + 30, obj.rateUpperLimit) };
  }
  return { ...obj, rate: Math.min(obj.rate - 30, obj.rateUpperLimit) };
}

function calcAccessory(obj) {
  switch (obj.accessory) {
    case ACCESSORY.NO_ACCESS:
      return { ...obj, rate: Math.min(obj.rate - 15, obj.rateUpperLimit) };
    case ACCESSORY.YES_ACCESS:
      return { ...obj, rate: Math.min(obj.rate + 5, obj.rateUpperLimit) };
    case ACCESSORY.INAPPLICABLE:
      return { ...obj, rate: Math.min(obj.rate, obj.rateUpperLimit) };
    default:
      return { ...obj, rate: Math.min(obj.rate, obj.rateUpperLimit) };
  }
}

function calcDelivery(obj) {
  switch (obj.delivery) {
    case TIMESPAN.EARLY:
      return { ...obj, rate: Math.min(obj.rate + 5, obj.rateUpperLimit) };
    case TIMESPAN.NORUSH:
      return { ...obj, rate: Math.min(obj.rate + 2, obj.rateUpperLimit) };
    case TIMESPAN.LATE:
      return { ...obj, rate: Math.min(obj.rate - 2, obj.rateUpperLimit) };
    case TIMESPAN.DELAYED:
      return { ...obj, rate: Math.min(obj.rate - 5, obj.rateUpperLimit) };
    default:
      return { ...obj, rate: Math.min(obj.rate, obj.rateUpperLimit) };
  }
}

function calcResult(obj) {
  let fixedrate = Math.max(Math.min(obj.rate, obj.rateUpperLimit), obj.rateLowerLimit);
  const res = (obj.price * fixedrate) / 100;
  return { ...obj, rate: fixedrate, result: res, resultUpRange: res * 1.1, resultLowRange: res * 0.9 };
}

export default function getSuggestPrice(obj) {
  let resultobj;
  const inputObj = Object.assign({}, obj, {
    rate: 0,
    rateUpperLimit: 0,
    rateLowerLimit: 10
  });
  resultobj = calcYear(inputObj);
  resultobj = calcAssurance(resultobj);
  resultobj = calcCalibration(resultobj);
  resultobj = calcAccessory(resultobj);
  resultobj = calcDelivery(resultobj);
  resultobj = calcValidate(resultobj);
  resultobj = calcResult(resultobj);
  return resultobj;
}
