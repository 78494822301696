import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormControl, FormHelperText, TextField } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { withStyles } from '@material-ui/core/styles';
import { addField } from 'react-admin';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import STYLE from 'styles/_constants.scss';
const styles = theme => ({
  container: {
    marginTop: 24,
    marginBottom: 8,
    fontFamily: `${STYLE.fontFamily}`
  },
  input: {
    fontFamily: `${STYLE.fontFamily}`,
    height: '48px'
  },
  grayInput: {
    backgroundColor: '#f5f6fa'
  },
  bootstrapInput: {
    fontFamily: `${STYLE.fontFamily}`,
    borderRadius: 4,
    border: '1px solid #dddddd',
    fontSize: 16,
    lineHeight: '24px',
    padding: '12px 16px',
    width: 'calc(100% - 24px)',
    transition: theme.transitions.create(['border-color']),

    '&:focus': {
      borderColor: '#6bc5e8'
    }
  },
  bootstrapInputError: {
    borderColor: '#9b1d20'
  },
  bootstrapFormLabel: {
    fontSize: '14px',
    lineHeight: '20px',
    color: '#3c3c3c'
  },
  bootstrapFormMessage: {
    color: '#f5bb00',
    fontSize: 14,
    lineHeight: '20px',
    marginBottom: '8px'
  },
  bootstrapFormSuggest: {
    fontSize: '16px',
    color: '#00a3df',
    marginLeft: 'auto',
    cursor: 'pointer',
    lineHeight: '24px',
    '@media (max-width: 1024px)': {
      fontSize: '10px'
    }
  },
  groupLabel: {
    display: 'flex',
    marginBottom: 8
  },
  require: {
    color: '#9b1d20'
  },
  errorIcon: {
    width: '16px',
    height: '16px',
    position: 'relative',
    left: '-4px',
    top: '3px'
  },
  errorMessage: {
    marginLeft: '4px'
  }
});

class BorderTextField extends React.Component {
  render() {
    const {
      classes,
      label,
      placeholder,
      suggest,
      message,
      className,
      required,
      handleClick,
      customForm = false,
      disabled
    } = this.props;
    let touched = _.get(this.props, 'meta.touched');
    const error = customForm ? _.get(this.props, 'meta.error') : _.get(this.props, 'meta.error[0]');
    return (
      <FormControl fullWidth className={`${classes.container} ${className}`}>
        <div className={classes.groupLabel}>
          <Fragment>
            <div className={classes.bootstrapFormLabel}>
              {label}
              {required && <span className={classes.require}> *</span>}
            </div>
          </Fragment>
          {suggest && (
            <div onClick={handleClick} className={classes.bootstrapFormSuggest}>
              {suggest}
            </div>
          )}
        </div>
        {message && <div className={classes.bootstrapFormMessage}>{message}</div>}
        <TextField
          {...this.props.input}
          id="outlined-bare"
          placeholder={placeholder || label}
          InputProps={{
            readOnly: disabled,
            disableUnderline: true,
            classes: {
              root: `${classes.input} ${disabled && classes.grayInput} `,
              input: `${classes.bootstrapInput} ${error && touched && classes.bootstrapInputError} `
            }
          }}
          error={!!(touched && error)}
        />
        {!!(touched && error) && (
          <FormHelperText id="helper-text" error>
            <span className={classes.errorMessage}>
              <ErrorIcon className={classes.errorIcon} />
              {touched && error}
            </span>
          </FormHelperText>
        )}
      </FormControl>
    );
  }
}

BorderTextField.propTypes = {
  input: PropTypes.object,
  disabled: PropTypes.bool,
  meta: PropTypes.object,
  classes: PropTypes.any,
  type: PropTypes.any,
  label: PropTypes.any,
  placeholder: PropTypes.any,
  suggest: PropTypes.any,
  className: PropTypes.any,
  message: PropTypes.string,
  sellState: PropTypes.string,
  required: PropTypes.bool,
  source: PropTypes.string,
  customForm: PropTypes.bool,
  handleClick: PropTypes.func
};

const mapStateToProps = state => ({ sellState: _.get(state, 'form.record-form.values.sellState') });

export default addField(compose(connect(mapStateToProps), withStyles(styles))(BorderTextField));
