import { GET_ONE, GET_LIST, UPDATE, DELETE, UPDATE_MANY, CREATE } from 'react-admin';
import _ from 'lodash';
import moment from 'moment';
import { FILE_TYPE, SELLSTATE } from 'helpers/Const';
import Uploader from 'helpers/Uploader';
import { DELETE_MANY } from 'ra-core';
import { RESOURCES, CUSTOM_ACTION } from 'helpers/Const';
import { SET_IMPORT_FILE, GET_PREFECTURES_REQUEST } from 'containers/Merchandise/constants';
import { getAxiosInstant } from 'helpers/Uploader';
export default async (axiosInstance, type, params, resource) => {
  switch (type) {
    case GET_ONE: {
      return axiosInstance.post('/functions/getMerchById', { merchandiseId: params.id }).then(response => {
        const data = _.get(response, 'data.result', {});
        _.set(data, 'id', data.objectId);
        _.set(data, 'categoryParentId', _.get(data, 'category.parent.objectId'));
        const expirationOfAdjustment = _.get(response, 'data.result.expirationOfAdjustment');
        if (expirationOfAdjustment) {
          _.set(data, 'expirationOfAdjustment', moment.unix(expirationOfAdjustment).format('YYYY/MM'));
        }
        const createdAt = _.get(response, 'data.result.createdAt');
        _.set(data, 'createdAt', moment(createdAt).format('YYYY/MM/DD'));
        return Promise.resolve({ data });
      });
    }

    case GET_LIST: {
      let filter = { ...params.filter };
      const category = _.get(params, 'filter.category');
      if (category && !Array.isArray(category)) {
        filter = { ...filter, category: [category] };
      }
      let page = _.get(params, 'pagination.page', 0);
      let perPage = _.get(params, 'pagination.perPage', 0);
      let field = _.get(params, 'sort.field');
      let order = _.get(params, 'sort.order');

      perPage = perPage > 1 ? perPage : 10;
      field = field === null ? 'createdAt' : field;
      order = order === null ? 'DESC' : order;
      if (resource === RESOURCES.MERCHANDISE_FOR_SALE.name) {
        filter = { ...filter, sellState: SELLSTATE.SELLING };
      }
      let data = {
        ...filter,
        page: page,
        limit: perPage,
        orderBy: field,
        order
      };
      return axiosInstance.post('/functions/getListMerchByUser', data).then(response => {
        const data = _.get(response, 'data.result.data', []);
        const total = _.get(response, 'data.result.total', 0);
        data.forEach(item => {
          item.id = item.objectId;
        });
        return Promise.resolve({
          data,
          total
        });
      });
    }
    case CREATE: {
      const data = _.pick(params.data, [
        'name',
        'sellState',
        'categoryId',
        'manufacturerId',
        'otherManufacturer',
        'modelNumber',
        'statusOfAdjustment',
        'expirationOfAdjustment',
        'description',
        'usaged',
        'delivery',
        'serialNumber',
        'assetNumber',
        'price',
        'holdingCategory',
        'officer',
        'user',
        'dateOfUse',
        'storageAndAvailableArea',
        'targetOfCalibration',
        'calibrationCycle',
        'calibrationAppliedDate',
        'calibrationDate',
        'calibrationResult',
        'fixedAssets',
        'purchasedDate',
        'originalPrice',
        'serviceLife',
        'realMarketValue',
        'storageLocation',
        'year'
      ]);
      const imagesToUpload = [];
      const imagesToDelete = [];
      const keepImages = [];
      if (_.get(params, 'data.images')) {
        _.get(params, 'data.images').map(item => {
          if (_.has(item, 'objectId')) {
            if (_.get(item, 'deleted')) {
              imagesToDelete.push(_.get(item, 'objectId'));
            } else {
              keepImages.push(_.get(item, 'objectId'));
            }
          } else {
            imagesToUpload.push(_.get(item, 'src'));
          }
        });
      }
      const promise = [];
      imagesToUpload.forEach(item => {
        const payload = new FormData();
        payload.append('type', FILE_TYPE.MERCHANDISE);
        payload.append('file', item);
        const uploadedImage = Uploader.uploadImage(payload);
        promise.push(uploadedImage);
      });
      const imagesUploaded = await Promise.all(promise);
      const uploadedImages = imagesUploaded.map(item => _.get(item, 'data.objectId', ''));
      data.images = keepImages.concat(uploadedImages);
      data.deletedImages = imagesToDelete;
      return axiosInstance.post('/functions/createMerch', data).then(response => {
        const data = _.get(response, 'data.result', {});
        _.set(data, 'id', data.objectId);
        return Promise.resolve({ data });
      });
    }

    case UPDATE: {
      const data = _.pick(params.data, [
        'objectId',
        'name',
        'sellState',
        'categoryId',
        'manufacturerId',
        'otherManufacturer',
        'modelNumber',
        'statusOfAdjustment',
        'expirationOfAdjustment',
        'description',
        'usaged',
        'delivery',
        'serialNumber',
        'assetNumber',
        'price',
        'holdingCategory',
        'officer',
        'dateOfUse',
        'storageAndAvailableArea',
        'targetOfCalibration',
        'calibrationCycle',
        'calibrationAppliedDate',
        'calibrationDate',
        'calibrationResult',
        'fixedAssets',
        'purchasedDate',
        'originalPrice',
        'serviceLife',
        'realMarketValue',
        'storageLocation',
        'year'
      ]);
      const imagesToUpload = [];
      const imagesToDelete = [];
      const keepImages = [];
      if (_.get(params, 'data.images')) {
        _.get(params, 'data.images').forEach(item => {
          if (_.has(item, 'objectId')) {
            if (_.get(item, 'deleted')) {
              imagesToDelete.push(_.get(item, 'objectId'));
            } else {
              keepImages.push(_.get(item, 'objectId'));
            }
          } else {
            imagesToUpload.push(_.get(item, 'src'));
          }
        });
      }
      const promise = [];
      imagesToUpload.forEach(item => {
        const payload = new FormData();
        payload.append('type', FILE_TYPE.MERCHANDISE);
        payload.append('file', item);
        const uploadedImage = Uploader.uploadImage(payload);
        promise.push(uploadedImage);
      });
      const imagesUploaded = await Promise.all(promise);
      const uploadedImages = imagesUploaded.map(item => _.get(item, 'data.objectId', ''));
      data.merchandiseId = data.objectId;
      data.images = keepImages.concat(uploadedImages);
      data.deletedImages = imagesToDelete;
      return axiosInstance.post('/functions/updateMerch', data).then(response => {
        const data = _.get(response, 'data', {});
        _.set(data, 'id', data.objectId);
        return Promise.resolve({ data });
      });
    }

    case DELETE: {
      return axiosInstance.post('/functions/deleteMerch', { merchandiseId: params.id }).then(response => {
        const data = _.get(response, 'data', {});
        _.set(data, 'id', data.objectId);
        return Promise.resolve({ data });
      });
    }

    case UPDATE_MANY: {
      return axiosInstance.post('/functions/updateSellStateForMerchs', params).then(response => {
        const errorIds = _.get(response, 'data.result.errorIds', []);
        if (_.isEmpty(errorIds)) {
          return Promise.resolve({ data: [] });
        } else return Promise.reject({ payload: errorIds });
      });
    }

    case DELETE_MANY: {
      return axiosInstance.post('functions/deleteMerchs', params).then(response => {
        const errorIds = _.get(response, 'data.result.errorIds', []);
        if (!_.isEmpty(errorIds)) {
          return Promise.reject({ payload: errorIds });
        }
        return Promise.resolve({ data: [] });
      });
    }

    case SET_IMPORT_FILE: {
      const formData = new FormData();
      formData.append('type', '.csv');
      formData.append('file', params);

      return getAxiosInstant()
        .post('/upload/importMerchs', formData)
        .then(response => {
          return Promise.resolve({ data: response.data });
        })
        .catch(error => {
          return Promise.reject(error);
        });
    }

    case CUSTOM_ACTION.GET_EXPORT_LIST: {
      let filter = { ...params.filter };
      let data = {
        ...filter,
        page: 0,
        limit: 1000
      };
      return axiosInstance.post('/functions/getListMerchByUser', data).then(response => {
        const data = _.get(response, 'data.result.data', []);
        const total = _.get(response, 'data.result.total', 0);
        data.forEach(item => {
          item.id = item.objectId;
        });
        return Promise.resolve({
          data,
          total
        });
      });
    }

    case GET_PREFECTURES_REQUEST: {
      return axiosInstance
        .post('/functions/getResources')
        .then(response => {
          return Promise.resolve({ data: response.data });
        })
        .catch(error => {
          return Promise.reject(error);
        });
    }

    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }
};
