import React from 'react';
import _ from 'lodash';
import { ORDERSTATE } from 'helpers/Const';
import SellingIcon from 'assets/icon-status-onsale.svg';
import CompletedIcon from 'assets/icon-status-complete.svg';
import ConfirmIcon from 'assets/confirm-icon.svg';
import PaymentIcon from 'assets/payment-icon.svg';
import DeliveringIcon from 'assets/icon-delivering.svg';
import RejectIcon from 'assets/reject-icon.svg';
import ReviewIcon from 'assets/review-icon.svg';
import PropTypes from 'prop-types';
import './style.scss';

const TransactionStatus = ({ source, record = {} }) => {
  const status = _.get(record, `${source}`);
  let subClassName = '';
  let icon = '';
  if (!status) {
    return null;
  }
  switch (status) {
    case ORDERSTATE.ORDER_SELLING:
      subClassName = 'selling';
      icon = SellingIcon;
      break;
    case ORDERSTATE.ORDER_CONFIRMING:
      subClassName = 'confirm';
      icon = ConfirmIcon;
      break;
    case ORDERSTATE.ORDER_PAYCONFIRM:
    case ORDERSTATE.ORDER_PAYING:
      subClassName = 'payment';
      icon = PaymentIcon;
      break;
    case ORDERSTATE.ORDER_DELIPREPARE:
      subClassName = 'deliprepare';
      break;
    case ORDERSTATE.ORDER_DELIVERING:
      subClassName = 'delivering';
      icon = DeliveringIcon;
      break;
    case ORDERSTATE.ORDER_RECEIVED:
    case ORDERSTATE.ORDER_REVIEWED:
      subClassName = 'reviewed';
      icon = ReviewIcon;
      break;
    case ORDERSTATE.ORDER_COMPLETED:
      subClassName = 'completed';
      icon = CompletedIcon;
      break;
    case ORDERSTATE.ORDER_REJECTED:
      subClassName = 'rejected';
      icon = RejectIcon;
      break;

    default:
      break;
  }
  return (
    <div className={`list-status-btn ${subClassName}-state`}>
      {icon && <img alt="status" className="status-icon" src={icon} />}
      <div className="status-text">{status}</div>
    </div>
  );
};

TransactionStatus.propTypes = {
  source: PropTypes.string,
  record: PropTypes.object
};
export default TransactionStatus;
