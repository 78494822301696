import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { ListController, getListControllerProps } from 'ra-core';

import { Title, Pagination, BulkActionsToolbar, ListToolbar } from 'react-admin';
export const styles = () => ({
  root: {
    display: 'flex'
  },
  card: {
    position: 'relative',
    flex: '1 1 auto'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignSelf: 'flex-start'
  },
  noResults: { padding: 20 },
  toolbar: {
    display: 'unset',
    padding: 0
  }
});

const sanitizeRestProps = ({
  actions,
  basePath,
  bulkActions,
  changeListParams,
  children,
  classes,
  className,
  crudGetList,
  currentSort,
  data,
  defaultTitle,
  displayedFilters,
  exporter,
  filter,
  filterDefaultValues,
  filters,
  filterValues,
  hasCreate,
  hasEdit,
  hasList,
  hasShow,
  hideFilter,
  history,
  ids,
  isLoading,
  loadedOnce,
  locale,
  location,
  match,
  onSelect,
  onToggleItem,
  onUnselectItems,
  options,
  page,
  pagination,
  params,
  permissions,
  perPage,
  push,
  query,
  refresh,
  resource,
  selectedIds,
  setFilters,
  setPage,
  setPerPage,
  setSelectedIds,
  setSort,
  showFilter,
  sort,
  title,
  toggleItem,
  total,
  translate,
  version,
  ...rest
}) => rest;

export const ListView = ({
  // component props
  actions,
  aside,
  filters,
  bulkActions, // deprecated
  bulkActionButtons,
  pagination,
  // overridable by user
  children,
  className,
  classes,
  exporter,
  title,
  ...rest
}) => {
  const { defaultTitle, version } = rest;
  const controllerProps = getListControllerProps(rest);
  return (
    <div className={classnames('list-page', classes.root, className)} {...sanitizeRestProps(rest)}>
      <Title title={title} defaultTitle={defaultTitle} />
      <Card className={classes.card}>
        {(filters || actions) && (
          <ListToolbar
            classes={{ toolbar: classes.toolbar }}
            filters={filters}
            {...controllerProps}
            actions={actions}
            bulkActions={bulkActions}
            exporter={exporter}
          />
        )}
        <div key={version}>
          {children &&
            cloneElement(Children.only(children), {
              ...controllerProps,
              hasBulkActions: bulkActions !== false && bulkActionButtons !== false
            })}
          {pagination && cloneElement(pagination, controllerProps)}
        </div>
      </Card>
      {aside && cloneElement(aside, controllerProps)}
    </div>
  );
};

ListView.propTypes = {
  actions: PropTypes.element,
  aside: PropTypes.node,
  basePath: PropTypes.string,
  bulkActions: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  bulkActionButtons: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  children: PropTypes.element,
  className: PropTypes.string,
  classes: PropTypes.object,
  currentSort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.string
  }),
  data: PropTypes.object,
  defaultTitle: PropTypes.string,
  displayedFilters: PropTypes.object,
  exporter: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  filterDefaultValues: PropTypes.object,
  filters: PropTypes.element,
  filterValues: PropTypes.object,
  hasCreate: PropTypes.bool,
  hideFilter: PropTypes.func,
  ids: PropTypes.array,
  isLoading: PropTypes.bool,
  onSelect: PropTypes.func,
  onToggleItem: PropTypes.func,
  onUnselectItems: PropTypes.func,
  page: PropTypes.number,
  pagination: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  perPage: PropTypes.number,
  refresh: PropTypes.func,
  resource: PropTypes.string,
  selectedIds: PropTypes.array,
  setFilters: PropTypes.func,
  setPage: PropTypes.func,
  setPerPage: PropTypes.func,
  setSort: PropTypes.func,
  showFilter: PropTypes.func,
  title: PropTypes.any,
  total: PropTypes.number,
  translate: PropTypes.func,
  version: PropTypes.number
};

ListView.defaultProps = {
  classes: {},
  bulkActionButtons: <BulkActionsToolbar />,
  pagination: <Pagination />
};
export const List = props => {
  return <ListController {...props}>{controllerProps => <ListView {...props} {...controllerProps} />}</ListController>;
};

List.propTypes = {
  // the props you can change
  actions: PropTypes.element,
  aside: PropTypes.node,
  bulkActions: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  bulkActionButtons: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  filter: PropTypes.object,
  filterDefaultValues: PropTypes.object,
  filters: PropTypes.element,
  pagination: PropTypes.element,
  perPage: PropTypes.number.isRequired,
  sort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.string
  }),
  title: PropTypes.any,
  // the props managed by react-admin
  authProvider: PropTypes.func,
  hasCreate: PropTypes.bool.isRequired,
  hasEdit: PropTypes.bool.isRequired,
  hasList: PropTypes.bool.isRequired,
  hasShow: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  path: PropTypes.string,
  resource: PropTypes.string.isRequired
};

List.defaultProps = {
  filter: {},
  perPage: 50
};

export default withStyles(styles)(List);
