import React from 'react';
import { DateField } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import STYLE from 'styles/_constants.scss';

const styles = {
  fieldNoneBorder: {
    marginTop: '8px',
    fontWeight: 'bold',
    fontSize: '16px'
  },
  title: {
    fontSize: '14px',
    color: '#3c3c3c',
    fontWeight: '500',
    fontFamily: `${STYLE.fontFamily}`
  }
};

export const DateFieldCustom = withStyles(styles)(({ classes, label, source = '', ...props }) => (
  <div>
    <label className={classes.title}>{label}</label>
    <DateField source={source} className={classes.fieldNoneBorder} {...props} fullWidth />
  </div>
));
