import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { ReferenceInput } from 'react-admin';
import CustomFilter from 'components/CustomFilters';
import SearchInput from '../../../../components/SearchInput';
import { LABEL } from 'helpers/Const';
import SelectField from 'components/CustomFields/SelectField';

class MerchandiseFilter extends React.Component {
  render() {
    const { classes, ...rest } = this.props;

    return (
      <CustomFilter formClassName="merchandise-search-form" {...rest}>
        <SearchInput
          containerclassname="container_456"
          label=""
          placeholder={LABEL.SEARCH_FILTER_PLACEHOLDER}
          title={LABEL.SEARCH_FILTER}
          className={classes.filterInput}
          source="search"
          alwaysOn
        />

        <ReferenceInput
          containerclassname="container_208"
          label={LABEL.SELECT_FILTER}
          source="category"
          reference="category"
          alwaysOn
          allowEmpty
          classes={{
            container: classes.container,
            groupLabel: classes.groupLabel,
            bootstrapFormLabel: classes.bootstrapFormLabel
          }}
        >
          <SelectField
            InputProps={{
              disableUnderline: true,
              classes: {
                input: classes.input
              }
            }}
          />
        </ReferenceInput>
      </CustomFilter>
    );
  }
}

MerchandiseFilter.propTypes = {
  classes: PropTypes.object,
  getSubCategoriesRequest: PropTypes.func,
  subCategories: PropTypes.array
};

const styles = {
  filterInput: {
    width: '100%',
    height: '48px',
    border: 'solid 1px #dddddd',
    backgroundColor: '#ffffff',
    borderRadius: '4px',
    margin: 0
  },
  input: {
    padding: 0,
    lineHeight: '24px',
    width: '158px'
  },
  customSelect: {
    marginTop: '0px !important',
    padding: '12px 16px'
  },
  bootstrapFormLabel: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 500
  },
  container: {
    marginTop: 0,
    marginBottom: 0
  }
};

export default withStyles(styles)(MerchandiseFilter);
