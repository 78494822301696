import React from 'react';
import PropTypes from 'prop-types';

const LogIdField = ({ record = {}, className }) => {
  return <span className={className}>{`#${record.logId}`}</span>;
};
LogIdField.propTypes = {
  record: PropTypes.object,
  className: PropTypes.string
};

export default LogIdField;
