import moment from 'moment';
import _ from 'lodash';

export function validateUrl(url) {
  if (typeof url !== 'string') {
    return false;
  }
  const pattern = new RegExp(
    '^(https?:\\/\\/)' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return pattern.test(url);
}

export function validateEmail(email) {
  if (typeof email !== 'string') {
    return false;
  }
  var pattern = /[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/gim;
  return pattern.test(email);
}

export function validateDoubledigit(str) {
  const pattern = new RegExp('^[0-9]{1,2}[:.,-]?$');
  return pattern.test(str);
}

export function validateNumber(str) {
  return !isNaN(Number(str));
}

export function formatDate(date, format) {
  return moment(date).format(format);
}

export function formatPrice(input) {
  if (!input) return '';
  return String(parseInt(input))
    .replace(/,/g, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function normalizeAmount(val) {
  return val.replace(/,/g, '');
}

export function checkIfDeleteAllImages(arrImg) {
  const imgHasObjectId = _.filter(arrImg, img => _.has(img, 'objectId'));
  const imgHasDeleted = _.filter(imgHasObjectId, img => _.has(img, 'deleted'));
  return imgHasObjectId.length === imgHasDeleted.length && imgHasObjectId.length === arrImg.length;
}

export function checkIsMobileDevice() {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
    window.innerWidth < 768
  ) {
    return true;
  }
  return false;
}

export const checkIsChrome = () => /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
