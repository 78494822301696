import React, { Component } from 'react';
import CommentList from './CommentList';
import PropTypes from 'prop-types';
import Comment from '../CommentField/Comment';
import _ from 'lodash';
import { withStyles } from '@material-ui/core';
import { CSSTransition } from 'react-transition-group';

const style = {
  commentField: {
    borderTop: 'solid 1px #dddddd',
    margin: '8px 48px 0 48px',
    fontFamily: 'Noto Sans JP, sans-serif'
  },
  commentText: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 500,
    color: '#777777',
    marginTop: '16px'
  },
  comments: {
    marginLeft: '24px'
  },
  viewOption: {
    width: 'max-content',
    color: '#00A3DF',
    cursor: 'pointer',
    marginTop: '12px',
    fontSize: '14px',
    lineHeight: '20px'
  },
  iconArrowUp: {
    fontSize: '11px',
    fontFamily: 'Noto Sans JP, sans-serif',
    lineHeight: '14px',
    backgroundRepeat: 'no-repeat',
    padding: '11px 0px 8px 22px',
    backgroundPosition: '0px',
    backgroundImage: 'url(/img/up.svg)'
  },
  iconArrowDown: {
    fontSize: '11px',
    fontFamily: 'Noto Sans JP, sans-serif',
    lineHeight: '14px',
    backgroundRepeat: 'no-repeat',
    padding: '11px 0px 8px 22px',
    backgroundPosition: '0px',
    backgroundImage: 'url(/img/down.svg)'
  },
  showAll: {
    opacity: '0'
  },
  showAllActive: {
    opacity: '1',
    transition: 'all 100ms linear'
  },
  showOne: {
    opacity: '1'
  },
  showOneActive: {
    opacity: '0',
    transition: 'all 100ms linear'
  }
};

class CommentField extends Component {
  render() {
    const { classes, comments, isViewAll } = this.props;
    const comment = Object.assign({}, ...comments.slice(0, 1));
    const otherComments = comments.slice(1);
    return (
      <div className={classes.commentField}>
        <div className={classes.commentText}>お問い合わせ、またはメッセージ</div>
        <div className={classes.comments}>
          <Comment
            key={_.get(comment, 'objectId')}
            author={_.get(comment, 'owner.representative')}
            updatedDate={_.get(comment, 'updatedAt')}
            content={_.get(comment, 'content')}
          />
          <CSSTransition
            in={isViewAll}
            mountOnEnter
            unmountOnExit
            timeout={200}
            classNames={{
              enter: classes['showAll'],
              enterActive: classes['showAllActive'],
              exit: classes['showOne'],
              exitActive: classes['showOneActive']
            }}
          >
            <div>
              <CommentList data={otherComments} />
              <div onClick={this.props.toggleViewOption} className={classes.viewOption}>
                <span className={classes.iconArrowUp}></span>
                閉じる
              </div>
            </div>
          </CSSTransition>
          {!isViewAll && (
            <div onClick={this.props.toggleViewOption} className={classes.viewOption}>
              <span className={classes.iconArrowDown}></span>
              もっと見る
            </div>
          )}
        </div>
      </div>
    );
  }
}

CommentField.propTypes = {
  classes: PropTypes.object,
  isViewAll: PropTypes.bool,
  comments: PropTypes.array,
  toggleViewOption: PropTypes.func
};

export default withStyles(style)(CommentField);
