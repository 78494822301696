import React from 'react';
import _ from 'lodash';
import moment from 'moment';
const UnixTimeField = ({ source, record = {}, maxWidth = 245, className = '' }) => {
  let value = _.get(record, `${source}`);
  if (value) {
    value = source === 'expirationOfAdjustment' ? moment.unix(value).format('YYYY/MM') : value;
    return (
      <div style={{ maxWidth: `${maxWidth}px` }} className={className}>
        {value}
      </div>
    );
  } else return null;
};
export default UnixTimeField;
