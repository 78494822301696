import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormControl, FormHelperText, Select, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import { addField } from 'react-admin';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import STYLE from 'styles/_constants.scss';
const styles = theme => ({
  container: {
    marginTop: 24,
    marginBottom: 8,
    fontFamily: `${STYLE.fontFamily}`
  },
  groupLabel: {
    display: 'flex',
    marginBottom: 8
  },
  bootstrapFormLabel: {
    fontSize: '14px',
    lineHeight: '20px',
    color: '#3c3c3c'
  },
  bootstrapInput: {
    fontFamily: `${STYLE.fontFamily}`,
    borderRadius: 4,
    border: '1px solid #dddddd',
    fontSize: 16,
    lineHeight: '24px',
    padding: '12px 16px',
    width: 'calc(100% - 24px)',
    transition: theme.transitions.create(['border-color']),
    '&:focus': {
      borderColor: '#6bc5e8'
    }
  },
  bootstrapRoot: {
    'label + &': {
      marginTop: theme.spacing.unit * 3
    },
    lineHeight: '24px',
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: '1px solid #202a6a',
    fontSize: 16,
    padding: '11px 15px',
    width: 'calc(100% - 24px)',
    transition: theme.transitions.create('border-color'),
    fontFamily: `${STYLE.fontFamily}`,
    '&:focus': {
      borderColor: '#6bc5e8'
    }
  },
  bootstrapInputError: {
    borderColor: '#9b1d20'
  },

  bootstrapFormMessage: {
    color: '#f5bb00',
    fontSize: 14
  },
  bootstrapFormSuggest: {
    fontSize: '16px',
    color: '#00a3df',
    marginLeft: 'auto',
    cursor: 'pointer',
    lineHeight: '24px'
  },
  require: {
    color: '#9b1d20'
  },
  select: {
    padding: 0,
    fontSize: 16,
    lineHeight: '24px',
    '&:focus': {
      backgroundColor: 'unset'
    }
  },
  menuItemPlaceholder: {
    color: '#dddddd'
  },
  menuItem: {
    fontFamily: `${STYLE.fontFamily}`,
    backgroundColor: '#FAFAFA !important'
  },
  disable: {
    border: '1px solid #dddddd',
    color: '#dddddd'
  },
  errorIcon: {
    width: '16px',
    height: '16px',
    position: 'relative',
    left: '-4px',
    top: '3px'
  },
  errorMessage: {
    marginLeft: '4px'
  }
});

class SelectField extends React.Component {
  state = {
    choices: []
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const choices = _.get(nextProps, 'choices');
    if (!_.isEqual(choices, prevState.choices)) {
      return {
        choices
      };
    }
    return null;
  }

  handleChange = e => {
    const value = e.target.value;
    this.props.input.onChange(value);
  };

  render() {
    const {
      classes,
      label,
      required,
      suggest,
      handleClick,
      message,
      className,
      customForm = false,
      placeholder,
      disabled
    } = this.props;
    const { choices } = this.state;
    let touched = _.get(this.props, 'meta.touched');
    const error = customForm ? _.get(this.props, 'meta.error') : _.get(this.props, 'meta.error[0]');
    return (
      <FormControl fullWidth className={`${classes.container} ${className}`}>
        <div className={classes.groupLabel}>
          <Fragment>
            <div className={classes.bootstrapFormLabel}>
              {label}
              {required && <span className={classes.require}> *</span>}
            </div>
            {message && <div className={classes.bootstrapFormMessage}>{message}</div>}
          </Fragment>
          {suggest && (
            <div onClick={handleClick} className={classes.bootstrapFormSuggest}>
              {suggest}
            </div>
          )}
        </div>
        <Select
          disableUnderline
          classes={{
            root: `${classes.bootstrapRoot} ${touched && error && classes.bootstrapInputError} ${disabled &&
              classes.disable}`,
            select: classes.select
          }}
          name={label}
          displayEmpty
          fullWidth
          disabled={disabled}
          {...this.props.input}
        >
          <MenuItem classes={{ selected: `${classes.menuItem} ${disabled && classes.disable}` }} value="">
            <span className={classes.menuItemPlaceholder}>{placeholder || label}</span>
          </MenuItem>
          {choices.map((choice, index) => (
            <MenuItem key={index} value={choice.id || choice.value}>
              {choice.name || choice.text}
            </MenuItem>
          ))}
        </Select>
        {!!(touched && error) && (
          <FormHelperText id="helper-text" error>
            <span className={classes.errorMessage}>
              <ErrorIcon className={classes.errorIcon} />
              {touched && error}
            </span>
          </FormHelperText>
        )}
      </FormControl>
    );
  }
}

SelectField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  classes: PropTypes.any,
  type: PropTypes.any,
  label: PropTypes.any,
  placeholder: PropTypes.any,
  required: PropTypes.bool,
  sellState: PropTypes.string,
  suggest: PropTypes.string,
  message: PropTypes.string,
  customForm: PropTypes.bool,
  handleClick: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool
};

const mapStateToProps = state => ({ sellState: _.get(state, 'form.record-form.values.sellState') });

export default addField(compose(connect(mapStateToProps), withStyles(styles))(SelectField));
