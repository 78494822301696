import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';

import './style.scss';
/* eslint-disable max-len */
class TermAndCondition extends React.Component {
  render() {
    return (
      <Card id="term-of-use-page-container">
        <Title title="Terms and Conditions" />
        <CardContent>
          <div id="term-of-use-page-content">
            <h3>利用規約 </h3>
            <div>
              この利用規約（以下、「本規約」といいます。）は、Ekuipp株式会社（以下、「当社」といいます。）がこのウェブサイト上で提供するサービス（以下、「本サービス」といいます。）の利用条件を定めるものです。登録ユーザーの皆さま（以下、「ユーザー」といいます。）には、本規約に従って、本サービスをご利用いただきます。
              <br />
              ユーザーは、本サービスを利用する前に、本規約をよくお読みください。
              <br />
              <h4>第1条（適用)</h4>
              <ol>
                <li>本規約は、ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。</li>
                <li>
                  ユーザーは、本規約の定めに従って本サービスを利用しなければならず、本規約に同意しない限り、本サービスを利用できません。
                </li>
                <li>
                  ユーザーが本サービスを利用するにあたり、本規約が適用されることへの同意手続きを行った時点で、ユーザーと当社との間で、本規約の諸規定に従った利用契約（以下「本利用契約」といいます。）が成立します。
                </li>
                <li>
                  ユーザーは、本サービスの利用に関し、本規約の他、本規約の下位規約、ルール、ガイドライン、プライバシーポリシー及びヘルプ等を遵守することに同意して本サービスを利用するものとし、本サービスを利用した場合は、本規約の他、本規約の下位規約、ルール、ガイドライン、プライバシーポリシー及びヘルプ等を遵守することに同意したものとみなします。
                </li>
              </ol>
              <h4>第2条（利用登録）</h4>
              <ol>
                <li>
                  登録希望者が当社の定める方法によって利用登録を申請し、当社がこれを承認することによって、利用登録が完了するものとします。
                  <br />
                  以下、利用登録時に登録された情報を、ユーザー情報といいます。
                </li>
                <li>
                  ユーザー情報の登録にあたり、登録希望者は、当社に対し、真実かつ正確な情報を提供しなければなりません。ユーザー情報に誤りがあった場合又は変更が生じた場合、ユーザーは、自己の責任において、速やかにユーザー情報を修正又は変更するものとします。
                </li>
                <li>ユーザーは、一個人又は一法人あたり一登録に限るとし、重複登録はできません。</li>
                <li>
                  当社は、利用登録の申請者に以下の事由があると判断した場合、利用登録の申請を承認しないことがあります。この場合において、当該不承認の理由について、当社はユーザーに対して一切の開示義務を負わず、当該不承認について何らの責任を負わないものとします。
                  <ol>
                    <li>本条第２項又は第５項のユーザー資格を満たしていない場合</li>
                    <li>利用登録の申請に際して虚偽の事項を届け出た場合</li>
                    <li>本規約に違反したことがある者からの申請である場合</li>
                    <li>
                      暴力団員、暴力団準構成員、総会屋、社会運動等標榜ゴロ、特殊知能暴力団その他これらに準じる反社会的勢力（以下「反社会的勢力等」といいます）であると判明した場合、または、反社会的勢力等が経営に実質的に関与していることが判明した場合等反社会的勢力と何らかの関係を有していると判明した場合
                    </li>
                    <li>その他、当社が利用登録を相当でないと判断した場合</li>
                  </ol>
                </li>
                <li>
                  本サービスについて、満１８歳未満の方は、ユーザーになることができません。
                  <br />
                  また、１８歳以上であっても未成年者である場合、ユーザーは本サービスの一切の利用に関して、親権者等法定代理人の包括的な同意を得なければなりません。該当未成年者は、親権者等法定代理人の同意の有無について、当社から親権者等法定代理人に対し確認をする場合があることを予め同意します。
                </li>
              </ol>
              <h4>第3条（ユーザーIDおよびパスワードの管理）</h4>
              <ol>
                <li>ユーザーは、自己の責任において、本サービスのユーザーIDおよびパスワードを管理するものとします。</li>
                <li>
                  ユーザーは、いかなる場合にも、ユーザーIDおよびパスワードを第三者に譲渡または貸与することはできません。当社は、ユーザーIDとパスワードの組み合わせが登録情報と一致してログインされた場合には、そのユーザーIDを登録しているユーザー自身による利用とみなします。
                </li>
                <li>
                  ユーザーID及びパスワードが盗用され又は第三者に使用されていることが判明した場合、ユーザーは、直ちにその旨を当社に通知するとともに、当社からの指示に従うものとします。
                </li>
                <li>
                  ユーザーID及びパスワードの管理不十分又は第三者の使用等によるユーザーの損害の責任は、ユーザーが負うものとし、当社は一切の責任を負いません。
                </li>
              </ol>
              <h4>第４条（ユーザーに関する情報の取扱い）</h4>
              当社は、ユーザー情報その他本サービスの利用に関してユーザーから収集する情報を、別途当社が定めるプライバシーポリシー（URL:
              https://www.ekuippcorp.com/）に従い、適切に取り扱います。
              <br />
              <h4>第５条（本サービスの内容及び当社の役割）</h4>
              <ol>
                <li>
                  本サービスは、ユーザーに対し、他のユーザーとの間の商品の売買、サービスの提供その他の取引（以下「個別取引」といいます。）の機会を提供することを内容とするものとします。
                </li>
                <li>
                  当社は、個別取引の当事者とはならず、本規約に定める場合を除き、個別取引に関する一切の事項に関与しません。
                </li>
                ユーザーは、個別取引における取引上の義務の遂行を、すべて自らの責任において行うものとします
              </ol>
              <h4>第６条（商品の出品）</h4>
              <ol>
                <li>
                  ユーザーは、当社所定の手続きに従い必要な情報を登録することにより商品の出品を行うものとし、併せて見積書を当社所定の手続きに従いアップロードするものとします。
                </li>
                <li>
                  当社は、ユーザーの商品の出品にあたり、基準を設け、審査を行い、基準に合致しない商品の出品をお断りすることができるものとします。
                </li>
                <li>
                  出品者は、商品の出品にあたり、古物営業法、特定商取引に関する法律、不当景品類及び不当表示防止法、不正競争防止法、商標法、著作権法その他の法令、行政機関の定める準則、ガイドラインを遵守しなければなりません。
                </li>
                <li>
                  出品者は、出品する際に、真に売却する意思のない出品、その商品情報だけでは正しく商品を理解できない又は混乱する可能性のある出品を行ってはなりません。また、出品者は、出品する商品と無関係の画像等を当該出品情報として掲載することはできません。
                </li>
                <li>
                  当社は、本条に定める審査の結果、基準に合致しない商品であると判断し出品を拒否することができます。この場合において、当社はユーザーに対して何らの責めを負わないものとします。
                </li>
              </ol>
              <h4>第７条（商品の注文）</h4>
              <ol>
                <li>
                  ユーザーは、商品を出品したユーザー（以下「出品者」といいます。）に対して、当社の定める手続きにより購入する意思をもって、商品の注文を行うことができるものとします。
                </li>
                <li>
                  ユーザーは、出品者に対し、当社所定の手続きに従い、前項の注文を行うか否かの判断のために必要な質問を行い、追加の情報を求めることができるものとします。
                </li>
              </ol>
              <h4>第８条（出品者の諾否及び売買契約の成立）</h4>
              <ol>
                <li>
                  出品者は、前条に基づく商品の注文を受けた場合、当該注文を受けた日から７日以内に、当社所定の手続きに従い、注文手続きをしたユーザー（以下「注文者」という。）に対して、当該注文を受諾するか否かの回答をするものとします。出品者は、海外への輸出を要する場合、出品者は、外国為替及び外国貿易法並びにその下位法令、関税法、輸出入規制法等の定める輸出規制に抵触する輸出を行ってはなりません。
                </li>
                <li>
                  出品者は、注文者からの注文に応じるか否かの判断のため、注文者から、注文者の名前、住所、属性等の情報を取得することができるものとします。この場合において、出品者は、注文者に対して、追加の情報の開示を求めることができるものとします。
                </li>
                <li>
                  出品者が注文者からの注文に対して、当社所定の手続きに従い、当該注文を受諾する旨の回答をした時点で、当該商品について、出品者と注文者との間に売買契約が成立するものとします。
                </li>
                <li>
                  上記輸出規制に基づき、輸出に官庁の事前許可を要する場合、出品者は、売買契約成立後、直ちに官庁に輸出許可に関する申請手続を行うものとします。輸出許可申請が不許可となった場合、当該売買契約は白紙解約されるものとし、一切の損害賠償を求めることはできないものとします。
                </li>
                <li>
                  出品者が注文者からの注文を受諾しない場合、当社所定の手続きに従い、出品者から注文者に対して、その旨の通知をするものとします。
                </li>
                <br />
                本条第１項に定める期限の経過後、出品者から注文者に対して、何らの諾否の通知が行われない場合、出品者は、注文者の注文を受諾しなかったものとみなします。
                <li>
                  出品者及び当社は、注文者に対し、注文が受諾されなかった場合でも、何ら当該理由の開示義務を負うものではなく何らの責めを負うものではありません。
                </li>
                <li>
                  出品者と注文者との間に売買契約が成立した場合、当該売買契約の履行を目的とし、当該出品者及び当該注文者に相互の情報が開示されるものとします。
                </li>
                <li>
                  出品者及び注文者は、本サービスにより開示された情報に基づいて、出品者及び注文者との間において出品された商品に関して売買契約を成立させることはできないものとします。
                </li>
              </ol>
              <h4>第９条（売買代金の支払及び支払方法）</h4>
              <ol>
                <li>
                  前条に基づく出品者と注文者との間の売買契約が成立後、注文者は、当社の定める方法により、商品代金を支払うものとします。
                </li>
                <li>
                  出品者は、当社に対して、前項に基づき支払われる商品代金（決済事業者または収納代行業者から支払われる商品代金に相当する金員を含みます。）を代理受領する権限を付与するものとします。
                </li>
              </ol>
              <h4>第１０条（出品者の本サービス利用料の負担）</h4>
              <ol>
                <li>
                  本サービスの利用により、出品者及び注文者間に売買契約が成立した場合、出品者は、商品売買代金（消費税を除く）の１５％及びこれに要する消費税を、本サービス利用料として、当社に支払うものとします。
                </li>
                <li>
                  出品者は、前項に定める本サービス利用料の対価の支払について、当社が代理受領権限に基づき注文者から入金を受けた商品代金から、前項のサービス利用料の対価を控除する方法により徴収されることを承諾します。
                </li>
              </ol>
              <h4>第１１条（商品の出荷）</h4>
              <ol>
                <li>
                  出品者は、第９条の定める注文者の商品代金の決済が完了した後、注文者に対して、当社所定の手続きにより通知される場所及び日時までに、商品を出荷するものとします。
                </li>
                <li>
                  出品者から注文者に対する商品の送料は、出品者が負担する場合には、商品代金に含むものとし、注文者が負担する場合には、商品の発送を着払いで行うものとします。
                </li>
                <li>
                  前項の場合において、商品の配送業者について、出品者は事前に当社に通知し、当社の確認を予め得るものとします。
                </li>
                <li>出品者は、出荷手続完了後、注文者及び当社に対して、出荷済みである旨の通知をするものとします。</li>
                <li>
                  商品の出荷後、注文者による「お届け先の変更」、「配達日の日時指定」、「指定時間をすぎても商品が届かない」等の配送に関するお問い合わせは、注文者は当該配送会社宛てに連絡するものとします。
                </li>
              </ol>
              <h4>第１２条（商品の検収）</h4>
              <ol>
                <li>
                  注文者は、注文した商品が到着後、２週間以内に、商品の検収業務を実施し、合格基準に適合するか否かを確認し、合格基準に達する場合には、当社が指定する書式による検収合格通知を、当社所定の手続きにより、当社及び出品者に対して通知するものとします。注文者が注文した商品の到着後、２週間以内に検収業務をしない場合には、当該商品について合格したものとみなしたものとする。
                </li>
                <li>
                  前項における合格基準とは、商品が作動することにより当該商品に求められる通常の品質を保持していることを指すものとします。
                </li>
                <li>
                  本条第１項に基づく注文者の商品の検収の結果、不合格の場合には、当社所定の手続きにより不合格の理由を通知後、当社が指定する手続きで、商品を出品者に返送するものとします。この場合の返送費用は、出品者の負担とします。
                </li>
                <li>
                  商品に瑕疵がある場合、商品説明と実際の商品が明らかに異なる場合、梱包の不備により配送時に商品が破損した等の場合は、出品者が責任を負うものとします。この場合において、注文者との協議に基づき、出品者はその費用と責任において、返金、商品の引取、修理又は交換その他の措置に応じるものとします。
                </li>
              </ol>
              <h4>第１３条（売買代金の送金）</h4>
              <ol>
                <li>
                  当社は、出品者に対し、注文者から検収合格通知を受領した場合、当該検収合格通知の受領日から起算して９０日以内に、当該商品について注文者から代理受領権限に基づき当社に支払われた金額から本サービス料を控除した残額を、予め登録された出品者の振込送金先口座に送金するものとします。
                  <br />
                  この場合において、送金手数料として出品者は５００円負担するものとし、当社が送金額から当該金額を控除し取得することを予め承諾するものとします。
                </li>
                <li>
                  出品者は、注文者に対して、前項に基づく商品代金の支払を受けた後、当社所定の方式により、速やかに領収書を発行するものとします。
                </li>
              </ol>
              <h4>第１４条（キャンセル、返品、交換の禁止）</h4>
              <ol>
                <li>
                  本サービスの利用にあたり、出品者は、注文者（但し、既に注文された場合に限る。）及び当社の同意がある場合を除き、商品出品後のキャンセルは出来ないものとします。
                </li>
                <li>
                  本サービスの利用にあたり、注文者は、出品者及び当社の同意がある場合を除き、商品の注文後のキャンセルはできないものとします。
                </li>
                <li>
                  本条に基づくキャンセルが行われた場合において、既に当社に支払われた本サービス利用料は、理由の如何を問わず、注文者に返金しないものとします。
                </li>
                <li>
                  本サービスの利用により、注文者が商品を受領した場合において、第１２条４項に定める場合を除き、当該商品の返品及び交換はできないものとします。但し、契約当事者間において別途合意した場合はこの限りではありません。
                </li>
              </ol>
              <h4>第１５条（売買契約の取消）</h4>
              <ol>
                <li>
                  売買契約が成立した場合であっても、注文者が商品代金及び利用料の合計額を当社所定の手続きに従って支払わない場合又は支払を遅延した場合、出品者が商品を発送しない場合、その他当社が必要と認める場合、当社は、出品者及び注文者間の売買契約を取り消すことが出来るものとします。
                  <br />
                  この場合において、出品者及び注文者並びに当社間の精算手続に関して、当社の指示に従うものとします。
                  <br />
                  但し、当社に既に支払われた本サービス利用料は、理由の如何を問わず、注文者に返金しないものとします。
                </li>
                <li>
                  前項の取消によりユーザーに損害が生じた場合であっても、当社は何らの責任を負うものではありません。
                </li>
              </ol>
              <h4>第１６条（免責）</h4>
              <ol>
                <li>
                  当社は、個別取引における商品の内容、品質、安全性、適法性、有用性等、又は情報の正確性、信憑性につき一切の確認又は保証を行わず、当該商品又はその情報に瑕疵があった場合でも、一切の責任を負いません。
                </li>
                <li>
                  当社は、個別取引における商品の引渡の履行可能性につき一切の保証を行うものではなく、出品者による履行が適切になされなかった場合でも、一切の責任を負うものではありません。
                </li>
                <li>
                  当社は、個別取引における購入後の当該商品の取り扱い上生じうる危険又は出荷に伴う設置手続きに伴い生じうる危険に関する発生可能性について、何ら確認又は検証するものではなく、これらの危険の発生が現実化し、出品者、注文者又は配送業者等個別取引に関係する第三者に損害が生じた場合でも、当社は何らの責任を負うものではありません。
                </li>
                <li>
                  ユーザーは、当社に対し、個別取引に関する一切のクレーム、トラブルへの関与、解決、損害賠償その他の請求を行わず、これらの責任を免除することに同意するものとします。
                </li>
                <li>
                  出品者及び注文者との間で商品等に関してトラブルが発生した場合、当該ユーザー間で解決するものとします。但し、当社の判断により、当社も協議に入ることができるものとします。
                </li>
              </ol>
              <h4>第１７条（校正・修理の手続）</h4>
              <ol>
                <li>
                  ユーザーは、当社の定める手続きにより、当社が提携する校正・修理会社に対して、商品の校正又は修理（以下「校正等」といいます。）を有償で求めることができます（以下「校正等サービス」といいます。）。
                  <br />
                  但し、当社は、ユーザーが求める校正作業を行うものではなく、当社と提携する校正作業を行う会社（以下「校正会社」といいます。）を案内するのみであり、当社は、校正作業に関する個別の契約当事者の地位に立たないものとします。
                </li>
                <li>
                  ユーザーが校正等サービスを希望し、校正会社がこれを受諾した場合に、ユーザーと校正会社との間で校正等に関する契約が成立するものとします。
                </li>
                <li>
                  ユーザーが前項に基づく校正会社との間の校正等に関する契約に基づく校正等サービスを利用する場合、当社が事前に通知する校正等サービス手数料を、当社が指定する期限までに当社所定の方法により支払うものとします。
                  <br />
                  この場合において、当社に既に支払われた校正等サービス手数料は、いかなる理由であっても、ユーザーに返金しないものとします。
                </li>
                <li>
                  ユーザーは校正等サービスを利用する場合、当社の指示する場所に校正対象品を配送するものとします。この場合の配送手数料は、当該ユーザーの負担とします。
                </li>
                <li>
                  ユーザーは、校正作業を依頼するにあたり、校正会社から、校正等に要する作業代金及び納期等の見積もりを取得できるものとします。
                </li>
                <li>
                  当社は、ユーザーが校正等サービスを利用した結果及び校正等サービスを利用したことにより生じた損害について、何らの責任を負うものではありません。
                </li>
                <li>
                  校正等サービス利用によって生じた紛争については、ユーザーと校正会社との間で解決するものとし、当社に一切の迷惑を掛けないものとします。
                </li>
              </ol>
              <h4>第１８条（禁止事項）</h4>
              &nbsp;&nbsp;&nbsp;ユーザーは、本サービスの利用にあたり、以下の行為をしてはなりません。
              <br />
              ①法令、裁判所の判決、決定若しくは命令、又は法令上拘束力のある行政措置に違反する行為又はこれらを助長する行為
              <br />
              ②当社又は他のユーザーその他の第三者に対する詐欺又は脅迫行為
              <br />
              ③公序良俗に反し又は善良な風俗を害する行為
              <br />
              ④当社又は他のユーザーその他の第三者の知的財産権、肖像権、プライバシー、名誉、その他の権利又は利益を侵害する行為
              <br />
              ⑤本サービスの誤作動を誘引する行為
              <br />
              ⑥本サービスが通常意図しないバグを利用する動作又は通常意図しない効果を及ぼすツールの利用、作成又は頒布を行う行為
              <br />
              ⑦本サービス又は当社サーバー等に過度の負担をかける行為
              <br />
              ⑧本サービスの内外を問わず、本サービス上のアカウント（ユーザーID又はパスワードを含む）又はコンテンツを第三者に有償で貸与、譲渡、売買等をする行為（いわゆるリアルマネートレード行為）
              <br />
              ⑨前号に定める行為の申込みの誘引、申込み、承諾を含む一切の準備行為
              <br />
              ⑩歩行中、車両運転中、その他の本サービスの利用が不適切な状況又は態様において本サービスを利用する行為
              <br />
              以下に該当し又は該当すると当社が判断した情報を本サービス上に送信・投稿する行
              <br />為
              <br />ア
              当社又は第三者の著作権その他の知的財産権、肖像権、プライバシー、名誉、その他の権利又は利益を侵害する情報
              <br />イ
              ユーザーを特定可能な個人情報等を含む情報（但し、利用登録に必要な場合等当社が求めた場合、その他当社が認めた場合を除きます。）
              <br />ウ 無限連鎖講、ネットワークビジネス関連の勧誘等に関する情報
              <br />エ ジャンクメール、スパムメールに相当する文面を含む情報
              <br />オ コンピュータウィルス等の有害なプログラムを流布させる情報
              <br />カ その他当社が不適切と判断する情報
              <br />
              ⑫本サービスが意図しない方法で利益を得ることを目的とする行為
              <br />
              ⑬本サービスを逆アセンブル、逆コンパイル、リバースエンジニアリング、その他本アプリのソースコードを解析する行為
              <br />
              ⑭本サービスに接続しているシステムに権限なく不正アクセスし又は当社設備に蓄積された情報を不正に書き換え若しくは消去する行為
              <br />
              ⑮本サービスを複製、送信、譲渡、貸与又は改変する行為
              <br />
              ⑯故意に虚偽のデータ等を公開し又は投稿する行為
              <br />
              ⑰当社又は他のユーザーの情報の収集を目的とする行為
              <br />
              ⑱当社又は他のユーザーその他の第三者に成りすます行為
              <br />
              ⑲他のユーザーアカウントを利用する行為
              <br />
              ⑳本サービス上での宣伝、広告又は勧誘行為
              <br />
              ㉑反社会勢力等への利益供与行為
              <br />
              ㉒本規約及び本サービスの趣旨・目的に反する行為
              <br />
              ㉓その他、当社が不適切と判断する行為
              <br />
              <h4>第１９条（本サービスの提供の停止等）</h4>
              <ol>
                <li>
                  当社は、以下のいずれかの事由があると判断した場合、ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
                  <ol>
                    <li>本サービスにかかるコンピュータシステムの保守点検または更新を行う場合</li>
                    <li>地震、落雷、火災、停電または天災などの不可抗力により、本サービスの提供が困難となった場合</li>
                    <li>コンピュータまたは通信回線等が事故により停止した場合</li>
                    <li>その他、当社が本サービスの提供が困難と判断した場合</li>
                  </ol>
                </li>
                <li>
                  当社は、本サービスの提供の停止または中断により、ユーザーまたは第三者が被ったいかなる不利益または損害について、理由を問わず一切の責任を負わないものとします。
                </li>
              </ol>
              <h4>第２０条（利用制限および登録抹消）</h4>
              <ol>
                <li>
                  当社は、本条に基づき当社が行った行為によりユーザーに生じた損害について、一切の責任を負いません。
                  <ol>
                    <li>本規約のいずれかの条項に違反した場合</li>
                    <li>登録事項に虚偽の事実があることが判明した場合</li>
                    <li>本規約等に必要となる手続き又は当社への連絡を行わなかった場合</li>
                    <li>登録した当社からユーザーへの連絡が不通となった場合</li>
                    <li>ユーザーが債務超過、無資力、支払停止又は支払不能の状態に陥った場合</li>
                    <li>ユーザーの経済的信用不安の情報を当社が確知した場合</li>
                    <li>ユーザーについて破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始された場合</li>
                    <li>過去に本規約等に違反し、本サービスの利用停止処分となった者である場合</li>
                    <li>
                      登録した金融機関の口座に関して違法又は不適切その他の問題があることが当該金融機関による指摘その他により判明した場合
                    </li>
                    <li>その他、当社が本サービスの利用を適当でないと判断した場合</li>
                  </ol>
                </li>
              </ol>
              <h4>第２１条（ユーザーの退会）</h4>
              <ol>
                <li>
                  ユーザーが本サービスからの退会を求める場合、当社に対して、退会を希望する旨を問い合わせ、当社が退会を認めた場合、退会することができるものとします。
                  <br />
                  但し、取引の決済や商品の発送等未了の手続きがある場合は当該一連の取引を完了したと当社が判断した場合に退会することができるものとします。
                </li>
                <li>当社は、本条の措置により生じうる損害について何らの責任を負わないものとします。</li>
              </ol>
              <h4>第２２条（サービス内容の変更等）</h4>
              当社は、ユーザーに通知することなく、本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし、これによってユーザーに生じた損害について一切の責任を負いません。
              <br />
              <h4>第２３条（損害賠償）</h4>
              <ol>
                <li>
                  ユーザーによる本規約違反行為その他本サービスの利用に起因して、当社に直接又は間接の損害が生じた場合（当該行為が原因で、当社らが第三者から損害賠償請求その他の請求を受けた場合を含みます。）、ユーザーは、当社に対し、その全ての損害（弁護士等専門家費用及び当社において対応に要した人件費相当額を含みます。）を賠償しなければなりません。
                </li>
                <li>
                  本サービスの利用に関連してユーザーが被った損害につき、当社が、本利用規約において責任を負わない旨定めている以外に、債務不履行責任又は不法行為責任を負う場合には、当社は、ユーザーに現実に発生した直接かつ通常の損害に限り、これを賠償する責任を負うものとし、特別な事情から生じた損害については、責任を負わないものとします。
                </li>
              </ol>
              <h4>第２４条（利用規約の変更）</h4>
              <ol>
                <li>
                  当社は、必要と判断した場合には、ユーザーに通知することなくいつでも本規約を変更又は追加することができるものとします。変更後の利用規約は、当社が別途定める場合を除いて、当社の運営するウェブサイトに掲示します。
                </li>
                <li>
                  ユーザーが本規約の変更又は追加後も本サービスの利用を継続する場合、当該ユーザーは、変更又は追加後の本規約に同意したものとみなされます。ユーザーは、ユーザーの責任において、随時、本規約の最新の内容を確認のうえ、本サービスをご利用ください。
                </li>
              </ol>
              <h4>第２５条（通知または連絡）</h4>
              <ol>
                <li>本サービスに関する当社からユーザーへの連絡は、当社が定める方法により行うものとします。</li>
                <li>
                  当社が通知を行った場合、当社からの通知は、当該通知が通常到達すべきときに到達したものとみなします。
                </li>
                <li>
                  本サービスに関する問い合わせその他ユーザーから当社らに対する連絡又は通知は、当社が指定する方法により行うものとします。
                </li>
              </ol>
              <h4>第２６条（接続環境）</h4>
              <ol>
                <li>
                  本サービスの提供を受けるために必要なコンピュータ、スマートフォンその他の機器、ソフトウェア、通信回線その他の通信接続環境等は、ユーザーの費用と責任において準備し維持するものとします。また、その機器・ソフトウェア・通信接続環境等の設置や操作についても、ユーザーは自らの費用と責任で行うものとします。
                  <br />
                  当社は、本サービスがあらゆる機器等に適合することを保証するものではなく、機器等の準備、設置、操作に関して、一切関与せず、ユーザーに対するサポートを行う義務はないものとします。
                </li>
                <li>
                  利用者は、本サービスを利用する際に、種々のネットワークを経由する場合があることを理解し、接続しているネットワークや機器等によっては、それらに接続したり、それらを通過するために、データや信号等の内容が変更される可能性があることを理解したうえで、本サービスを利用するものとし、かかる変更について当社は一切責任を負わないものとします。
                </li>
                <li>
                  利用者がインターネット回線を通じて行う本サービスへの入力、出品、購入、退会、その他の手続きは、当社のサーバーに当該手続きに関するデータが送信され、当社のシステムに当該手続の内容が反映された時点をもって有効に成立するものとします。
                </li>
              </ol>
              <h4>第２７条（事業譲渡等の場合の取扱い）</h4>
              当社が、本サービス若しくは本サービスにかかる事業を第三者に譲渡し、又は合併若しくは会社分割等により本サービスに係る事業を承継させたときは、当社は、当該譲渡等に伴い、本利用契約上の地位、権利及び義務並びに登録情報その他のユーザー情報を当該譲渡等の譲受人等に承継させることができるものとします。
              <br />
              ユーザーは、かかる譲渡等につき本条において予めこれに同意したものとみなします。
              <br />
              <h4>第２８条（分離可能性）</h4>
              <ol>
                <li>
                  本規約のいずれかの条項又はその一部が無効又は執行不能と判断とされた場合であっても、当該判断は他の部分に影響を及ぼさず、本規約の残りの部分は、引き続き有効かつ執行力を有するものとします。当社及びユーザーは、当該無効若しくは執行不能とされた条項又は部分の趣旨に従い、これと同等の効果を確保できるように努めるとともに、修正された本規約に拘束されることに同意するものとします。
                </li>
                <li>
                  本規約のいずれかの条項又はその一部が、あるユーザーとの関係で無効又は執行不能と判断された場合であっても、他のユーザーとの関係における有効性等には影響を及ぼさないものとします。
                </li>
              </ol>
              <h4>第２９条（権利義務の譲渡の禁止）</h4>
              ユーザーは、当社の書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し、または担保に供することはできません。
              <br />
              <h4>第３０条（準拠法・裁判管轄）</h4>
              <ol>
                <li>本規約の解釈にあたっては、日本法を準拠法とします。</li>
                <li>
                  本サービスに関して紛争が生じた場合には、当社の本店所在地を管轄する裁判所を専属的合意管轄裁判所とします。
                </li>
              </ol>
              <br />
              【附則】
              <br />
              本規約は、2019年1月1日から施行します。
              <br />
              本規約改定：2019年4月11日
              <br />
              <span style={{ float: 'right' }}>以上</span>
            </div>
          </div>
        </CardContent>
      </Card>
    );
  }
}

export default TermAndCondition;
