const { validateNumber } = require('helpers/Utils');
const { ERROR_MESSAGE } = require('helpers/Const');

export const validate = values => {
  const errors = {};
  if (!values.price) {
    errors.price = [ERROR_MESSAGE.REQUIRED];
  }
  if (values.price) {
    if (!validateNumber(values.price) || Number(values.price) < 1) {
      errors.price = 'Invalid price';
    }
  }
  if (!values.year) {
    errors.year = [ERROR_MESSAGE.REQUIRED];
  }
  if (!values.assurances) {
    errors.assurances = [ERROR_MESSAGE.REQUIRED];
  }
  if (!values.calibration) {
    errors.calibration = [ERROR_MESSAGE.REQUIRED];
  }
  if (!values.delivery) {
    errors.delivery = [ERROR_MESSAGE.REQUIRED];
  }
  if (!values.accessory) {
    errors.accessory = [ERROR_MESSAGE.REQUIRED];
  }
  if (!values.validation) {
    errors.validation = [ERROR_MESSAGE.REQUIRED];
  }
  return errors;
};
