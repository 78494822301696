import merchandiseReducer from 'containers/Merchandise/reducer';
import popupReducer from 'containers/Popup/reducer';
import userReducer from 'containers/Admin/reducer';
const reducer = {
  merchandiseReducer,
  ...userReducer,
  ...popupReducer
};

export default reducer;
