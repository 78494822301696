import React from 'react';
import { Route } from 'react-router-dom';
import TermAndCondition from 'containers/TermsConditions';
import PrivacyPolicy from 'containers/PrivacyPolicy';
import CustomRedirectRoute from 'components/CustomRedirectRoute';

export default [
  <Route exact path="/" component={CustomRedirectRoute} key="redirect-route" />,
  <Route exact path="/terms" component={TermAndCondition} key="Terms and condtions" />,
  <Route exact path="/privacy" component={PrivacyPolicy} key="Privacy policies" />
];
