import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { List } from 'components/CustomList';
import { withStyles } from '@material-ui/core/styles';
import { CustomDatagrid } from 'components/CustomDatagrid';
import _ from 'lodash';
import TextFieldForGrid from 'components/CustomFieldForGrid/TextField';
import { getPendingMembersRequest } from '../actions';
import { connect } from 'react-redux';
import ActionsToolbar from 'containers/Admin/AdminList/components/CustomActions';
import PendingAdminList from 'containers/Admin/AdminList/components/PendingAdminList';
import DeleteAdminButton from 'containers/Admin/AdminList/components/DeleteButton';
import CustomFilter from 'components/CustomFilters';
import MemeberIcon from 'assets/member.svg';
import DeletePopup from 'components/DeletePopup';
import EmptyList from 'containers/Admin/AdminList/components/EmptyList';
import { RESOURCES, LABEL } from 'helpers/Const';
import ConfirmPopup from 'components/ConfirmPopup';

const styles = {
  image: { maxHeight: '1rem' },
  card: {
    minWidth: 275
  },
  toolbar: {
    display: 'unset',
    padding: 0
  },
  adminTableGrid: {
    width: 'unset !important'
  },
  tableIcon: {
    marginLeft: '24px'
  },
  tableName: {
    lineHeight: '24px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#202a6a',
    marginLeft: '8px'
  },
  pendingDeleteBtn: {
    justifyContent: 'flex-end',
    marginRight: '15px'
  },
  mgTop_40: {
    marginTop: '40px'
  }
};

class AdminList extends Component {
  componentDidMount() {
    this.props.getPendingMembersRequest();
  }
  render() {
    const { classes } = this.props;
    const listProps = _.omit(this.props, [
      'dispatch',
      'classes',
      'adminList',
      'pendingAdminList',
      'currentUserRole',
      'getPendingMembersRequest'
    ]);
    const gridProps = _.omit(listProps, ['hasShow', 'hasList', 'hasEdit']);
    const { adminList = [] } = this.props;
    const { pendingAdminList = [] } = this.props;
    return (
      <div>
        <DeletePopup />
        <ConfirmPopup />
        <List
          title="台帳"
          pagination={null}
          bulkActionButtons={false}
          actions={this.props.currentUserRole === 'MEMBER' ? <ActionsToolbar /> : null}
          sort={{ field: 'createdAt', order: 'DESC' }}
          classes={{ card: classes.card, toolbar: classes.toolbar }}
          filters={<CustomFilter title={RESOURCES.ADMIN.label} />}
          {...listProps}
        >
          <CustomDatagrid
            tableClassName={classes.adminTableGrid}
            tableName={
              <Fragment>
                <img className={classes.tableIcon} alt="member" src={MemeberIcon} />
                <div className={classes.tableName}>登録メンバー</div>
              </Fragment>
            }
            fixedColNumber={0}
            colWidth={155}
            {...gridProps}
            rowClick="show"
          >
            <TextFieldForGrid className="cell-content" source="representative" label={LABEL.USER_NAME} />
            <TextFieldForGrid className="cell-content" source="username" label={LABEL.EMAIL_ADDRESS} />
            <TextFieldForGrid className="cell-content" source="roleName" label={LABEL.AUTHORITY} />
            {this.props.currentUserRole === 'MEMBER' && (
              <DeleteAdminButton className={classes.pendingDeleteBtn} basepath="/admin" />
            )}
          </CustomDatagrid>
        </List>
        <PendingAdminList className={classes.mgTop_40} />
        {_.isEmpty(adminList) && _.isEmpty(pendingAdminList) && <EmptyList />}
      </div>
    );
  }
}

AdminList.propTypes = {
  selectedIds: PropTypes.any,
  dispatch: PropTypes.any,
  classes: PropTypes.object,
  getPendingMembersRequest: PropTypes.func,
  pendingAdminList: PropTypes.array,
  adminList: PropTypes.array,
  currentUserRole: PropTypes.string
};

const mapStateToProps = state => {
  return {
    adminList: _.get(state, 'admin.resources.admin.list.ids'),
    pendingAdminList: _.get(state, 'userReducer.pendingMembers'),
    currentUserRole: _.get(state, 'admin.resources.user.data.currentUser.roleName')
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getPendingMembersRequest: () => dispatch(getPendingMembersRequest())
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AdminList));
