import {
  GET_PARENT_CATEGORIES_REQUEST,
  GET_PARENT_CATEGORIES_SUCCESS,
  GET_PARENT_CATEGORIES_ERROR,
  GET_SUB_CATEGORIES_REQUEST,
  GET_SUB_CATEGORIES_SUCCESS,
  GET_SUB_CATEGORIES_ERROR,
  GET_MANUFACTURERS_REQUEST,
  GET_MANUFACTURERS_SUCCESS,
  GET_MANUFACTURERS_ERROR,
  SET_IMPORT_FILE,
  SET_IMPORT_FILE_SUCCESS,
  SET_IMPORT_FILE_ERROR,
  TAKE_IMPORT_FILE,
  REMOVE_IMPORT_FILE,
  GET_PREFECTURES_REQUEST,
  GET_PREFECTURES_SUCCESS,
  GET_PREFECTURES_ERROR
} from './constants';

export function getParentCategoriesRequest() {
  return {
    type: GET_PARENT_CATEGORIES_REQUEST
  };
}
export function getParentCategoriesSuccess(data) {
  return {
    type: GET_PARENT_CATEGORIES_SUCCESS,
    data
  };
}
export function getParentCategoriesError(error) {
  return {
    type: GET_PARENT_CATEGORIES_ERROR,
    error
  };
}

export function getSubCategoriesRequest(data) {
  return {
    type: GET_SUB_CATEGORIES_REQUEST,
    data
  };
}
export function getSubCategoriesSuccess(data) {
  return {
    type: GET_SUB_CATEGORIES_SUCCESS,
    data
  };
}
export function getSubCategoriesError(error) {
  return {
    type: GET_SUB_CATEGORIES_ERROR,
    error
  };
}

export function getManufacturersRequest(data) {
  return {
    type: GET_MANUFACTURERS_REQUEST,
    data
  };
}
export function getManufacturersSuccess(data) {
  return {
    type: GET_MANUFACTURERS_SUCCESS,
    data
  };
}
export function getManufacturersError(error) {
  return {
    type: GET_MANUFACTURERS_ERROR,
    error
  };
}
export function takeImportFile(data) {
  return {
    type: TAKE_IMPORT_FILE,
    data
  };
}
export function setImportFile(data) {
  return {
    type: SET_IMPORT_FILE,
    data
  };
}
export function setImportFileSuccess(data) {
  return {
    type: SET_IMPORT_FILE_SUCCESS,
    data
  };
}
export function setImportFileFailure(error) {
  return {
    type: SET_IMPORT_FILE_ERROR,
    error
  };
}
export function removeImportFile(data) {
  return {
    type: REMOVE_IMPORT_FILE,
    data
  };
}
export function getPrefecturesRequest() {
  return {
    type: GET_PREFECTURES_REQUEST
  };
}
export function getPrefecturesSuccess(payload) {
  return {
    type: GET_PREFECTURES_SUCCESS,
    payload
  };
}
export function getPrefecturesFailure(error) {
  return {
    type: GET_PREFECTURES_ERROR,
    error
  };
}
