import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withStyles } from '@material-ui/core';
import DeleteAdminBtn from 'containers/Admin/AdminList/components/DeleteButton';
import PendingAdminIcon from 'assets/pending.svg';
import ResendButton from 'containers/Admin/AdminList/components/ResendButton';
import STYLE from 'styles/_constants.scss';
import { LABEL } from 'helpers/Const';
const headCells = [
  { id: 'adminName', numeric: false, disablePadding: false, label: LABEL.USER_NAME },
  { id: 'adminAddress', numeric: false, disablePadding: false, label: LABEL.EMAIL_ADDRESS },
  { id: 'role', numeric: false, disablePadding: false, label: LABEL.AUTHORITY },
  { id: 'btn', numeric: false, disablePadding: false }
];

const EnhancedTableHead = props => {
  const { classes } = props;
  return (
    <TableHead>
      <TableRow className={classes.tableHeadRow}>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            className={classes.tableHeaderCell}
          >
            <TableSortLabel>{headCell.label}</TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired
};

const useStyles = () => ({
  root: {
    border: '1.5px solid #eeeeee',
    fontFamily: STYLE.fontFamily,
    backgroundColor: '#FFFFFF',
    '@media(max-width: 1024px)': {
      width: 'calc(100vw - 339px)'
    }
  },
  tableName: {
    marginLeft: '8px'
  },
  tableCell: {
    borderRight: '1.5px solid #eeeeee',
    padding: '16px 24px',
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: STYLE.fontFamily
  },
  paper: {
    width: '100%'
  },
  table: {},
  tableWrapper: {
    overflow: 'auto',
    boxSizing: 'border-box',
    '-moz-box-sizing': 'border-box',
    '&::-webkit-scrollbar': {
      height: '8px'
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '5px',
      backgroundColor: '#f5f6fa'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
      backgroundColor: '#cccccc'
    }
  },
  tableHeaderCell: {
    padding: '0 12px'
  },
  tableHeadRow: {},
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  deleteBtn: {
    justifyContent: 'flex-end',
    marginRight: '15px'
  },
  tableNameContainer: {
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1.5px solid #eeeeee',
    color: '#202a6b'
  },
  tableIcon: {
    marginLeft: '24px'
  },
  maxWidth_292: {
    width: '292px',
    '@media (max-width:1024px)': {
      width: '107px'
    }
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  resendBtn: {
    marginLeft: '16px',
    '@media (max-width:1024px)': {
      marginLeft: '0px'
    }
  }
});
const EnhancedTable = props => {
  const { className = '' } = props;
  const { classes = {} } = props;
  const { pendingAdmin = [] } = props;
  const rowNumber = pendingAdmin.length;
  if (pendingAdmin.length > 0) {
    return (
      <div className={`${classes.root} ${className}`}>
        <div className={classes.tableNameContainer}>
          <img alt="pending" className={classes.tableIcon} src={PendingAdminIcon} />
          <div className={classes.tableName}>承認待ち</div>
        </div>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead classes={classes} rowCount={rowNumber} />
            <TableBody>
              {pendingAdmin.map((data, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow role="checkbox" tabIndex={-1} key={index}>
                    <TableCell
                      className={`${classes.tableCell} ${classes.maxWidth_292}`}
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      <div>{data.adminName}</div>
                    </TableCell>
                    <TableCell className={classes.tableCell} component="th" id={labelId} scope="row" padding="none">
                      <div>{data.adminAddress}</div>
                    </TableCell>
                    <TableCell
                      className={`${classes.tableCell} ${classes.maxWidth_292}`}
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      <div>アドミン</div>
                    </TableCell>
                    <TableCell className={classes.maxWidth_292}>
                      <div className={classes.btnContainer}>
                        <ResendButton className={classes.resendBtn} record={data} />
                        <DeleteAdminBtn className={classes.deleteBtn} record={data} />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }
  return null;
};
const mapStateToProps = state => {
  return {
    pendingAdmin: _.get(state, 'userReducer.pendingMembers')
  };
};
EnhancedTable.propTypes = {
  pendingAdmin: PropTypes.array,
  classes: PropTypes.object,
  className: PropTypes.string
};
export default connect(mapStateToProps)(withStyles(useStyles)(EnhancedTable));
