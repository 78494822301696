import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withStyles } from '@material-ui/core';
const { DATE_FORMAT } = require('../../helpers/Const');

const comment = props => {
  const { classes } = props;
  return (
    <div className={classes.comment}>
      <div className={classes.author}>{props.author}</div>
      <div className={classes.updatedDate}>
        <div className={classes.iconCalendar}></div>
        <div className={classes.dateText}>{moment(props.updatedDate).format(DATE_FORMAT.DIV)}</div>
      </div>
      <div className={classes.content}>{props.content}</div>
    </div>
  );
};

comment.propTypes = {
  author: PropTypes.string,
  updatedDate: PropTypes.string,
  content: PropTypes.string,
  classes: PropTypes.object
};

const style = {
  comment: {
    marginTop: '24px'
  },
  iconCalendar: {
    width: '16px',
    height: '16px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '0px',
    backgroundImage: 'url(/img/icon-Calendar.svg)',
    backgroundSize: '16px 16px'
  },
  author: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '20px',
    width: 'max-content'
  },
  updatedDate: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '8px'
  },
  dateText: {
    fontSize: '12px',
    color: '#aaaaaa',
    marginLeft: '8px',
    lineHeight: '18px'
  },
  content: {
    marginTop: '8px',
    fontSize: '16px',
    color: '#3c3c3c',
    textAlign: 'justify'
  }
};

export default withStyles(style)(comment);
