import { ADJUSTMENT } from 'helpers/Const';
import moment from 'moment';
import _ from 'lodash';

const { validateDoubledigit, validateNumber, checkIfDeleteAllImages } = require('../../../helpers/Utils');
const { TARGET_OF_CALIBRATION, FIXED_ASSETS } = require('../MerchandiseCreate/constants');
const { ERROR_MESSAGE, SELLSTATE, DATE_FORMAT } = require('../../../helpers/Const');

const validate = values => {
  const errors = {};
  if (values.sellState === SELLSTATE.SUSPENDED) {
    if (!values.name || !_.get(values, 'name')) {
      errors.name = [ERROR_MESSAGE.REQUIRED];
    }
    if (!values.modelNumber) {
      errors.modelNumber = [ERROR_MESSAGE.REQUIRED];
    }
    if (values.price && (!validateNumber(values.price) || Number(values.price) < 1)) {
      errors.price = [ERROR_MESSAGE.INVALID];
    }
    if (values.statusOfAdjustment && values.statusOfAdjustment === ADJUSTMENT.CALIBRATED) {
      if (!values.expirationOfAdjustment) {
        errors.expirationOfAdjustment = [ERROR_MESSAGE.REQUIRED];
      }
    }
    if (values.expirationOfAdjustment) {
      if (!moment(values.expirationOfAdjustment, DATE_FORMAT.SIMPLE, true).isValid()) {
        errors.expirationOfAdjustment = [ERROR_MESSAGE.INVALID];
      } else {
        const nYear = moment().year();
        const nMonth = moment().month();
        const expirationYear = moment(values.expirationOfAdjustment, DATE_FORMAT.SIMPLE, true).year();
        const expirationMonth = moment(values.expirationOfAdjustment, DATE_FORMAT.SIMPLE, true).month();
        if (expirationYear < nYear || (nYear === expirationYear && expirationMonth < nMonth)) {
          errors.expirationOfAdjustment = [ERROR_MESSAGE.INVALID];
        }
      }
    }
  }
  if (values.sellState === SELLSTATE.SELLING) {
    if (!values.modelNumber) {
      errors.modelNumber = [ERROR_MESSAGE.REQUIRED];
    }
    if (
      !values.images ||
      (Array.isArray(values.images) && values.images.length === 0) ||
      (Array.isArray(values.images) && values.images.length > 0 && checkIfDeleteAllImages(values.images))
    ) {
      errors.images = [ERROR_MESSAGE.REQUIRED];
    } else if (Array.isArray(values.images) && values.images.length > 10) {
      errors.images = [ERROR_MESSAGE.INVALID];
    }
    if (!values.categoryParentId) {
      errors.categoryParentId = [ERROR_MESSAGE.REQUIRED];
    }
    if (!values.name || !_.get(values, 'name').trim()) {
      errors.name = [ERROR_MESSAGE.REQUIRED];
    }
    if (!values.categoryId || !_.get(values, 'categoryId').trim()) {
      errors.categoryId = [ERROR_MESSAGE.REQUIRED];
    }
    if (!values.manufacturerId || !_.get(values, 'manufacturerId').trim()) {
      errors.manufacturerId = [ERROR_MESSAGE.REQUIRED];
    }
    if (!values.statusOfAdjustment || !_.get(values, 'statusOfAdjustment').trim()) {
      errors.statusOfAdjustment = [ERROR_MESSAGE.REQUIRED];
    } else if (values.statusOfAdjustment === ADJUSTMENT.CALIBRATED) {
      if (!values.expirationOfAdjustment) {
        errors.expirationOfAdjustment = [ERROR_MESSAGE.REQUIRED];
      }
    }
    if (values.expirationOfAdjustment) {
      if (!moment(values.expirationOfAdjustment, DATE_FORMAT.SIMPLE, true).isValid()) {
        errors.expirationOfAdjustment = [ERROR_MESSAGE.INVALID];
      } else {
        const nYear = moment().year();
        const nMonth = moment().month();
        const expirationYear = moment(values.expirationOfAdjustment, DATE_FORMAT.SIMPLE, true).year();
        const expirationMonth = moment(values.expirationOfAdjustment, DATE_FORMAT.SIMPLE, true).month();
        if (expirationYear < nYear || (nYear === expirationYear && expirationMonth < nMonth)) {
          errors.expirationOfAdjustment = [ERROR_MESSAGE.INVALID];
        }
      }
    }
    if (!values.description || !_.get(values, 'description').trim()) {
      errors.description = [ERROR_MESSAGE.REQUIRED];
    }
    if (!values.usaged || !_.get(values, 'usaged').trim()) {
      errors.usaged = [ERROR_MESSAGE.REQUIRED];
    }
    if (!values.delivery || !_.get(values, 'delivery').trim()) {
      errors.delivery = [ERROR_MESSAGE.REQUIRED];
    }
    if (!values.price || !_.get(values, 'price')) {
      errors.price = [ERROR_MESSAGE.REQUIRED];
    } else if (!validateNumber(values.price) || Number(values.price) < 1) {
      errors.price = [ERROR_MESSAGE.INVALID];
    }
    if (values.targetOfCalibration === TARGET_OF_CALIBRATION.TARGET) {
      if (!values.calibrationCycle) {
        errors.calibrationCycle = [ERROR_MESSAGE.REQUIRED];
      } else {
        if (!validateDoubledigit(values.calibrationCycle)) {
          errors.calibrationCycle = [ERROR_MESSAGE.INVALID];
        }
      }

      if (!values.calibrationAppliedDate || !_.get(values, 'calibrationAppliedDate').trim()) {
        errors.calibrationAppliedDate = [ERROR_MESSAGE.REQUIRED];
      } else {
        if (!moment(values.calibrationAppliedDate, 'YYYY/MM/DD', true).isValid()) {
          errors.calibrationAppliedDate = [ERROR_MESSAGE.INVALID];
        }
      }

      if (!values.calibrationDate || !_.get(values, 'calibrationDate').trim()) {
        errors.calibrationDate = [ERROR_MESSAGE.REQUIRED];
      } else {
        if (!moment(values.calibrationDate, DATE_FORMAT.SIMPLE, true).isValid()) {
          errors.calibrationDate = [ERROR_MESSAGE.INVALID];
        }
      }
    }
    if (values.fixedAssets && values.fixedAssets === FIXED_ASSETS.TARGET) {
      if (!values.purchasedDate || !_.get(values, 'purchasedDate')) {
        errors.purchasedDate = [ERROR_MESSAGE.REQUIRED];
      } else {
        if (
          !moment(values.purchasedDate, DATE_FORMAT.SIMPLE, true).isValid() ||
          moment(values.purchasedDate, DATE_FORMAT.SIMPLE, true).isAfter(moment().subtract(1, 'year'))
        ) {
          errors.purchasedDate = [ERROR_MESSAGE.INVALID];
        }
      }
      if (!values.originalPrice || !_.get(values, 'originalPrice')) {
        errors.originalPrice = [ERROR_MESSAGE.REQUIRED];
      } else if (!validateNumber(values.originalPrice) || Number(values.originalPrice) < 1) {
        errors.originalPrice = [ERROR_MESSAGE.INVALID];
      }

      if (!values.serviceLife || !_.get(values, 'serviceLife')) {
        errors.serviceLife = [ERROR_MESSAGE.REQUIRED];
      } else if (
        !validateNumber(values.serviceLife) ||
        Number(values.serviceLife) < 1 ||
        Number(values.serviceLife) > 100
      ) {
        errors.serviceLife = [ERROR_MESSAGE.INVALID];
      }
      if (!validateNumber(values.realMarketValue)) {
        errors.realMarketValue = [ERROR_MESSAGE.INVALID];
      }
    }
  }
  if (!values.storageLocation || !_.get(values, 'storageLocation').trim()) {
    errors.storageLocation = [ERROR_MESSAGE.REQUIRED];
  }
  if (values.year) {
    const year = values.year.replace(/[_]/g, '');
    if (year < 1800) {
      errors.year = [ERROR_MESSAGE.INVALID];
    }
  }
  return errors;
};

export default validate;
