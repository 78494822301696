import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { change } from 'redux-form';
import { Create, SimpleForm, FormDataConsumer, REDUX_FORM_NAME } from 'react-admin';
import { Grid, Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import validate from 'containers/Merchandise/MerchandiseEdit/validate';
import {
  getParentCategoriesRequest,
  getSubCategoriesRequest,
  getManufacturersRequest,
  getPrefecturesRequest
} from 'containers/Merchandise/actions';
import utils from 'containers/Merchandise/MerchandiseEdit/utils';
import { MANUFACTURER, LABEL, DATE_FIELD_TYPES, SELLSTATE, DATE_FORMAT, ADJUSTMENT } from 'helpers/Const';
import ImageUploader from 'containers/Merchandise/MerchandiseEdit/components/ImageUploader';
import BorderTextField from '../../../components/CustomFields/BorderTextField';
import SelectField from '../../../components/CustomFields/SelectField';
import CustomDateField from '../../../components/CustomFields/CustomDateField';
import LongTextField from '../../../components/CustomFields/LongTextField';
import PriceSuggestionPoup from 'components/PriceSuggestionPopup/index';
import './style.scss';
import PostCreateToolbar from '../../../components/CustomCreateToolbar';
import { formatPrice, normalizeAmount } from 'helpers/Utils';
import carryingValue from 'helpers/CarryingValueCalculation';
import STYLE from 'styles/_constants.scss';
import { setPopup } from 'containers/Popup/actions';

const { TARGET_OF_CALIBRATION, FIXED_ASSETS } = require('./constants');
const { MERCHANDISE, BUTTON_LABEL } = require('../../../helpers/Const');

const styles = theme => ({
  gridContainer: {
    padding: '0 2.5rem !important'
  },
  spacing: {
    ...theme.mixins.toolbar
  },
  blockContainer: {
    backgroundColor: '#fff',
    padding: '1.5rem 0'
  },
  headingSpacing: {
    paddingBottom: '1.5rem',
    fontFamily: `${STYLE.fontFamily}`,
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#3c3c3c'
  },
  dividerRoot: {
    backgroundColor: '#00a3df',
    height: '4px'
  },
  fieldLayout: {
    backgroundColor: '#f5f6fa'
  }
});

class MerchandiseCreate extends Component {
  state = {
    parentCategoriesData: [],
    subCategoriesData: [],
    manufacturersData: []
  };

  handlePopup = () => {
    this.props.setPopup('priceSuggestionPoup', true);
  };
  componentDidMount = () => {
    this.props.getPrefectures();
    this.props.getParentCategoriesRequest();
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { parentCategories, subCategories, manufacturers } = nextProps;

    let parentCategoriesData = [];
    let subCategoriesData = [];
    let manufacturersData = [];

    if (
      _.isEqual(parentCategories, prevState.parentCategories) &&
      _.isEqual(subCategories, prevState.subCategories) &&
      _.isEqual(manufacturers, prevState.manufacturers)
    ) {
      return null;
    }

    if (!_.isEqual(manufacturers, prevState.manufacturers)) {
      manufacturersData =
        _.isArray(manufacturers) &&
        manufacturers.map(item => ({
          id: _.get(item, 'objectId'),
          name: _.get(item, 'name')
        }));
    }

    if (!_.isEqual(parentCategories, prevState.parentCategories)) {
      parentCategoriesData =
        _.isArray(parentCategories) &&
        parentCategories.map(item => ({
          id: _.get(item, 'objectId'),
          name: _.get(item, 'name')
        }));
    }

    if (!_.isEqual(subCategories, prevState.subCategories)) {
      subCategoriesData =
        _.isArray(subCategories) &&
        subCategories.map(item => ({
          id: _.get(item, 'objectId'),
          name: _.get(item, 'name')
        }));
    }

    return {
      parentCategoriesData,
      subCategoriesData,
      manufacturersData
    };
  }

  onFormChange = event => {
    const serviceLife = Number(_.get(event, 'serviceLife'));
    const originalPrice = Number(_.get(event, 'originalPrice'));
    const purchasedDate = moment(_.get(event, 'purchasedDate'), DATE_FORMAT.SIMPLE);
    const age = purchasedDate.isValid() ? moment().diff(purchasedDate, 'years') : NaN;
    if (!isNaN(serviceLife) && !isNaN(originalPrice) && !isNaN(age)) {
      event.realMarketValue = carryingValue(originalPrice, serviceLife, age);
    } else {
      event.realMarketValue = '';
    }
  };

  render() {
    const { manufacturers, classes, sellState } = this.props;
    const required = sellState === SELLSTATE.SELLING ? true : false;
    const { parentCategoriesData, subCategoriesData, manufacturersData } = this.state;
    const otherManufacturer = _.find(manufacturers, { name: MANUFACTURER.OTHER });
    const { basePath = '/' } = this.props;
    const createProps = _.omit(this.props, [
      'classes',
      'parentCategories',
      'subCategories',
      'sellState',
      'getParentCategoriesRequest',
      'getSubCategoriesRequest',
      'getManufacturersRequest',
      'submitOnEnter',
      'setPopup'
    ]);
    return (
      <Fragment>
        <PriceSuggestionPoup />
        <Create {...createProps}>
          <SimpleForm
            redirect={'/merchandise'}
            className="simple-form-create"
            toolbar={<PostCreateToolbar saveRedirect={basePath} saveBtnLabel={BUTTON_LABEL.CREATE} />}
            validate={validate}
            onChange={this.onFormChange}
          >
            <FormDataConsumer>
              {({ formData, dispatch, ...rest }) => (
                <Fragment>
                  <header className="header">登録画面</header>
                  <Grid classes={{ container: classes.blockContainer }} container spacing={32}>
                    <Grid className={classes.gridContainer} item xs={12}>
                      <h2 className={classes.headingSpacing}>機器情報</h2>
                      <Divider classes={{ root: classes.dividerRoot }} />
                    </Grid>
                    <Grid className={classes.gridContainer} item xs={6}>
                      <SelectField
                        source="holdingCategory"
                        choices={MERCHANDISE.HOLDING_CATEGORIES}
                        fullWidth
                        label={LABEL.HOLDING_CATEGORY}
                      />
                      <BorderTextField
                        className="container"
                        source="assetNumber"
                        label={LABEL.CONTROL_NUMBER}
                        fullWidth
                      />
                      <BorderTextField source="name" label={LABEL.NAME} required={true} fullWidth />
                      <BorderTextField source="serialNumber" label={LABEL.SERIAL_NUMBER} fullWidth />
                    </Grid>
                    <Grid className={classes.gridContainer} item xs={6}>
                      <SelectField
                        label={LABEL.MAIN_CATEGORY}
                        source="categoryParentId"
                        choices={parentCategoriesData}
                        fullWidth
                        required={required}
                        onChange={(e, value) => {
                          dispatch(getSubCategoriesRequest({ parentId: value }));
                          dispatch(change(REDUX_FORM_NAME, 'categoryId', ''));
                        }}
                      />
                      <SelectField
                        label={LABEL.SUB_CATEGORY}
                        source="categoryId"
                        choices={subCategoriesData}
                        required={required}
                        onChange={(e, value) => {
                          dispatch(getManufacturersRequest({ categoryId: value }));
                          dispatch(change(REDUX_FORM_NAME, 'manufacturerId', ''));
                        }}
                        fullWidth
                      />
                      <SelectField
                        source="manufacturerId"
                        label={LABEL.MAKER}
                        choices={manufacturersData}
                        required={required}
                        fullWidth
                      />
                      {_.get(formData, 'manufacturerId') &&
                        _.get(formData, 'manufacturerId') === _.get(otherManufacturer, 'objectId') && ( // eslint-disable-line
                          <BorderTextField source="otherManufacturer" label={LABEL.OTHER_MAKER} fullWidth /> // eslint-disable-line
                        ) /* eslint-disable-line */}
                      <BorderTextField
                        required={true}
                        source="modelNumber"
                        label={LABEL.MAKER_MODEL_NUMBER}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <div className={classes.spacing} />
                  <Grid classes={{ container: classes.blockContainer }} container spacing={32}>
                    <Grid className={classes.gridContainer} item xs={12}>
                      <h2 className={classes.headingSpacing}>管理情報</h2>
                      <Divider classes={{ root: classes.dividerRoot }} />
                    </Grid>
                    <Grid className={classes.gridContainer} item xs={6}>
                      <BorderTextField source="officer" label={LABEL.OFFICER} fullWidth />
                    </Grid>
                    <Grid className={classes.gridContainer} item xs={6}>
                      <SelectField
                        source="storageLocation"
                        label={LABEL.STORAGE_LOCATION}
                        choices={this.props.prefectures}
                        fullWidth
                        required={true}
                      />
                      <BorderTextField
                        source="storageAndAvailableArea"
                        label={LABEL.STORAGE_AND_AVAILABLE_AREA}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <div className={classes.spacing} />
                  <Grid classes={{ container: classes.blockContainer }} container spacing={32}>
                    <Grid className={classes.gridContainer} item xs={12}>
                      <h2 className={classes.headingSpacing}>校正状況</h2>
                      <Divider classes={{ root: classes.dividerRoot }} />
                    </Grid>
                    <Grid className={classes.gridContainer} item xs={6}>
                      <SelectField
                        source="targetOfCalibration"
                        label={LABEL.TARGET_OF_CALIBRATION}
                        choices={MERCHANDISE.TARGET_OF_CALIBRATIONS}
                        fullWidth
                      />
                      {_.get(formData, 'targetOfCalibration') === TARGET_OF_CALIBRATION.TARGET && (
                        <Fragment>
                          <CustomDateField
                            type={DATE_FIELD_TYPES.YY}
                            label={LABEL.CALIBRATION_CYCLE}
                            source="calibrationCycle"
                            required={required}
                          />
                          <CustomDateField
                            type={DATE_FIELD_TYPES.YYYY_MM_DD}
                            label={LABEL.CALIBRATION_APPLIED_DATE}
                            source="calibrationAppliedDate"
                            required={required}
                          />
                          <CustomDateField
                            type={DATE_FIELD_TYPES.YYYY_MM}
                            label={LABEL.CALIBRATION_DATE}
                            source="calibrationDate"
                            required={required}
                          />
                        </Fragment>
                      )}
                    </Grid>
                    <Grid className={classes.gridContainer} item xs={6}>
                      <SelectField
                        source="calibrationResult"
                        label={LABEL.CALIBRATION_RESULT}
                        choices={MERCHANDISE.CALIBRATION_RESULTS}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <div className={classes.spacing} />
                  <Grid classes={{ container: classes.blockContainer }} container spacing={32}>
                    <Grid className={classes.gridContainer} item xs={12}>
                      <h2 className={classes.headingSpacing}>簿価情報</h2>
                      <Divider classes={{ root: classes.dividerRoot }} />
                    </Grid>
                    <Grid className={classes.gridContainer} item xs={6}>
                      <SelectField
                        source="fixedAssets"
                        label={LABEL.FIXED_ASSETS}
                        choices={MERCHANDISE.FIXED_ASSETSES}
                        fullWidth
                      />
                      {_.get(formData, 'fixedAssets') === FIXED_ASSETS.TARGET && (
                        <Fragment>
                          <BorderTextField
                            format={formatPrice}
                            normalize={normalizeAmount}
                            source="originalPrice"
                            label={`${LABEL.ORIGINAL_PRICE}`}
                            required={required}
                            fullWidth
                          />
                          <BorderTextField
                            source="serviceLife"
                            label={LABEL.SERVICE_LIFE}
                            required={required}
                            fullWidth
                          />
                          <BorderTextField
                            disabled
                            format={formatPrice}
                            normalize={normalizeAmount}
                            source="realMarketValue"
                            label={`${LABEL.REAL_MARKET_VALUE}`}
                            message="耐用年数・購入価格・購入日を入力すると定額法で算出されます"
                            fullWidth
                          />
                        </Fragment>
                      )}
                    </Grid>
                    <Grid className={classes.gridContainer} item xs={6}>
                      <CustomDateField
                        type={DATE_FIELD_TYPES.YYYY_MM}
                        label={LABEL.PURCHASED_DATE}
                        source="purchasedDate"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <div className={classes.spacing} />
                  <Grid classes={{ container: classes.blockContainer }} container spacing={32}>
                    <Grid className={classes.gridContainer} item xs={12}>
                      <h2 className={classes.headingSpacing}>商品画像</h2>
                      <Divider classes={{ root: classes.dividerRoot }} />
                    </Grid>
                    <Grid className={classes.gridContainer} item xs={12}>
                      <ImageUploader source="images" label={LABEL.MERCHANDISE_IMAGE} />
                    </Grid>
                  </Grid>
                  <div className={classes.spacing} />
                  <Grid classes={{ container: classes.blockContainer }} container spacing={32}>
                    <Grid className={classes.gridContainer} item xs={12}>
                      <h2 className={classes.headingSpacing}>出品に必要な情報</h2>
                      <Divider classes={{ root: classes.dividerRoot }} />
                    </Grid>
                    <Grid className={classes.gridContainer} item xs={6}>
                      <SelectField
                        source="statusOfAdjustment"
                        label={LABEL.STATUS_OF_ADJUSTMENT}
                        choices={utils.calibrations}
                        optionText="text"
                        optionValue="value"
                        required={required}
                        fullWidth
                      />
                      <CustomDateField
                        type={DATE_FIELD_TYPES.YYYY_MM}
                        label={LABEL.EXPIRATION_OF_ADJUSTMENT}
                        source="expirationOfAdjustment"
                        required={_.get(formData, 'statusOfAdjustment') === ADJUSTMENT.CALIBRATED ? true : false}
                      />
                      <LongTextField source="description" label={LABEL.DESCRIPTION} required={required} fullWidth />
                    </Grid>
                    <Grid className={classes.gridContainer} item xs={6}>
                      <CustomDateField source="year" label={LABEL.YEAR} type={DATE_FIELD_TYPES.YYYY} />
                      <LongTextField source="usaged" label={LABEL.USAGED} required={required} fullWidth />
                      <SelectField
                        source="delivery"
                        choices={utils.timeoptions}
                        optionText="text"
                        optionValue="value"
                        label={LABEL.DELIVERY}
                        required={required}
                        fullWidth
                      />
                      <BorderTextField
                        handleClick={this.handlePopup}
                        source="price"
                        label={LABEL.PRICE}
                        suggest="価格設定に困ったらこちら"
                        required={required}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Fragment>
              )}
            </FormDataConsumer>
          </SimpleForm>
        </Create>
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getParentCategoriesRequest: () => dispatch(getParentCategoriesRequest()),
  getSubCategoriesRequest: data => dispatch(getSubCategoriesRequest(data)),
  getManufacturersRequest: data => dispatch(getManufacturersRequest(data)),
  setPopup: (formName, state) => dispatch(setPopup(formName, state)),
  getPrefectures: () => dispatch(getPrefecturesRequest())
});

const mapStateToProps = state => ({
  parentCategories: _.get(state, 'merchandiseReducer.parentCategories', []),
  subCategories: _.get(state, 'merchandiseReducer.subCategories', []),
  manufacturers: _.get(state, 'merchandiseReducer.manufacturers', []),
  merchandises: _.get(state, 'admin.resources.merchandise.data'),
  sellState: _.get(state, 'form.record-form.values.sellState'),
  prefectures: _.get(state, 'merchandiseReducer.prefectures', [])
});

MerchandiseCreate.propTypes = {
  classes: PropTypes.any,
  getParentCategoriesRequest: PropTypes.func,
  getSubCategoriesRequest: PropTypes.func,
  getManufacturersRequest: PropTypes.func,
  parentCategories: PropTypes.array,
  subCategories: PropTypes.array,
  manufacturers: PropTypes.array,
  merchandises: PropTypes.object,
  id: PropTypes.any,
  sellState: PropTypes.string,
  setPopup: PropTypes.func,
  basePath: PropTypes.string,
  getPrefectures: PropTypes.func,
  prefectures: PropTypes.array
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(MerchandiseCreate);
