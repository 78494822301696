import React from 'react';
import { TextField } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import STYLE from 'styles/_constants.scss';

const styles = {
  field: {
    border: 'solid 1px #dddddd',
    borderRadius: '4px',
    width: '100%',
    overflowWrap: 'break-word',
    whiteSpace: 'pre-wrap',
    padding: '10px',
    height: 'auto',
    minHeight: '25px',
    marginBottom: '20px',
    marginTop: '7px',
    backgroundColor: '#f5f6fa',
    fontSize: '16px',
    color: '#3c3c3c',
    fontFamily: `${STYLE.fontFamily}`
  },
  fieldNoneBorder: {
    marginTop: '8px',
    fontWeight: 'bold',
    fontSize: '16px',
    fontFamily: `${STYLE.fontFamily}`
  },
  title: {
    fontSize: '14px',
    color: '#3c3c3c',
    fontWeight: '500',
    fontFamily: `${STYLE.fontFamily}`
  }
};

export const TextFieldCustom = withStyles(styles)(({ classes, label, source = '', ...props }) => {
  const FieldBorder =
    source !== `id` && source !== `createdAt` && source !== `createdUser.email` && source !== `sellState`;
  return (
    <div>
      <label className={classes.title}>{label}</label>
      <TextField
        source={source}
        className={FieldBorder ? classes.field : classes.fieldNoneBorder}
        fullWidth
        {...props}
      />
    </div>
  );
});
