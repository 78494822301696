import React, { Component } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import SelectField from 'components/CustomFields/SelectField';
import { bulkUpdate, selectExport } from 'containers/Merchandise/MerchandiseList/actions.js';
import PropTypes from 'prop-types';
import { SELLSTATE, CUSTOM_ACTION } from 'helpers/Const';
const styles = () => ({
  container: {
    margin: 0
  },
  groupLabel: {
    display: 'none'
  },
  form: {
    width: '180px'
  },
  menuItemPlaceholder: {
    color: '#3c3c3c'
  }
});

class ActionDropdown extends Component {
  handleChange = e => {
    switch (e.target.value) {
      case CUSTOM_ACTION.SELECT_EXPORT:
        this.props.selectExport({ ids: this.props.selectedIds }, '/merchandise', this.props.exporter);
        break;
      case SELLSTATE.SELLING:
        this.props.bulkUpdate(this.props.selectedIds, SELLSTATE.SELLING, '/merchandise');
        break;
      case SELLSTATE.SUSPENDED:
        this.props.bulkUpdate(this.props.selectedIds, SELLSTATE.SUSPENDED, '/merchandise');
        break;
      default:
        break;
    }
  };
  render() {
    const { classes = {}, selectedIds } = this.props;
    return (
      <form className={classes.form}>
        <Field
          label="アクションを選択"
          name="action"
          showLabel={false}
          component={SelectField}
          customForm={true}
          classes={classes}
          disabled={!selectedIds || (Array.isArray(selectedIds) && selectedIds.length === 0)}
          onChange={this.handleChange}
          choices={[
            { id: CUSTOM_ACTION.SELECT_EXPORT, name: 'CSVに出力' },
            { id: SELLSTATE.SELLING, name: '出品する' },
            { id: SELLSTATE.SUSPENDED, name: '保留にする' }
          ]}
        />
      </form>
    );
  }
}
ActionDropdown.propTypes = {
  handleSubmit: PropTypes.func,
  handleClose: PropTypes.func,
  suggestingResult: PropTypes.object,
  invalid: PropTypes.bool,
  action: PropTypes.any,
  bulkUpdate: PropTypes.func,
  classes: PropTypes.object,
  dispatch: PropTypes.any,
  selectedIds: PropTypes.array,
  selectExport: PropTypes.func,
  exporter: PropTypes.func
};
const mapDispatchToProps = dispatch => {
  return {
    bulkUpdate: (ids, sellState, basepath) => dispatch(bulkUpdate(ids, sellState, basepath, dispatch)),
    selectExport: (filter, basePath, exporter) => dispatch(selectExport(filter, basePath, exporter, dispatch))
  };
};
const mapStateToProps = state => {
  return {
    action: formValueSelector('action-dropdown')(state, 'action')
  };
};
export default compose(
  withStyles(styles),
  reduxForm({
    form: 'action-dropdown'
  })
)(connect(mapStateToProps, mapDispatchToProps)(ActionDropdown));
