import { GET_ONE, GET_LIST } from 'react-admin';
import _ from 'lodash';
import moment from 'moment';
import { DATE_FORMAT } from 'helpers/Const';
export default async (axiosInstance, type, params) => {
  switch (type) {
    case GET_ONE: {
      return axiosInstance.post('/functions/getTransactionById', { transactionId: params.id }).then(response => {
        const data = _.get(response, 'data.result', {});
        _.set(data, 'id', data.objectId);
        return Promise.resolve({ data });
      });
    }

    case GET_LIST: {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const { startDate, endDate } = params.filter;
      if (startDate) {
        params.filter.startDate = moment(startDate, DATE_FORMAT.DIV);
      }
      if (endDate) {
        params.filter.endDate = moment(endDate, DATE_FORMAT.DIV);
      }
      let data = {
        ...params.filter,
        page: page,
        limit: perPage,
        orderBy: field,
        order
      };
      return axiosInstance.post('/functions/getListTransactionByUser', data).then(response => {
        const data = _.get(response, 'data.result.data', []);
        const total = _.get(response, 'data.result.count', 0);
        data.forEach(item => {
          item.id = item.objectId;
        });
        return Promise.resolve({
          data,
          total
        });
      });
    }
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }
};
