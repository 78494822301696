import React from 'react';
import _ from 'lodash';
import { ShowController, ShowView, SimpleShowLayout, SimpleForm } from 'react-admin';
import { Grid } from '@material-ui/core';
import ImagesField from 'containers/Merchandise/MerchandiseDetail/components/ImagesField';
import { SELLSTATE } from 'helpers/Const';
import CardActions from '@material-ui/core/CardActions';
import DeleteButton from '../../../components/DeleteButtonWithConfirmation/index';
import PropTypes from 'prop-types';
import { TextFieldCustom } from './components/TextFieldCustom';
import { DateFieldCustom } from './components/DateFieldCustom';
import { TextLongFieldCustom } from './components/TextLongFieldCustom';
import { LABEL } from '../../../helpers/Const';
import EditButtonCustom from './components/EditButtonCustom';
import Typography from '@material-ui/core/Typography';
import './styles.scss';
import SellStateField from 'components/StatusField/SellState';
import moment from 'moment';

const ExpirationOfAdjustmentField = props => {
  const expirationOfAdjustment = _.get(props, 'record.expirationOfAdjustment');
  let value;
  //set minimum expectation for date value to be greater than unix epoch by at least 3000,
  //under these value the unix epoch close to 0 cause errors
  if (moment.unix(expirationOfAdjustment).isValid() && expirationOfAdjustment > 3000) {
    value = moment.unix(expirationOfAdjustment).format('YYYY/MM');
  } else if (!expirationOfAdjustment) {
    value = '';
  } else {
    value = expirationOfAdjustment;
  }
  return <div className={`AdjustmentField`}>{value}</div>;
};

const PriceField = props => {
  let priceField = _.get(props, `record.${props.source}`);
  if (!priceField) {
    return <div className={`AdjustmentField`}></div>;
  }
  priceField = new Intl.NumberFormat({ style: 'currency' }).format(priceField);
  return <div className={`AdjustmentField`}>{priceField}</div>;
};

PriceField.propTypes = {
  source: PropTypes.object
};

const cardActionStyle = {
  zIndex: 2,
  float: 'right',
  backgroundColor: '#f5f6fa',
  width: '120%',
  marginTop: '-16px',
  marginRight: '-27px'
};
const layout = {
  margin: '0px -24px -16px -24px'
};
const ShowActions = ({ basePath, data, resource }) => {
  const sellState = _.get(data, 'sellState');
  const validSellState = [SELLSTATE.SUSPENDED, SELLSTATE.SELLING];
  const invalidDeleteStates = [SELLSTATE.PROCESSING, SELLSTATE.COMPLETED, SELLSTATE.NEGOTIATE];
  return (
    <CardActions style={cardActionStyle}>
      <span className={`title-header`}>商品詳細</span>
      <DeleteButton
        undoable={false}
        disabled={invalidDeleteStates.indexOf(sellState) > -1}
        basePath={basePath}
        record={data}
        resource={resource}
      />
      <EditButtonCustom basePath={basePath} record={data} disabled={validSellState.indexOf(sellState) < 0} />
    </CardActions>
  );
};

ShowActions.propTypes = {
  basePath: PropTypes.any,
  data: PropTypes.any,
  resource: PropTypes.any
};

class MerchandiseDetail extends React.Component {
  componentWillMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <ShowController {...this.props}>
        {controllerProps => {
          return (
            <ShowView actions={<ShowActions />} {...this.props} {...controllerProps}>
              <SimpleForm style={layout} toolbar={null}>
                <Grid container className={`layout layout-fullwidth`}>
                  <Grid className={`block-field`} container spacing={16}>
                    <Grid className={`first-field`} item xs>
                      <SimpleShowLayout {...controllerProps}>
                        <TextFieldCustom source="id" label={LABEL.ID} />
                      </SimpleShowLayout>
                    </Grid>
                    <Grid className={`first-field`} item xs>
                      <SimpleShowLayout {...controllerProps}>
                        <DateFieldCustom source="createdAt" label="登録日" />
                      </SimpleShowLayout>
                    </Grid>
                    <Grid className={`first-field`} item xs>
                      <SimpleShowLayout {...controllerProps}>
                        <TextFieldCustom source="createdUser.email" label="登録者" />
                      </SimpleShowLayout>
                    </Grid>
                    <Grid item xs>
                      <SimpleShowLayout {...controllerProps}>
                        <label className={`title-status`}>ステータス</label>
                        <SellStateField source="sellState" />
                      </SimpleShowLayout>
                    </Grid>
                  </Grid>

                  <Grid className={`block-field`} container spacing={32}>
                    <Grid className={`title-container`} container xs={12}>
                      <Typography className={`title`} variant="h4" gutterBottom>
                        機器情報
                      </Typography>
                    </Grid>
                    <Grid xs={6}>
                      <SimpleShowLayout {...controllerProps}>
                        <TextFieldCustom source="holdingCategory" label={LABEL.HOLDING_CATEGORY} />
                        <TextFieldCustom source="assetNumber" label={LABEL.CONTROL_NUMBER} />
                        <TextFieldCustom source="name" label={LABEL.NAME} />
                        <TextFieldCustom source="serialNumber" label={LABEL.SERIAL_NUMBER} />
                      </SimpleShowLayout>
                    </Grid>
                    <Grid xs={6}>
                      <SimpleShowLayout {...controllerProps}>
                        <TextFieldCustom label={LABEL.MAIN_CATEGORY} source="category.parent.name" />
                        <TextFieldCustom label={LABEL.SUB_CATEGORY} source="category.name" />
                        <TextFieldCustom source="manufacturer.name" label={LABEL.MAKER} />
                        <TextFieldCustom source="modelNumber" label={LABEL.MAKER_MODEL_NUMBER} />
                      </SimpleShowLayout>
                    </Grid>
                  </Grid>

                  <Grid className={`block-field`} container spacing={32}>
                    <Grid className={`title-container`} xs={12} container>
                      <Typography className={`title`} variant="h4" gutterBottom>
                        管理情報
                      </Typography>
                    </Grid>
                    <Grid xs={6}>
                      <SimpleShowLayout {...controllerProps}>
                        <TextFieldCustom source="officer" label={LABEL.OFFICER} />
                      </SimpleShowLayout>
                    </Grid>
                    <Grid xs={6}>
                      <SimpleShowLayout {...controllerProps}>
                        <TextFieldCustom source="storageLocation" label={LABEL.STORAGE_LOCATION} />
                        <TextFieldCustom source="storageAndAvailableArea" label={LABEL.STORAGE_AND_AVAILABLE_AREA} />
                      </SimpleShowLayout>
                    </Grid>
                  </Grid>

                  <Grid className={`block-field`} container spacing={32}>
                    <Grid className={`title-container`} xs={12} container>
                      <Typography className={`title`} variant="h4" gutterBottom>
                        校正状況
                      </Typography>
                    </Grid>
                    <Grid xs={6}>
                      <SimpleShowLayout {...controllerProps}>
                        <TextFieldCustom source="targetOfCalibration" label={LABEL.TARGET_OF_CALIBRATION} />
                        <TextFieldCustom source="calibrationCycle" label={LABEL.CALIBRATION_CYCLE} />
                        <TextFieldCustom source="calibrationAppliedDate" label={LABEL.CALIBRATION_APPLIED_DATE} />
                        <TextFieldCustom source="calibrationDate" label={LABEL.CALIBRATION_DATE} />
                      </SimpleShowLayout>
                    </Grid>
                    <Grid xs={6}>
                      <SimpleShowLayout {...controllerProps}>
                        <TextFieldCustom source="calibrationResult" label={LABEL.CALIBRATION_RESULT} />
                      </SimpleShowLayout>
                    </Grid>
                  </Grid>

                  <Grid className={`block-field`} container spacing={32}>
                    <Grid className={`title-container`} xs={12} container>
                      <Typography className={`title`} variant="h4" gutterBottom>
                        簿価情報
                      </Typography>
                    </Grid>
                    <Grid xs={6}>
                      <SimpleShowLayout {...controllerProps}>
                        <TextFieldCustom source="fixedAssets" label={LABEL.FIXED_ASSETS} />
                        <label className={`title-field`}>{LABEL.ORIGINAL_PRICE}</label>
                        <PriceField source="originalPrice" />
                        <TextFieldCustom source="serviceLife" label={LABEL.SERVICE_LIFE} />
                        <label className={`title-field`}>{LABEL.REAL_MARKET_VALUE}</label>
                        <span className={`label-value`}>
                          耐用年数・購入価格・購入日を入力すると定額法に基づく簿価が自動で算出されます{' '}
                        </span>
                        <PriceField source="realMarketValue" />
                      </SimpleShowLayout>
                    </Grid>
                    <Grid xs={6}>
                      <SimpleShowLayout {...controllerProps}>
                        <TextFieldCustom source="purchasedDate" label={LABEL.PURCHASED_DATE} />
                      </SimpleShowLayout>
                    </Grid>
                  </Grid>

                  <Grid className={`block-field`} container spacing={32}>
                    <Grid className={`title-container`} xs={12} container>
                      <Typography className={`title`} variant="h4" gutterBottom>
                        商品画像
                      </Typography>
                    </Grid>
                    <Grid xs={6}>
                      <SimpleShowLayout {...controllerProps}>
                        <label className={`title-field`}>{LABEL.MERCHANDISE_IMAGE}</label>
                        <span className={`label-image`}> 一番目の画像が商品一覧に表示さます </span>
                        <ImagesField source="images" addLabel={false} />
                      </SimpleShowLayout>
                    </Grid>
                  </Grid>

                  <Grid className={`block-field`} container spacing={32}>
                    <Grid className={`title-container`} xs={12} container>
                      <Typography className={`title`} variant="h4" gutterBottom>
                        出品に必要な情報
                      </Typography>
                    </Grid>
                    <Grid xs={6}>
                      <SimpleShowLayout {...controllerProps}>
                        <TextFieldCustom source="statusOfAdjustment" label={LABEL.STATUS_OF_ADJUSTMENT} />
                        <label className={`title-field`}>{LABEL.EXPIRATION_OF_ADJUSTMENT}</label>
                        <ExpirationOfAdjustmentField addLabel={false} />
                        <TextLongFieldCustom source="description" label={LABEL.DESCRIPTION} />
                      </SimpleShowLayout>
                    </Grid>
                    <Grid xs={6}>
                      <SimpleShowLayout {...controllerProps}>
                        <TextFieldCustom source="year" label={LABEL.YEAR} />
                        <TextLongFieldCustom source="usaged" label={LABEL.USAGED} />
                        <TextFieldCustom source="delivery" label={LABEL.DELIVERY} />
                        <label className={`title-field`}>{LABEL.PRICE}</label>
                        <PriceField source="price" />
                      </SimpleShowLayout>
                    </Grid>
                  </Grid>
                </Grid>
              </SimpleForm>
            </ShowView>
          );
        }}
      </ShowController>
    );
  }
}

export default MerchandiseDetail;
