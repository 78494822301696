import React from 'react';
import { Admin, Resource } from 'react-admin';
import authProvider from 'providers/authProvider';
import dataProvider from 'providers/dataProvider';
import CustomLayout from 'CustomLayout';
import Login from 'containers/Login';
import saga from './saga';
import reducer from './reducer';
import { RESOURCES } from './helpers/Const';
import MerchandiseList from 'containers/Merchandise/MerchandiseList';
import MerchandiseDetail from 'containers/Merchandise/MerchandiseDetail';
import EditMerchandise from 'containers/Merchandise/MerchandiseEdit';
import { createMuiTheme } from '@material-ui/core/styles';
import MerchandiseCreate from './containers/Merchandise/MerchandiseCreate';
import { MerchandiseLogo, TransactionLogo, ManagementLogo, ForSaleLogo } from 'components/MenuIcon';
import TransactionList from 'containers/Transaction/TransactionList';
import Languages from 'helpers/Languages';
import STYLE from 'styles/_constants.scss';
import MerchandiseErrorList from 'containers/Merchandise/MerchandiseErrorList';
import AdminCreate from 'containers/Admin/AdminCreate';
import AdminList from 'containers/Admin/AdminList';
import customRoutes from './customRoutes';
import { createBrowserHistory as createHistory } from 'history';
const history = createHistory();

const theme = createMuiTheme({
  palette: {
    primary: { main: '#202a6b', contrastText: '#ffffff' },
    secondary: { main: '#8fc31f', contrastText: '#ffffff' },
    error: { main: '#9b1d20', contrastText: '#ffffff' }
  },
  overrides: {
    MuiTableSortLabel: {
      root: {
        minWidth: '96px',
        fontWeight: '700',
        fontSize: '14px'
      },
      icon: {
        marginLeft: '0xp',
        marginRight: '2px'
      }
    },
    MuiPaper: {
      root: {
        backgroundColor: '#f5f6fa'
      },
      elevation0: {
        width: '320px !important',
        margin: '0 !important',
        '@media (max-width: 1024px)': {
          width: '240px !important'
        }
      },
      elevation1: {
        boxShadow: 'none'
      },
      elevation4: {
        boxShadow: 'none'
      }
    },
    MuiIconButton: {
      colorInherit: {
        color: 'rgba(0, 0, 0, 0.54)'
      }
    },
    MuiDrawer: {
      docked: {
        backgroundColor: '#202a6b',
        height: 'calc(100vh - 96px)',
        top: '96px',
        position: 'sticky'
      }
    },
    MuiList: {
      root: {
        backgroundColor: 'white'
      }
    },
    MuiCardContent: {
      root: {
        backgroundColor: 'white'
      }
    },
    MuiTable: {
      root: {
        overflow: 'scroll',
        display: 'table'
      }
    },
    MuiToolbar: {
      dense: {
        height: '92px',
        borderBottom: '4px solid #8fc31f;'
      }
    },

    MuiButton: {
      disabled: {
        opacity: '0.5',
        color: 'white !important'
      }
    },
    MuiTypography: {
      body1: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    },
    MuiTableCell: {
      head: {
        zIndex: 3,
        fontWeight: 700
      }
    },
    MuiTablePagination: {
      caption: {
        lineHeight: '18px',
        fontSize: '12px',
        color: '#3c3c3c',
        fontFamily: STYLE.fontFamily
      },
      select: {
        paddingRight: '32px'
      }
    },
    MuiSvgIcon: {
      root: {
        right: '8px !important'
      }
    }
  }
});

const messages = Languages;
const i18nProvider = locale => messages[locale];

class App extends React.Component {
  render() {
    return (
      <Admin
        customRoutes={customRoutes}
        locale="ja"
        i18nProvider={i18nProvider}
        appLayout={CustomLayout}
        authProvider={authProvider}
        dataProvider={dataProvider}
        loginPage={Login}
        customSagas={saga}
        customReducers={reducer}
        theme={theme}
        history={history}
      >
        <Resource
          options={{ label: RESOURCES.MERCHANDISE.label }}
          icon={MerchandiseLogo}
          name={RESOURCES.MERCHANDISE.name}
          list={MerchandiseList}
          create={MerchandiseCreate}
          show={MerchandiseDetail}
          edit={EditMerchandise}
        />
        <Resource
          options={{ label: RESOURCES.MERCHANDISE_FOR_SALE.label }}
          icon={ForSaleLogo}
          name={RESOURCES.MERCHANDISE_FOR_SALE.name}
          list={MerchandiseList}
          create={MerchandiseCreate}
          show={MerchandiseDetail}
          edit={EditMerchandise}
        />
        <Resource
          options={{ label: RESOURCES.TRANSACTION.label }}
          icon={TransactionLogo}
          name={RESOURCES.TRANSACTION.name}
          list={TransactionList}
        />
        <Resource
          options={{ label: RESOURCES.ADMIN.label }}
          icon={ManagementLogo}
          name={RESOURCES.ADMIN.name}
          list={AdminList}
          create={AdminCreate}
        />
        <Resource name={RESOURCES.MERCHANDISE_ERROR.name} list={MerchandiseErrorList} />
        <Resource name={RESOURCES.CATEGORY.name} />
        <Resource name={RESOURCES.USER.name} />
        <Resource name={RESOURCES.COMMENT.name} />
      </Admin>
    );
  }
}

export default App;
