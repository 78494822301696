import axios from 'axios';
import { RESOURCES } from 'helpers/Const';
import authProvider from './authProvider';
import merchandiseProvider from 'providers/merchandiseProvider';
import categoryProvider from 'providers/categoryProvider';
import manufacturer from 'providers/manufacturerProvider';
import { AUTH_LOGOUT } from 'react-admin';
import { getAuthToken } from '../helpers/Auth';
import userProvider from 'providers/userProvider';
import transactionProvider from 'providers/transactionProvider';
import commentProvider from 'providers/commentProvider';
import adminProvider from 'providers/adminProvider';
import _ from 'lodash';
const apiUrl = process.env.REACT_APP_APP_SERVER;

function providerCall(axiosInstance, resource, type, params) {
  switch (resource) {
    case 'admin':
      return adminProvider(axiosInstance, type, params);
    case RESOURCES.MERCHANDISE.name:
    case RESOURCES.MERCHANDISE_FOR_SALE.name:
    case RESOURCES.MERCHANDISE_ERROR.name:
      return merchandiseProvider(axiosInstance, type, params, resource);
    case RESOURCES.CATEGORY.name:
      return categoryProvider(axiosInstance, type, params);
    case RESOURCES.MANUFACTURER.name:
      return manufacturer(axiosInstance, type, params);
    case RESOURCES.USER.name:
      return userProvider(axiosInstance, type, params);
    case RESOURCES.TRANSACTION.name:
      return transactionProvider(axiosInstance, type, params);
    case RESOURCES.COMMENT.name:
      return commentProvider(axiosInstance, type, params);
    default:
      return Promise.reject(new Error(`Unsupported Data Provider request resource ${resource}`));
  }
}
export default (type, resource, params) => {
  const token = getAuthToken();
  const headers = {
    Accept: 'application/json',
    'X-Parse-Application-Id': process.env.REACT_APP_PARSE_APP_ID,
    'X-Parse-REST-API-Key': process.env.REACT_APP_PARSE_API_KEY
  };
  if (token) {
    headers['X-Parse-Session-Token'] = token;
  }
  const axiosInstance = axios.create({
    baseURL: apiUrl,
    headers
  });
  return providerCall(axiosInstance, resource, type, params).catch(error => {
    const statusCode = _.get(error, 'response.code') || _.get(error, 'response.data.code');
    //case invalid token
    if (statusCode === 209) {
      authProvider(AUTH_LOGOUT, params);
      window.location.replace('/'); //I used it because there's no way to redirect from the provider
    }
    const payload = _.get(error, 'payload');
    if (payload) {
      return Promise.reject({ payload });
    }
    return Promise.reject({
      message: _.get(error, 'response.error') || _.get(error, 'response.data.error'),
      status: statusCode
    });
  });
};
