import { takeLatest, put } from 'redux-saga/effects';
import { GET_PENDING_MEMBERS, SEND_ADMIN_INVITE, DELETE_PEDNING_ADMIN } from './constants';
import {
  getPendingMembersError,
  getPendingMembersSuccess,
  submitAdminInviteSuccess,
  submitAdminInviteError,
  deletePendingAdminSuccess
} from './actions';
import dataProvider from 'providers/dataProvider';
import { setPopup } from 'containers/Popup/actions';

export function* getPendingMembers() {
  try {
    const result = yield dataProvider(GET_PENDING_MEMBERS, 'admin');
    yield put(getPendingMembersSuccess(result.pendingAdmin));
  } catch (err) {
    yield put(getPendingMembersError(err));
  }
}
export function* sendAdminInvite(action) {
  try {
    const result = yield dataProvider(SEND_ADMIN_INVITE, 'admin', action.data);
    const pendingAdmin = result.pendingAdmin;
    yield put(submitAdminInviteSuccess(pendingAdmin));
    yield put(
      setPopup('confirmPopup', {
        content: '登録のメールアドレスへのメールをお送りいたしました'
      })
    );
  } catch (err) {
    yield put(submitAdminInviteError(err));
    yield put(
      setPopup('confirmPopup', {
        content: 'このアドレスは既に登録されています',
        type: 'warning'
      })
    );
  }
}

export function* deletePendingAdmin(action) {
  try {
    const result = yield dataProvider(DELETE_PEDNING_ADMIN, 'admin', action.data);
    const { pendingAdmin = [] } = result;
    yield put(deletePendingAdminSuccess(pendingAdmin));
    yield put(setPopup('deletePopup', false));
  } catch (err) {
    yield put(getPendingMembersError(err));
  }
}
export default function* saga() {
  yield takeLatest(GET_PENDING_MEMBERS, getPendingMembers);
  yield takeLatest(DELETE_PEDNING_ADMIN, deletePendingAdmin);
  yield takeLatest(SEND_ADMIN_INVITE, sendAdminInvite);
}
