import React from 'react';
import PropTypes from 'prop-types';
import { formatDate } from 'helpers/Utils';

const DateFormatField = ({ source, record = {}, dateFormat, className }) => (
  <span className={className}>{record[source] ? formatDate(record[source], dateFormat) : ''}</span>
);

DateFormatField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  dateFormat: PropTypes.string.isRequired
};

DateFormatField.defaultProps = {
  addLabel: true
};

export default DateFormatField;
