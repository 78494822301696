import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from 'react-admin';
import { ERROR_MESSAGE } from 'helpers/Const';
import axios from 'axios';

import { setAuthToken, getAuthToken, removeAuthToken } from '../helpers/Auth';
export default (type, params) => {
  const apiUrl = process.env.REACT_APP_APP_SERVER;
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    return axios({
      method: 'post',
      url: `${apiUrl}/functions/logInEAM`,
      data: {
        username,
        password
      },
      headers: {
        Accept: 'application/json',
        'X-Parse-Application-Id': process.env.REACT_APP_PARSE_APP_ID,
        'X-Parse-REST-API-Key': process.env.REACT_APP_PARSE_API_KEY
      },
      responseType: 'json'
    })
      .then(res => {
        setAuthToken(res.data.result.sessionToken);
        return Promise.resolve();
      })
      .catch(error => {
        return Promise.reject({ message: ERROR_MESSAGE.FAILED_AUTH, status: error.response.data.code });
      });
  }
  // called when the user clicks on the logout button
  if (type === AUTH_LOGOUT) {
    removeAuthToken();
    return Promise.resolve({ redirectTo: '/login' });
  }
  // called when the API returns an error
  if (type === AUTH_ERROR) {
    const { status } = params;
    if (status === 401 || status === 403) {
      removeAuthToken();
      return Promise.reject();
    }
    return Promise.resolve();
  }
  // called when the user navigates to a new location
  if (type === AUTH_CHECK) {
    return getAuthToken() ? Promise.resolve() : Promise.reject();
  }

  return Promise.reject('Unknown method');
};
