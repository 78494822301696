import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormControl, FormHelperText, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { addField } from 'react-admin';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import STYLE from 'styles/_constants.scss';

const styles = theme => ({
  container: {
    marginTop: 24,
    marginBottom: 8
  },
  bootstrapRoot: {
    padding: 0,
    'label + &': {
      marginTop: theme.spacing.unit * 2
    }
  },
  bootstrapInput: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 12px',
    width: 'calc(100% - 24px)',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: `${STYLE.fontFamily}`,
    '&:focus': {
      borderColor: '#6bc5e8'
    }
  },
  bootstrapInputError: {
    borderColor: '#9b1d20'
  },
  bootstrapFormLabel: {
    fontSize: '14px',
    color: '#3c3c3c',
    marginBottom: '8px',
    fontFamily: `${STYLE.fontFamily}`
  },
  require: {
    color: '#9b1d20'
  }
});

class LongTextField extends React.Component {
  render() {
    const { classes, label, required } = this.props;
    let touched = _.get(this.props, 'meta.touched');
    const error = _.get(this.props, 'meta.error[0]');
    return (
      <FormControl fullWidth className={classes.container}>
        <div className={classes.bootstrapFormLabel}>
          {label}
          {required && <span className={classes.require}> *</span>}
        </div>
        <TextField
          id="outlined-bare"
          multiline
          rows="4"
          rowsMax="100"
          placeholder={label}
          InputProps={{
            disableUnderline: true,
            classes: {
              root: classes.bootstrapRoot,
              input: `${classes.bootstrapInput} ${error && touched && classes.bootstrapInputError}`
            }
          }}
          error={!!(touched && error)}
          {...this.props.input}
        />
        {!!(touched && error) && (
          <FormHelperText id="helper-text" error>
            {touched && error}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
}

LongTextField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  classes: PropTypes.any,
  type: PropTypes.any,
  label: PropTypes.any,
  placeholder: PropTypes.any,
  required: PropTypes.bool,
  sellState: PropTypes.string
};

const mapStateToProps = state => ({ sellState: _.get(state, 'form.record-form.values.sellState') });

export default addField(compose(connect(mapStateToProps), withStyles(styles))(LongTextField));
