import React, { Component } from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import InProgress from 'assets/in-progress.svg';
import PropsTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

const styles = () => ({
  progressCircle: {
    width: '168px',
    marginBottom: '24px'
  }
});
class ProgressCircle extends Component {
  render() {
    const { percentage = 0, pathTransitionDuration = 2, className, classes = {} } = this.props;
    return (
      <div className={`${classes.progressCircle} ${className}`}>
        <CircularProgressbarWithChildren
          value={percentage}
          strokeWidth="8"
          styles={buildStyles({
            width: 168,
            rotation: 0,
            strokeLinecap: 'butt',
            pathTransitionDuration: pathTransitionDuration,
            pathColor: '#202a6a',
            trailColor: 'dddddd',
            backgroundColor: '#3e98c7'
          })}
        >
          <img alt="progress-icon" src={InProgress} />
        </CircularProgressbarWithChildren>
      </div>
    );
  }
}

ProgressCircle.propTypes = {
  percentage: PropsTypes.number,
  pathTransitionDuration: PropsTypes.number,
  className: PropsTypes.string,
  classes: PropsTypes.object
};
ProgressCircle.propTypes = {
  setPopup: PropsTypes.func,
  type: PropsTypes.string
};
export default withStyles(styles)(ProgressCircle);
