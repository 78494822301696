import React from 'react';
import PropTypes from 'prop-types';
import currencyFormat from 'helpers/Currency';
import moment from 'moment';
import _ from 'lodash';
import { ACTIONS, DATE_FORMAT, RESOURCES, LABEL } from 'helpers/Const';
import CommentField from 'components/CommentField/index';
import { crudGetOne, crudGetList } from 'react-admin';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import DefaultAva from '../../../../assets//empty-profile-image.svg';

class PostShow extends React.Component {
  state = {
    data: {},
    comments: [],
    isViewAll: false
  };
  componentDidUpdate() {
    if (!_.get(this.props, 'data.seller')) {
      this.fetchTransactionData(this.props.id);
    }
  }
  componentDidMount() {
    this.fetchTransactionData(this.props.id);
    this.fetchComments(this.props.id);
  }

  fetchTransactionData = async transactionId => {
    this.props.crudGetOne(RESOURCES.TRANSACTION.name, transactionId, '/', false);
  };

  fetchComments = async transactionId => {
    this.props.crudGetOne(RESOURCES.COMMENT.name, transactionId, '/', false);
  };

  toggleViewOption = () => {
    this.setState(prevState => {
      return { isViewAll: !prevState.isViewAll };
    });
  };

  render() {
    const { data = {} } = this.props;
    const comments = _.get(this.props, 'comment.data', []);
    const lastStatusUpdateTime = _.get(data, 'lastStatusUpdateTime.iso');
    let paymentdate;
    let actionLog = data.actionLog;
    if (actionLog) {
      const logArr = Object.keys(actionLog);
      paymentdate = logArr.find(key => typeof actionLog[key] === 'string' && actionLog[key].includes(ACTIONS.PAYMENT));
    }
    if (!_.get(this.props, 'data.seller')) {
      return <div>Loading...</div>;
    }
    return (
      <div>
        <div className="card_container">
          {/* Column 1 */}
          <div className="card_info_wrapper">
            <div className="card_header">
              <p className="card_title">注文内容</p>
            </div>
            <div className="card_info">
              <div className="image_merch">
                <img src={data.merchImg} alt="card" />
              </div>
              <div className="card_info_detail">
                <p className="name_merch">{_.get(data, 'merchandiseName', '')}</p>
                <div className="card_sub_info">
                  <div className="card_left_info">
                    <p>
                      {LABEL.LOG_ID_TITLE}: &nbsp; {_.get(data, 'logId', '') && `#${_.get(data, 'logId', '')}`}
                    </p>
                    <p>
                      {LABEL.STATUS}:&nbsp; {_.get(data, 'status', '')}
                    </p>
                    <p>
                      {LABEL.TRANSACTION_PRICE}: &nbsp;
                      <span className="name_merch">{currencyFormat.format(data.price)}</span>
                    </p>
                  </div>
                  <div className="card_right_info">
                    <p>
                      {LABEL.TRADING_DATE}:&nbsp; {moment(_.get(data, 'createdAt', '')).format(DATE_FORMAT.DIV)}
                    </p>
                    <p>
                      {LABEL.PAYMENT_DATE}:&nbsp;{' '}
                      {paymentdate && moment(paymentdate, DATE_FORMAT.FULL).format(DATE_FORMAT.DIV)}
                    </p>
                    <p>
                      {LABEL.UPDATE_DATE}:&nbsp;{' '}
                      {lastStatusUpdateTime && moment(lastStatusUpdateTime).format(DATE_FORMAT.DIV)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Divider ruler */}
          <div className="step__divider" />
          {/* Column 2 */}
          <div className="card_info_wrapper">
            <div className="card_header">
              <p className="card_title">購入者情報</p>
            </div>
            <div className="card_info">
              <div className="image-avatar">
                <img src={DefaultAva} alt="default-avatar" />
              </div>
              <div className="card_info_detail">
                <div className="card_sub_col_info">
                  <p className="name_merch">
                    {_.get(data, 'buyerCompany', '')} {`•`} {_.get(data, 'buyerName', '')}
                  </p>
                  <p>
                    {LABEL.SHIPPING_ADDRESS}: &nbsp; {_.get(data, 'shippingAddress', '')}
                  </p>
                  <p>
                    {LABEL.PHONE_NUMBER}: &nbsp;{_.get(data, 'buyerPhone', '')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {comments.length > 0 && (
          <CommentField toggleViewOption={this.toggleViewOption} isViewAll={this.state.isViewAll} comments={comments} />
        )}
      </div>
    );
  }
}

PostShow.propTypes = {
  classes: PropTypes.object,
  id: PropTypes.string,
  data: PropTypes.object,
  comment: PropTypes.object,
  crudGetOne: PropTypes.func
};

const mapStateToProps = (state, props) => {
  const id = props.id;

  return {
    data: state.admin.resources[RESOURCES.TRANSACTION.name]
      ? state.admin.resources[RESOURCES.TRANSACTION.name].data[id]
      : null,
    comment: state.admin.resources[RESOURCES.COMMENT.name]
      ? state.admin.resources[RESOURCES.COMMENT.name].data[id]
      : null
  };
};
export default compose(connect(mapStateToProps, { crudGetOne, crudGetList }))(PostShow);
