import {
  GET_PARENT_CATEGORIES_REQUEST,
  GET_PARENT_CATEGORIES_SUCCESS,
  GET_PARENT_CATEGORIES_ERROR,
  GET_SUB_CATEGORIES_REQUEST,
  GET_SUB_CATEGORIES_SUCCESS,
  GET_SUB_CATEGORIES_ERROR,
  GET_MANUFACTURERS_REQUEST,
  GET_MANUFACTURERS_SUCCESS,
  GET_MANUFACTURERS_ERROR,
  TAKE_IMPORT_FILE,
  SET_IMPORT_FILE_SUCCESS,
  SET_IMPORT_FILE_ERROR,
  REMOVE_IMPORT_FILE,
  GET_PREFECTURES_SUCCESS
} from './constants';
import produce from 'immer';

const initialState = {
  parentCategories: [],
  subCategories: [],
  manufacturers: [],
  importFile: null,
  error: null,
  prefectures: []
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case GET_PARENT_CATEGORIES_REQUEST:
      state.parentCategories = [];
      state.subCategories = [];
      state.manufacturers = [];
      return;
    case GET_PARENT_CATEGORIES_SUCCESS:
      state.parentCategories = action.data;
      return;
    case GET_PARENT_CATEGORIES_ERROR:
      state.error = action.error;
      return;
    case GET_SUB_CATEGORIES_REQUEST:
      state.subCategories = [];
      state.manufacturers = [];
      return;
    case GET_SUB_CATEGORIES_SUCCESS:
      state.subCategories = action.data;
      return;
    case GET_SUB_CATEGORIES_ERROR:
      state.error = action.error;
      return;
    case GET_MANUFACTURERS_REQUEST:
      state.manufacturers = [];
      return;
    case GET_MANUFACTURERS_SUCCESS:
      state.manufacturers = action.data;
      return;
    case GET_MANUFACTURERS_ERROR:
      state.error = action.error;
      return;
    case TAKE_IMPORT_FILE:
      state.importFile = action.data;
      return;
    case SET_IMPORT_FILE_SUCCESS:
      state.importFile = null;
      return;
    case SET_IMPORT_FILE_ERROR:
      state.error = action.error;
      return;
    case REMOVE_IMPORT_FILE:
      state.importFile = null;
      return;
    case GET_PREFECTURES_SUCCESS:
      state.prefectures = action.payload;
      return;
    default:
      return state;
  }
});

export default reducer;
