import React from 'react';
import { Redirect } from 'react-router-dom';
import { getAuthToken } from '../../helpers/Auth';
import { RESOURCES } from '../../helpers/Const';

const CustomRedirectRoute = () => {
  if (getAuthToken()) {
    return <Redirect to={`/${RESOURCES.MERCHANDISE.name}`} />;
  }
  return <Redirect to="/login" />;
};

export default CustomRedirectRoute;
