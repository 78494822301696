import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormControl, FormHelperText, Input, InputAdornment } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import MaskedInput from 'react-text-mask';
import { addField } from 'react-admin';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import STYLE from 'styles/_constants.scss';
const { DATE_FIELD_TYPES } = require('../../../helpers/Const');

const styles = theme => ({
  container: {
    marginTop: 24,
    marginBottom: 8
  },
  bootstrapInput: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '4px 12px',
    lineHeight: '25px',
    width: 'calc(100% - 24px)',
    transition: theme.transitions.create('border-color'),
    fontFamily: `${STYLE.fontFamily}`
  },
  bootstrapInputError: {
    borderColor: '#9b1d20'
  },
  bootstrapInputFocus: {
    borderColor: '#6bc5e8'
  },
  bootstrapFormLabel: {
    fontSize: '14px',
    color: '#3c3c3c',
    fontFamily: `${STYLE.fontFamily}`,
    marginBottom: '8px'
  },
  icon: {
    width: '24px',
    height: '24px'
  },
  require: {
    color: '#9b1d20'
  },
  errorIcon: {
    width: '16px',
    height: '16px',
    position: 'relative',
    left: '-4px',
    top: '3px'
  },
  errorMessage: {
    marginLeft: '4px'
  }
});

const YY_Mask = props => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/]}
      placeholder="YY"
    />
  );
};

YY_Mask.propTypes = {
  inputRef: PropTypes.func.isRequired
};

const YYYY_Mask = props => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, /\d/]}
      placeholder="YYYY"
    />
  );
};

YYYY_Mask.propTypes = {
  inputRef: PropTypes.func.isRequired
};

const YYYY_MM_Mask = props => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, /\d/, '/', /\d/, /\d/]}
      placeholder="YYYY/MM"
    />
  );
};

YYYY_MM_Mask.propTypes = {
  inputRef: PropTypes.func.isRequired
};

const YYYY_MM_DD_Mask = props => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/]}
      placeholder="YYYY/MM/DD"
    />
  );
};

YYYY_MM_DD_Mask.propTypes = {
  inputRef: PropTypes.func.isRequired
};

class CustomDateField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      textMask: '__'
    };
  }

  componentDidMount() {
    switch (this.props.type) {
      case DATE_FIELD_TYPES.YY:
        this.setState({ textMask: '__' });
        break;
      case DATE_FIELD_TYPES.YYYY:
        this.setState({ textMask: '____' });
        break;
      case DATE_FIELD_TYPES.YYYY_MM:
        this.setState({ textMask: '____/__' });
        break;
      case DATE_FIELD_TYPES.YYYY_MM_DD:
        this.setState({ textMask: '____/__/__' });
        break;
      default:
        this.setState({ textMask: '____ / __' });
    }
  }

  render() {
    const { classes, type, label, required, customForm = false } = this.props;
    let inputMask = null;
    const touched = _.get(this.props, 'meta.touched');
    const error = customForm ? _.get(this.props, 'meta.error') : _.get(this.props, 'meta.error[0]');

    switch (type) {
      case DATE_FIELD_TYPES.YYYY_MM:
        inputMask = YYYY_MM_Mask;
        break;
      case DATE_FIELD_TYPES.YY:
        inputMask = YY_Mask;
        break;
      case DATE_FIELD_TYPES.YYYY:
        inputMask = YYYY_Mask;
        break;
      case DATE_FIELD_TYPES.YYYY_MM_DD:
        inputMask = YYYY_MM_DD_Mask;
        break;
      default:
        inputMask = YYYY_MM_Mask;
    }
    return (
      <FormControl fullWidth className={classes.container}>
        <div className={classes.bootstrapFormLabel}>
          {label}
          {required && <span className={classes.require}> *</span>}
        </div>
        <Input
          fullWidth
          name="textMask"
          id="calibration-cycle-field"
          aria-describedby="helper-text"
          inputComponent={inputMask}
          disableUnderline
          endAdornment={
            <InputAdornment position="end">
              <img alt="date" src="/img/icon-Calendar.svg" className={classes.icon} />
            </InputAdornment>
          }
          classes={{
            root: classes.bootstrapInput,
            error: classes.bootstrapInputError,
            focused: classes.bootstrapInputFocus
          }}
          {...this.props.input}
          error={!!(touched && error)}
        />
        {!!(touched && error) && (
          <FormHelperText id="helper-text" error>
            <span className={classes.errorMessage}>
              <ErrorIcon className={classes.errorIcon} />
              {touched && error}
            </span>
          </FormHelperText>
        )}
      </FormControl>
    );
  }
}

CustomDateField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  classes: PropTypes.any,
  type: PropTypes.any,
  label: PropTypes.any,
  required: PropTypes.bool,
  sellState: PropTypes.string,
  customForm: PropTypes.bool
};

const mapStateToProps = state => ({ sellState: _.get(state, 'form.record-form.values.sellState') });

export default addField(compose(connect(mapStateToProps), withStyles(styles))(CustomDateField));
