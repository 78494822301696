export const setAuthToken = value => {
  localStorage.setItem('token', value);
};

export const getAuthToken = () => {
  return localStorage.getItem('token');
};

export const removeAuthToken = () => {
  localStorage.removeItem('token');
};
