import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { CreateButton } from 'react-admin';
import Button from '@material-ui/core/Button';
import AttachIcon from 'assets/attach-circle.svg';
import CreateIcon from 'assets/create.svg';
import { withStyles } from '@material-ui/core';
import { LABEL } from 'helpers/Const';
import STYLE from 'styles/_constants.scss';
import { setPopup } from 'containers/Popup/actions';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import DeletePopup from 'components/DeletePopup';
import DropdownBulkAction from 'components/StatusUpdateDropdown';
import ConfirmPopup from 'components/ConfirmPopup';
import DeleteIcon from 'assets/red-delete.svg';
import { DELETE_MESSAGE } from 'helpers/Const';
import ImportPopup from 'containers/Merchandise/MerchandiseImport';
import DeleteReviewIcon from 'assets/reviewing.svg';
import { bulkDelete } from 'containers/Merchandise/MerchandiseList/actions';
import _ from 'lodash';
const Attach = () => <img alt="attach" src={AttachIcon} />;
const Create = () => <img alt="create" src={CreateIcon} />;
const styles = () => ({
  btnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '24px',
    fontFamily: `${STYLE.fontFamily}`
  },
  rightBtn: {
    display: 'flex'
  },
  leftBtn: {
    display: 'flex',
    alignItems: 'center'
  },
  numberText: {
    fontSize: '14px',
    lineHeight: '20px',
    color: '#202a6a',
    width: '58px',
    marginRight: '8px'
  },
  deleteBtn: {
    width: '160px',
    marginLeft: '16px',
    border: 'solid 1px #9b1d20',
    backgroundColor: '#ffffff',
    color: '#9b1d20',
    '@media (max-width: 1077px)': {
      width: '145px'
    }
  },
  deleteText: {
    marginLeft: '8px'
  },
  btn: {
    height: '46px',
    borderRadius: '4px',
    padding: 0,
    fontFamily: `${STYLE.fontFamily}`,
    fontSize: '14px',
    lineHeight: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },
  exportBtn: {
    width: '224px',
    border: 'solid 1px #202a6a',
    backgroundColor: '#ffffff',
    '&:hover': {
      backgroundColor: '#ffffff'
    },
    '@media (max-width: 1077px)': {
      width: '145px'
    }
  },
  exportBtnText: {
    color: '#202a6b',
    marginLeft: '8px'
  },
  createBtn: {
    width: '224px',
    marginLeft: '16px',
    backgroundColor: '#202a6b',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#202a6b'
    },
    '@media (max-width: 1077px)': {
      width: '145px'
    }
  }
});

class PostActions extends React.Component {
  handleDeleteItems = () => {
    const { selectedIds = [] } = this.props;
    const { resource } = this.props;
    if (selectedIds.length > 0) {
      this.props.setPopup('deletePopup', {
        title: DELETE_MESSAGE.SUB_TITLE,
        content: `削除した商品は元に戻すことはできません
        ${selectedIds.length}件の商品を削除してもよろしいですか?
        続行する場合はチェックボックスをオンにしてください`,
        icon: <img alt="reviewIcon" src={DeleteReviewIcon} />,
        warningText: `${selectedIds.length}件の商品を削除する`,
        resource: resource,
        deleteCallback: () => this.bulkDelete(selectedIds)
      });
    }
  };
  bulkDelete = selectedIds => {
    const search = _.get(this.props, 'search');
    this.props.bulkDelete(selectedIds, '/merchandise', search);
  };
  openPopup = () => {
    this.props.setPopup('importCSV', true);
  };
  render() {
    const {
      bulkActions,
      basePath,
      displayedFilters,
      filters,
      filterValues,
      onUnselectItems,
      resource,
      selectedIds,
      showFilter,
      classes,
      exporter
    } = this.props;
    return (
      <Fragment>
        <ConfirmPopup />
        <DeletePopup />
        {bulkActions &&
          React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems
          })}
        {filters &&
          React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button'
          })}
        <div className={classes.btnContainer}>
          <div className={classes.leftBtn}>
            <div className={classes.numberText}>{`${selectedIds.length}件選択`}</div>
            <DropdownBulkAction selectedIds={selectedIds} exporter={exporter} />
            <div onClick={this.handleDeleteItems} className={`${classes.btn} ${classes.deleteBtn}`}>
              <img alt="bulkdelete" src={DeleteIcon} />
              <div className={classes.deleteText}>{DELETE_MESSAGE.DELETE}</div>
            </div>
          </div>
          <div className={classes.rightBtn}>
            <Button className={`${classes.exportBtn} ${classes.btn}`} onClick={this.openPopup}>
              <Attach />
              <div className={`${classes.exportBtnText}`}> {LABEL.EXPORT_BTN} </div>
            </Button>
            <CreateButton
              icon={<Create />}
              label={LABEL.CREATE_BTN}
              className={`${classes.createBtn} ${classes.btn}`}
              basePath={basePath}
            />
            <ImportPopup />
          </div>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    search: _.get(state, 'router.location.search')
  };
};
const mapDispatchToProps = dispatch => {
  return {
    bulkDelete: (ids, basepath) => dispatch(bulkDelete(ids, basepath, dispatch)),
    setPopup: (popupName, state) => dispatch(setPopup(popupName, state))
  };
};
PostActions.propTypes = {
  bulkActions: PropTypes.any,
  basePath: PropTypes.any,
  displayedFilters: PropTypes.any,
  filters: PropTypes.any,
  filterValues: PropTypes.any,
  onUnselectItems: PropTypes.any,
  resource: PropTypes.any,
  selectedIds: PropTypes.array,
  showFilter: PropTypes.any,
  total: PropTypes.any,
  classes: PropTypes.object,
  setPopup: PropTypes.func,
  bulkDelete: PropTypes.func,
  exporter: PropTypes.func
};
export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(PostActions);
