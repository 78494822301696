import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormControl, Checkbox, FormControlLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { addField } from 'react-admin';
const { SELLSTATE, MESSAGE } = require('../../../helpers/Const');

const styles = () => ({
  container: {
    marginTop: 8,
    marginBottom: 16
  },
  message: {
    color: '#9b1d20',
    fontSize: 14,
    '@media (max-width: 1024px)': {
      marginLeft: '0px'
    }
  }
});

class CheckboxField extends React.Component {
  state = {
    checked: false
  };

  componentDidMount() {
    const value = _.get(this.props, 'input.value');
    if (value === SELLSTATE.SELLING) {
      this.setState({
        checked: true
      });
    } else {
      this.props.input.onChange(SELLSTATE.SUSPENDED);
    }
  }

  handleChange = () => {
    this.setState(prevState => {
      const value = !prevState.checked ? SELLSTATE.SELLING : SELLSTATE.SUSPENDED;
      this.props.input.onChange(value);
      return { checked: !prevState.checked };
    });
  };

  render() {
    let { classes, label } = this.props;
    const { checked } = this.state;

    const value = _.get(this.props, 'input.value');
    if (!checked) {
      label = (
        <div className={classes.groupLabel}>
          <div>{label}</div>
          <div className={classes.message}>{MESSAGE.MARK_FOR_SALE}</div>
        </div>
      );
    }
    return (
      <FormControl fullWidth className={classes.container}>
        <FormControlLabel
          control={<Checkbox checked={checked} value={value} onChange={this.handleChange} />}
          label={label}
        />
      </FormControl>
    );
  }
}

CheckboxField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  classes: PropTypes.any,
  type: PropTypes.any,
  label: PropTypes.any,
  value: PropTypes.any
};

export default addField(withStyles(styles)(CheckboxField));
