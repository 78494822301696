import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { translate, crudDelete, startUndoable } from 'ra-core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Button } from 'react-admin';
import { DELETE_MESSAGE } from '../../helpers/Const';
import './styles.scss';
import IconDelete from '../../assets/delete.svg';
import IconError from '../../assets/icon-status-error.svg';
import BorderTextError from '../../assets/combined-shape.svg';
import STYLE from 'styles/_constants.scss';

const styles = () => ({
  deleteButtonDefault: {
    color: 'white',
    '&:hover': {
      backgroundColor: '#9b1d20'
    },
    marginRight: '20px',
    backgroundColor: '#9b1d20',
    width: '200px',
    height: '39px',
    '& span': {
      '& span': {
        fontFamily: `${STYLE.fontFamily}`
      }
    },
    '&.outSide': {
      '@media (max-width: 1240px)': {
        width: '140px'
      }
    }
  },

  cancelButton: {
    color: '#202a6a',
    marginRight: '20px',
    backgroundColor: '#ffffff',
    fontWeight: 'bold',
    width: '200px',
    height: '39px',
    borderRadius: '4px',
    border: 'solid 1px #202a6a',
    fontFamily: `${STYLE.fontFamily}`
  },
  IconError: {
    '&:hover': {
      cursor: 'pointer',
      '& ': {
        display: 'inline'
      }
    }
  },
  TextError: {
    visibility: 'hidden',
    fontSize: '12px',
    color: '#9b1d20',
    width: '240px',
    backgroundImage: `url(${BorderTextError})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    lineHeight: '36px',
    textAlign: 'center',
    paddingTop: '5px',
    marginTop: '-4px'
  },
  ErrorHidden: {
    display: 'none'
  },
  Error: {
    display: 'inline-flex',
    '&:hover': {
      cursor: 'pointer',
      '& div': {
        visibility: 'visible'
      }
    }
  },
  delete: {
    marginLeft: 'auto'
  }
});

class DeleteButtonWithConfirmation extends Component {
  state = {
    showDialog: false
  };

  handleClick = () => {
    this.setState({ showDialog: true });
  };

  handleCloseClick = () => {
    this.setState({ showDialog: false });
  };

  handleDelete = event => {
    event.preventDefault();
    this.setState({ showDialog: false });
    const { dispatchCrudDelete, startUndoable, resource, record, basePath, redirect, undoable } = this.props;
    if (undoable) {
      startUndoable(crudDelete(resource, record.id, record, basePath, redirect));
    } else {
      dispatchCrudDelete(resource, record.id, record, basePath, redirect);
    }
  };

  render() {
    const { showDialog } = this.state;
    const { label = '削除', classes = {}, className, disabled = false } = this.props;
    return (
      <Fragment>
        <div className={classes.delete}>
          <div className={disabled ? classes.Error : classes.ErrorHidden}>
            <div id="TextError" className={classes.TextError}>
              お取引開始後の商品は削除できません
            </div>
            <img alt="error" src={IconError} className={classes.IconError} />
          </div>

          <Button
            disabled={disabled}
            onClick={this.handleClick}
            label={label}
            className={classnames('ra-delete-button', `${classes.deleteButtonDefault} ${'outSide'}`, className)}
            key="button"
          >
            <img alt="delete" src={IconDelete} />
          </Button>
        </div>
        <Dialog
          className={`Delete-Field`}
          fullWidth
          open={showDialog}
          onClose={this.handleCloseClick}
          aria-label="Are you sure?"
        >
          <Button onClick={this.handleCloseClick} className={`Close_Dialog`}></Button>
          <DialogTitle className={`Delete_Title`}>{DELETE_MESSAGE.TITLE}</DialogTitle>
          <DialogContent>
            <div className={`Delete_Confirmed`}>{DELETE_MESSAGE.CONFIRMED}</div>
          </DialogContent>
          <DialogActions className={`Button-Field`}>
            <Button
              className={classes.cancelButton}
              label="キャンセルして戻る"
              onClick={this.handleCloseClick}
            ></Button>
            <Button
              onClick={this.handleDelete}
              label={label}
              className={classnames('ra-delete-button', classes.deleteButtonDefault, className)}
              key="button"
            ></Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

DeleteButtonWithConfirmation.propTypes = {
  disabled: PropTypes.bool,
  basePath: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  dispatchCrudDelete: PropTypes.func.isRequired,
  label: PropTypes.string,
  record: PropTypes.object,
  redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.func]),
  resource: PropTypes.string.isRequired,
  startUndoable: PropTypes.func,
  translate: PropTypes.func,
  undoable: PropTypes.bool
};

DeleteButtonWithConfirmation.defaultProps = {
  redirect: 'list',
  undoable: true
};

export default compose(
  connect(null, { startUndoable, dispatchCrudDelete: crudDelete }),
  translate,
  withStyles(styles)
)(DeleteButtonWithConfirmation);
