import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import ResendIcon from 'assets/resend.svg';
import STYLE from 'styles/_constants.scss';
import PropTypes from 'prop-types';
import { ADMIN_MANAGEMENT } from 'helpers/Const';
import { submitAdminInviteRequest } from 'containers/Admin/actions';
import { connect } from 'react-redux';
const styles = () => ({
  resendContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'fit-content',
    cursor: 'pointer'
  },
  resendText: {
    lineHeight: '24px',
    fontFamily: STYLE.fontFamily,
    fontSize: '16px',
    color: '#202a6b',
    marginLeft: '8px',
    '@media (max-width: 1400px)': {
      display: 'none'
    }
  },
  resendIcon: {
    width: '32px',
    height: '32px',
    minWidth: '32px',
    minHeigth: '32px'
  }
});

class ResendButton extends Component {
  handleAdminInviteRequest = () => {
    let { record = {} } = this.props;
    let userInfo = Object.assign({}, record, { resend: true });
    this.props.submitAdminInviteRequest(userInfo);
  };
  render() {
    const { classes = {}, className = '' } = this.props;
    return (
      <div onClick={this.handleAdminInviteRequest} className={`${classes.resendContainer} ${className}`}>
        <img className={classes.resendIcon} alt="resendIcon" src={ResendIcon} />
        <div className={classes.resendText}>{ADMIN_MANAGEMENT.RESEND_BTN}</div>
      </div>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    submitAdminInviteRequest: record => dispatch(submitAdminInviteRequest(record))
  };
};
ResendButton.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  record: PropTypes.object.isRequired
};
ResendButton.propTypes = {
  submitAdminInviteRequest: PropTypes.func
};
export default connect(null, mapDispatchToProps)(withStyles(styles)(ResendButton));
