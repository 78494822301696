import React, { Fragment } from 'react';
import { Toolbar, SaveButton } from 'react-admin';
import { withRouter } from 'react-router-dom';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { BUTTON_LABEL } from '../../helpers/Const';
import { connect } from 'react-redux';
import CheckBoxField from '../CustomFields/CheckBoxField';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import './style.scss';
import STYLE from 'styles/_constants.scss';
import _ from 'lodash';
import { push } from 'react-router-redux';
const styles = theme => ({
  gridContainer: {
    display: 'flex',
    padding: '0 48px 0 0 !important',
    alignSelf: 'center',
    justifyContent: 'flex-end'
  },
  spacing: {
    ...theme.mixins.toolbar
  },
  blockContainer: {
    height: '80px',
    justifyContent: 'space-between',
    backgroundColor: '#fff !important',
    position: 'fixed',
    left: '336px',
    paddingLeft: '1rem',
    bottom: 0,
    width: 'calc(100vw - 336px)',
    boxShadow: '0 -5px 10px 0 rgba(0, 0, 0, 0.05)',
    transition: theme.transitions.create(['left', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest
    }),
    '@media (max-width: 1024px)': {
      left: '257px',
      width: 'calc(100vw - 243px)'
    }
  },
  blockContainerShift: {
    width: 'calc(100vw - 72px)',
    left: 72,
    transition: theme.transitions.create(['left', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.short
    })
  },
  button: {
    width: '224px',
    height: '48px',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: STYLE.fontFamily
  },
  buttonCancel: {
    color: '#202a6a',
    marginRight: '16px',
    border: 'solid 1px #202a6a',
    backgroundColor: '#ffffff',
    minWidth: '150px'
  },
  buttonText: {},
  iconPaddingStyle: {
    marginRight: '0px'
  },
  toolbar: {
    marginTop: 0,
    padding: 0,
    margin: 0,
    justifyContent: 'flex-end'
  },
  checkboxText: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    fontFamily: STYLE.fontFamily,
    '@media (max-width:1024px)': {
      fontSize: '12px'
    }
  }
});

class PostCreateToolbar extends React.Component {
  handleCancel = () => {
    const { cancelRedirect, basePath = '/' } = this.props;
    if (cancelRedirect) {
      return this.props.push(cancelRedirect);
    }
    return this.props.push(basePath);
  };
  render() {
    const { classes, toggleState, saveBtnLabel = '', saveRedirect = '/' } = this.props;
    const toolbarProps = _.omit(this.props, [
      'dispatch',
      'staticContext',
      'toggleState',
      'classes',
      'saveRedirect',
      'saveBtnLabel',
      'push'
    ]);

    return (
      <Fragment>
        <div className={classes.spacing} />
        <Grid
          classes={{ container: `${classes.blockContainer} ${!toggleState && classes.blockContainerShift}` }}
          container
          spacing={32}
        >
          <Grid className={classes.gridContainer} item xs={6}>
            <CheckBoxField
              label={
                <Typography component="h3" className={classes.checkboxText} noWrap>
                  工作機械、測定器、精密機器の売買サイトEkuippに出品する
                </Typography>
              }
              source="sellState"
            />
          </Grid>
          <Grid className={classes.gridContainer} item xs={6}>
            <div onClick={this.handleCancel} className={`${classes.button} ${classes.buttonCancel}`}>
              {BUTTON_LABEL.CANCEL}
            </div>
            <Toolbar className="custom-toolbar" classes={{ toolbar: classes.toolbar }} {...toolbarProps}>
              <SaveButton
                redirect={saveRedirect}
                icon={<div></div>}
                label={saveBtnLabel}
                classes={{ button: classes.button }}
                submitOnEnter={true}
              />
            </Toolbar>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

PostCreateToolbar.propTypes = {
  classes: PropTypes.any,
  history: PropTypes.any,
  toggleState: PropTypes.bool,
  saveBtnLabel: PropTypes.string,
  redirect: PropTypes.string,
  push: PropTypes.func,
  basePath: PropTypes.string,
  cancelRedirect: PropTypes.string,
  saveRedirect: PropTypes.string
};

const mapStateToProps = state => ({ toggleState: state.admin.ui.sidebarOpen });
const mapDispatchToProps = dispatch => {
  return {
    push: path => dispatch(push(path))
  };
};
export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(withRouter(PostCreateToolbar));
