import React from 'react';
import { reduxForm, Field } from 'redux-form';
import PropTypes from 'prop-types';
import { withStyles, Button, Input } from '@material-ui/core';
import { ERROR_MESSAGE } from 'helpers/Const';
const style = {
  labelForm: {
    display: 'block',
    marginBottom: '8px',
    fontWeight: 500,
    fontSize: '14px'
  },
  inputForm: {
    display: 'block',
    width: '100%',
    height: '3em',
    padding: '12px',
    boxSizing: 'border-box'
  },
  fieldForm: {
    marginBottom: '27px',
    fontFamily: 'Noto Sans JP, sans-serif',
    color: '#3c3c3c'
  },
  inner: {
    boxSizing: 'border-box',
    width: '100%',
    borderRadius: 4,
    position: 'relative',
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 12px',
    '&:hover:before': {
      borderBottom: '1px solid transparent !important',
      transition: 'none',
      boxShawdow: 'none'
    },
    '&&&:before': {
      borderBottom: 'none'
    },
    '&&:after': {
      borderBottom: 'none'
    }
  },
  errorNotify: {
    color: '#9b1d20',
    marginTop: '8px'
  },
  submitButton: {
    fontFamily: 'Noto Sans JP, sans-serif',
    backgroundColor: '#202a6b',
    fontSize: '16px',
    height: '56px',
    fontWeight: 'bold',
    color: '#ffffff',
    marginTop: '14px',
    '&:hover': {
      backgroundColor: '#202a6b'
    }
  }
};

const validate = values => {
  const error = {};
  if (!values.username) {
    error.username = ERROR_MESSAGE.REQUIRED;
  }

  if (!values.password) {
    error.password = ERROR_MESSAGE.REQUIRED;
  }
  return error;
};

const renderField = ({ input, meta, label, classes, type, ...props }) => {
  return (
    <div className={classes.fieldForm}>
      <label className={classes.labelForm}>{label}</label>
      <Input className={classes.inner} type={type} placeholder={label} margin="dense" {...input} />
      {meta.error && meta.touched && (
        <div className={classes.errorNotify}>
          <i className="fas fa-exclamation-circle icon-error" /> {meta.error}
        </div>
      )}
    </div>
  );
};

renderField.propTypes = {
  meta: PropTypes.object,
  input: PropTypes.any,
  label: PropTypes.string,
  classes: PropTypes.any,
  type: PropTypes.string,
  autoFocus: PropTypes.any
};

let LoginForm = props => {
  const { handleSubmit, submitting, classes } = props;
  return (
    <form onSubmit={handleSubmit(props.login)}>
      <Field autoFocus type="text" classes={classes} name="username" label="メールアドレス" component={renderField} />
      <Field type="password" classes={classes} name="password" label="パスワード" component={renderField} />
      <Button type="submit" variant="contained" className={classes.submitButton} fullWidth disabled={submitting}>
        ログイン
      </Button>
    </form>
  );
};

LoginForm = reduxForm({
  form: 'login',
  destroyOnUnmount: false,
  validate
})(LoginForm);

LoginForm.propTypes = {
  classes: PropTypes.any,
  submitting: PropTypes.any,
  handleSubmit: PropTypes.any,
  login: PropTypes.func
};

export default withStyles(style)(LoginForm);
