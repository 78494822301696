import React, { Component, Fragment } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate } from './validate';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import _ from 'lodash';
import BorderTextField from 'components/CustomFields/BorderTextField';
import SelectField from 'components/CustomFields/SelectField';
import {
  SERVICE_YEARS,
  ADJUSTMENT,
  TIMESPAN,
  ACCESSORY,
  WARRANTY,
  VALIDATION,
  LABEL,
  SUGGESTED_RANGE_TEXT,
  BUTTON_LABEL
} from 'helpers/Const';
import './style.scss';
import { formatPrice } from 'helpers/Utils';
import PropTypes from 'prop-types';

class PriceSuggestionForm extends Component {
  render() {
    const { handleSubmit, handleClose, suggestingResult, invalid } = this.props;
    const result = _.get(suggestingResult, 'result');
    return (
      <form onSubmit={handleSubmit}>
        <div className="form-row-container">
          <div className="form-row">
            <Field
              required={true}
              label={LABEL.LIST_PRICE}
              className="custom-field"
              component={BorderTextField}
              name="price"
              customForm={true}
            />
            <Field
              className="custom-field"
              label={LABEL.SERVICE_YEARS}
              name="year"
              required={true}
              component={SelectField}
              customForm={true}
              choices={SERVICE_YEARS}
              placeholder={'使用年数'}
            />
            <Field
              className="custom-field"
              label={LABEL.WARRANTY}
              required={true}
              component={SelectField}
              customForm={true}
              choices={[
                { id: WARRANTY.WARRANTY_FALSE, name: WARRANTY.WARRANTY_FALSE },
                { id: WARRANTY.WARRANTY_TRUE, name: WARRANTY.WARRANTY_TRUE }
              ]}
              name="assurances"
            />
          </div>
          <div className="form-row">
            <Field
              className="custom-field"
              label={LABEL.CALIBRATION}
              component={SelectField}
              required={true}
              customForm={true}
              name="calibration"
              choices={[
                { id: ADJUSTMENT.CALIBRATED, name: ADJUSTMENT.CALIBRATED },
                { id: ADJUSTMENT.UNCALIBRATED, name: ADJUSTMENT.UNCALIBRATED },
                { id: ADJUSTMENT.INAPPLICABLE, name: ADJUSTMENT.INAPPLICABLE },
                { id: ADJUSTMENT.UNKNOWN, name: ADJUSTMENT.UNKNOWN }
              ]}
            />
            <Field
              className="custom-field mgl-24"
              label={LABEL.DELIVERY}
              component={SelectField}
              name="delivery"
              customForm={true}
              required={true}
              choices={[
                { id: TIMESPAN.EARLY, name: TIMESPAN.EARLY },
                { id: TIMESPAN.NORUSH, name: TIMESPAN.NORUSH },
                { id: TIMESPAN.LATE, name: TIMESPAN.LATE },
                { id: TIMESPAN.DELAYED, name: TIMESPAN.DELAYED }
              ]}
            />
            <Field
              className="custom-field mgl-24"
              label={LABEL.ACCESSORY}
              component={SelectField}
              required={true}
              customForm={true}
              name="accessory"
              choices={[
                { id: ACCESSORY.NO_ACCESS, name: ACCESSORY.NO_ACCESS },
                { id: ACCESSORY.YES_ACCESS, name: ACCESSORY.YES_ACCESS },
                { id: ACCESSORY.INAPPLICABLE, name: ACCESSORY.INAPPLICABLE }
              ]}
            />
          </div>
          <div className="form-row">
            <Field
              label={LABEL.VALIDATION}
              className="custom-field"
              component={SelectField}
              required={true}
              customForm={true}
              name="validation"
              choices={[
                { id: VALIDATION.VALIDATION_FALSE, name: VALIDATION.VALIDATION_FALSE },
                { id: VALIDATION.VALIDATION_TRUE, name: VALIDATION.VALIDATION_TRUE }
              ]}
            />
          </div>
        </div>
        {result && (
          <Fragment>
            <div className="suggesting-price-container">
              <div className="suggesting-price">{`¥${formatPrice(result)}`}</div>
              <div className="suggesting-range">
                <div className="suggesting-range-text">{SUGGESTED_RANGE_TEXT}</div>
                <div className="suggesting-range-content">
                  {`¥${formatPrice(_.get(suggestingResult, 'resultLowRange'))}`} {` - `}
                  {`¥${formatPrice(_.get(suggestingResult, 'resultUpRange'))}`}
                </div>
              </div>
            </div>
          </Fragment>
        )}
        <div className="btn-container">
          <div className="custom-btn back-btn" onClick={handleClose}>
            {BUTTON_LABEL.CANCEL}
          </div>
          <button disabled={invalid} className="custom-btn calc-btn" type="submit">
            {result ? BUTTON_LABEL.RETRY : BUTTON_LABEL.SUBMIT}
          </button>
        </div>
      </form>
    );
  }
}
PriceSuggestionForm.propTypes = {
  handleSubmit: PropTypes.func,
  handleClose: PropTypes.func,
  suggestingResult: PropTypes.object,
  invalid: PropTypes.bool
};
const mapStateToProps = state => {
  return {
    suggestingResult: formValueSelector('suggesting-price')(state, 'result', 'resultLowRange', 'resultUpRange')
  };
};
export default compose(
  reduxForm({
    form: 'suggesting-price',
    destroyOnUnmount: true,
    validate
  })
)(connect(mapStateToProps)(PriceSuggestionForm));
