import axios from 'axios';
import { UPLOADER_BASE_URL } from 'helpers/Const';

import { getAuthToken } from '../helpers/Auth';

export const getAxiosInstant = () => {
  const token = getAuthToken();
  const headers = {
    Accept: '*/*'
  };
  if (token) {
    headers['X-Parse-Session-Token'] = token;
  }
  const anxiosInstant = axios.create({
    baseURL: UPLOADER_BASE_URL,
    responseType: 'json',
    headers
  });
  anxiosInstant.defaults.params = {};
  return anxiosInstant;
};

const Uploader = {
  uploadImage: params => {
    return getAxiosInstant()
      .post('/upload/uploadImage', params)
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }
};

export default Uploader;
