import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { reduxForm, getFormValues } from 'redux-form';
import classnames from 'classnames';
import { withStyles, createStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import withProps from 'recompose/withProps';
import lodashSet from 'lodash/set';
import FilterFormInput from './FilterFormInput';
import STYLE from 'styles/_constants.scss';
import _ from 'lodash';
import { validate } from './validate';
const styles = theme =>
  createStyles({
    formSection: {
      marginTop: '32px',
      backgroundColor: '#eeeeee',
      fontFamily: `${STYLE.fontFamily}`
    },
    form: {
      alignItems: 'flex-end',
      flexWrap: 'wrap',
      padding: '24px 32px 24px 24px',
      position: 'relative'
    },
    title: {
      fontFamily: `${STYLE.fontFamilyTitle}`,
      fontSize: '40px',
      color: '#3c3c3c',
      lineHeight: '56px'
    },
    subTitle: {
      lineHeight: '30px',
      fontSize: '20px',
      fontWeight: 'bold',
      color: '#3c3c3c',
      width: 'fit-content'
    },
    body: { display: 'flex', alignItems: 'flex-end' },
    spacer: { width: '1em' },
    icon: { color: theme.palette.primary1Color || '#00bcd4', paddingBottom: 0 },
    clearFix: { clear: 'right' },
    submit: {
      position: 'absolute',
      bottom: 24,
      right: 32,
      width: '120px',
      height: '48px',
      padding: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: `${STYLE.fontFamily}`,
      fontSize: '16px'
    },
    filterContainer: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    defaultElem: {
      width: '100%'
    },
    filterTitle: {
      fontSize: '16px',
      fontWeight: 500,
      width: 'fit-content',
      lineHeight: '24px',
      marginBottom: '8px',
      color: '#3c3c3c'
    },
    field: {
      width: '100%'
    },
    text: {
      whiteSpace: 'pre-line',
      color: '#9b1d20',
      fontSize: '16px',
      fontFamily: `${STYLE.fontFamily}`,
      marginTop: '32px'
    }
  });

export class FilterForm extends Component {
  componentDidMount() {
    this.props.filters.forEach(filter => {
      if (filter.props.alwaysOn && filter.props.defaultValue) {
        throw new Error('Cannot use alwaysOn and defaultValue on a filter input.');
      }
    });
  }

  getShownFilters() {
    const { filters, displayedFilters, initialValues } = this.props;

    return filters.filter(
      filterElement =>
        filterElement.props.alwaysOn ||
        displayedFilters[filterElement.props.source] ||
        typeof initialValues[filterElement.props.source] !== 'undefined'
    );
  }

  handleHide = event => this.props.hideFilter(event.currentTarget.dataset.key);

  onKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  render() {
    const {
      classes = {},
      className,
      resource,
      setFilters,
      title,
      text,
      formClassName = '',
      invalid,
      filters = []
    } = this.props;
    return (
      <Fragment>
        <div className={classes.customFilter}>
          <div className={classes.title}>{title}</div>
          {text && <div className={classes.text}>{text}</div>}
          {filters.length > 0 && (
            <div className={classes.formSection}>
              <form className={classnames(className, classes.form, formClassName)} onKeyPress={this.onKeyPress}>
                <div className={classes.subTitle}>フィルター</div>
                <div className={classes.filterContainer}>
                  {this.getShownFilters().map((filterElement, index) => {
                    const containerclassname = _.get(filterElement, 'props.containerclassname', '');
                    return (
                      <div key={index} className={`${classes.defaultElem} ${classes.longElem} ${containerclassname}`}>
                        {filterElement.props.title && (
                          <div className={classes.filterTitle}>{filterElement.props.title}</div>
                        )}
                        <FilterFormInput
                          key={filterElement.props.source}
                          filterElement={filterElement}
                          handleHide={this.handleHide}
                          classes={classes}
                          resource={resource}
                        />
                      </div>
                    );
                  })}
                </div>
                <Button
                  disabled={invalid}
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={() => setFilters(this.props.formsvalues)}
                >
                  検索をする
                </Button>
                <div className={classes.clearFix} />
              </form>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

FilterForm.propTypes = {
  resource: PropTypes.string.isRequired,
  filters: PropTypes.arrayOf(PropTypes.node).isRequired,
  displayedFilters: PropTypes.object.isRequired,
  hideFilter: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  classes: PropTypes.object,
  className: PropTypes.string,
  formsvalues: PropTypes.object,
  setFilters: PropTypes.func,
  title: PropTypes.string,
  formClassName: PropTypes.string,
  invalid: PropTypes.bool,
  text: PropTypes.string
};

export const mergeInitialValuesWithDefaultValues = ({ initialValues, filters }) => ({
  initialValues: {
    ...filters
      .filter(filterElement => filterElement.props.alwaysOn && filterElement.props.defaultValue)
      .reduce(
        (acc, filterElement) => lodashSet({ ...acc }, filterElement.props.source, filterElement.props.defaultValue),
        {}
      ),
    ...initialValues
  }
});
const enhance = compose(
  withStyles(styles),
  withProps(mergeInitialValuesWithDefaultValues),
  connect(state => ({
    formsvalues: getFormValues('filterForm')(state)
  })),
  reduxForm({
    form: 'filterForm',
    validate,
    enableReinitialize: true,
    destroyOnUnmount: false // do not destroy to preserve state across navigation
  })
);

export default enhance(FilterForm);
