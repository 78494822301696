import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Panorama } from '@material-ui/icons';
const ImageField = ({ source, record = {}, classes }) => {
  const image = _.get(record, `${source}`);
  return (
    <div className={classes.imageField}>
      {!image ? <Panorama className={classes.icon} /> : <img alt="avatar" className={classes.image} src={image} />}
    </div>
  );
};

const styles = () => ({
  imageField: {
    display: 'flex',
    justifyContent: 'center'
  },
  image: {
    objectFit: 'scale-down',
    width: '160px',
    height: '80px',
    borderRadius: '4px'
  },
  icon: {
    fontSize: 30
  }
});
ImageField.propTypes = {
  classes: PropTypes.any,
  record: PropTypes.any,
  source: PropTypes.any
};
export default withStyles(styles)(ImageField);
