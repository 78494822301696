import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'components/CustomList';
import { withStyles } from '@material-ui/core/styles';
import { DATE_FORMAT, RESOURCES, LABEL } from 'helpers/Const';
import { CustomDatagrid } from 'components/CustomDatagrid';
import DateFormatField from 'components/DateFormatField';
import _ from 'lodash';
import SellStateField from 'components/StatusField/SellState';
import TextFieldForGrid from 'components/CustomFieldForGrid/TextField';
import PriceField from 'components/CustomFieldForGrid/PriceField';
import CategoryField from 'components/CustomFieldForGrid/CategoryField';
import UnixTimeField from 'components/CustomFieldForGrid/UnixTimeField';
import './style.scss';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import CustomFilter from 'components/CustomFilters';

const styles = {
  image: { maxHeight: '1rem' },
  card: {
    minWidth: 275
  },
  toolbar: {
    display: 'unset',
    padding: 0
  }
};

const MerchandiseErrorList = props => {
  const { classes } = props;
  const listProps = _.omit(props, ['dispatch', 'classes']);
  const gridProps = _.omit(listProps, ['hasShow', 'hasList', 'hasEdit']);
  const list = _.get(props, 'list');
  return (
    <List
      title="台帳"
      bulkActionButtons={false}
      filters={
        <CustomFilter
          formClassName="merchandise-search-form"
          text={`出品に必要な情報が不足しています
          ${list.length}件の商品を出品することができませんでした`}
          title={RESOURCES.MERCHANDISE_ERROR.label}
          resource={RESOURCES.MERCHANDISE.name}
        />
      }
      sort={{ field: 'createdAt', order: 'DESC' }}
      classes={{ card: classes.card, toolbar: classes.toolbar }}
      {...listProps}
    >
      <CustomDatagrid fixedColNumber={1} colWidth={155} {...gridProps} rowClick="show">
        <TextFieldForGrid className="cell-content id-text-field" source="id" label={LABEL.ID} />
        <TextFieldForGrid className="cell-content" source="holdingCategory" label={LABEL.HOLDING_CATEGORY} />
        <TextFieldForGrid className="cell-content" source="assetNumber" label={LABEL.CONTROL_NUMBER} />
        <TextFieldForGrid className="cell-content" source="name" label={LABEL.NAME} />
        <CategoryField className="cell-content" source="category.parent" sortable={false} label={LABEL.MAIN_CATEGORY} />
        <CategoryField className="cell-content" source="category" label={LABEL.SUB_CATEGORY} />
        <TextFieldForGrid className="cell-content" source="manufacturer.name" sortable={false} label={LABEL.MAKER} />
        <TextFieldForGrid className="cell-content" source="modelNumber" label={LABEL.MAKER_MODEL_NUMBER} />
        <TextFieldForGrid className="cell-content" source="serialNumber" label={LABEL.SERIAL_NUMBER} />
        <TextFieldForGrid className="cell-content" source="officer" label={LABEL.OFFICER} />
        <TextFieldForGrid
          className="cell-content"
          source="storageAndAvailableArea"
          label={LABEL.STORAGE_AND_AVAILABLE_AREA}
        />
        <TextFieldForGrid className="cell-content" source="targetOfCalibration" label={LABEL.TARGET_OF_CALIBRATION} />
        <TextFieldForGrid className="cell-content" source="calibrationCycle" label={LABEL.CALIBRATION_CYCLE} />
        <TextFieldForGrid
          className="cell-content"
          source="calibrationAppliedDate"
          label={LABEL.CALIBRATION_APPLIED_DATE}
        />
        <TextFieldForGrid className="cell-content" source="calibrationDate" label={LABEL.CALIBRATION_DATE} />
        <TextFieldForGrid className="cell-content" source="calibrationResult" label={LABEL.CALIBRATION_RESULT} />
        <TextFieldForGrid className="cell-content" source="fixedAssets" label={LABEL.FIXED_ASSETS} />
        <TextFieldForGrid className="cell-content" source="purchasedDate" label={LABEL.PURCHASED_DATE} />
        <PriceField className="cell-content" source="originalPrice" label={LABEL.ORIGINAL_PRICE} />
        <TextFieldForGrid className="cell-content" source="serviceLife" label={LABEL.SERVICE_LIFE} />
        <TextFieldForGrid className="cell-content" source="realMarketValue" label={LABEL.REAL_MARKET_VALUE} />
        <TextFieldForGrid className="cell-content" source="statusOfAdjustment" label={LABEL.STATUS_OF_ADJUSTMENT} />
        <UnixTimeField
          className="cell-content"
          source="expirationOfAdjustment"
          label={LABEL.EXPIRATION_OF_ADJUSTMENT}
        />
        <TextFieldForGrid className="cell-content" maxWidth={252} source="description" label={LABEL.DESCRIPTION} />
        <TextFieldForGrid className="cell-content" source="usaged" label={LABEL.USAGED} />
        <TextFieldForGrid className="cell-content" source="delivery" label={LABEL.DELIVERY} />
        <PriceField className="cell-content" source="price" label={LABEL.PRICE} />
        <SellStateField source="sellState" label={LABEL.STATUS} />
        <DateFormatField
          className="cell-content"
          source="createdAt"
          label={LABEL.CREATED_DATE}
          dateFormat={DATE_FORMAT.DIV}
        />
        <TextFieldForGrid className="cell-content" source="createdUserName" label={LABEL.CREATED_BY} />
      </CustomDatagrid>
    </List>
  );
};

MerchandiseErrorList.propTypes = {
  selectedIds: PropTypes.any,
  dispatch: PropTypes.any,
  classes: PropTypes.object
};
const mapStateToProps = state => {
  return {
    list: _.get(state, 'admin.resources.merchandiseError.list.ids', [])
  };
};
export default compose(connect(mapStateToProps), withStyles(styles))(MerchandiseErrorList);
