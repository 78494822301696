import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import SellStateIcon from '../../StatusField/SellState';
import './style.scss';

const CustomTextField = ({ source, record = {}, label }) => {
  const content = _.get(record, source);
  return (
    <div className="text-field">
      <div className="label-text-field">{label ? label : source.toUpperCase()}</div>
      {source === 'sellState' && (
        <div className="content-text-field">
          <SellStateIcon record={record} source={source} />
        </div>
      )}
      {source !== 'sellState' && (
        <div className="content-text-field">
          {source === 'id' && '#'}
          {content}
        </div>
      )}
    </div>
  );
};

CustomTextField.propTypes = {
  source: PropTypes.any,
  record: PropTypes.any,
  label: PropTypes.string
};

export default CustomTextField;
