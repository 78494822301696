import React from 'react';
import LogoColor from '../assets/logo-text.svg';
import PropTypes from 'prop-types';
import { PATH } from 'helpers/Const';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  logo: {
    marginLeft: '112px'
  }
};
export const Logo = withStyles(styles)(({ classes, ...props }) => (
  <a className={classes.logo} href={PATH.MERCHANDISE}>
    <img className={props.className} alt="logo" src={LogoColor} />
  </a>
));
Logo.propTypes = {
  className: PropTypes.string
};
