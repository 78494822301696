import React from 'react';
import _ from 'lodash';
const PriceField = ({ source, record = {}, maxWidth = 245, className = '' }) => {
  let value = _.get(record, `${source}`);
  if (value) {
    value = Number(value).toLocaleString();

    return (
      <div style={{ maxWidth: `${maxWidth}px` }} className={className}>
        {`¥${value}`}
      </div>
    );
  } else return null;
};
export default PriceField;
