export const GET_PENDING_MEMBERS = 'user/GET_PENDING_MEMBERS';
export const GET_PENDING_MEMBERS_SUCCESS = 'GET_PENDING_MEMBERS_SUCCESS';
export const GET_PENDING_MEMBERS_ERROR = 'GET_PENDING_MEMBERS_ERROR';

export const SEND_ADMIN_INVITE = 'SEND_ADMIN_INVITE';
export const SEND_ADMIN_INVITE_SUCCESS = 'SEND_ADMIN_INVITE_SUCCESS';
export const SEND_ADMIN_INVITE_ERROR = 'SEND_ADMIN_INVITE_ERROR';

export const DELETE_PEDNING_ADMIN = 'admin/DELETE_PEDNING_ADMIN';
export const DELETE_PEDNING_ADMIN_SUCCESS = 'admin/DELETE_PEDNING_ADMIN_SUCCESS';
export const DELETE_PEDNING_ADMIN_ERROR = 'admin/DELETE_PEDNING_ADMIN_ERROR';
