import React from 'react';
import PropTypes from 'prop-types';
import shouldUpdate from 'recompose/shouldUpdate';
import { Link } from 'react-router-dom';
import { linkToRecord } from 'ra-core';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import IconEdit from '../../../../assets/edit.svg';
import STYLE from 'styles/_constants.scss';

const styles = {
  button: {
    width: '200px',
    marginRight: '40px',
    color: 'white',
    backgroundColor: '#202a6b !important',
    '& span': {
      '& span': {
        fontFamily: `${STYLE.fontFamily}`
      }
    },
    '@media (max-width: 1240px)': {
      width: '140px'
    }
  },
  icon: {
    marginRight: '6px'
  }
};

const stopPropagation = e => e.stopPropagation();

const EditButtonCustom = withStyles(styles)(({ classes, className, basePath = '', record = {}, ...rest }) => (
  <Button
    className={clsx(classes.button, className)}
    fullWidth="true"
    variant="contained"
    component={Link}
    to={linkToRecord(basePath, record.id)}
    onClick={stopPropagation}
    {...rest}
  >
    <img alt="edit" className={classes.icon} src={IconEdit} />
    <span>編集</span>
  </Button>
));

EditButtonCustom.propTypes = {
  basePath: PropTypes.string,
  className: PropTypes.string,
  classes: PropTypes.object,
  label: PropTypes.string,
  record: PropTypes.object,
  icon: PropTypes.element
};

const enhance = shouldUpdate(
  (props, nextProps) =>
    props.translate !== nextProps.translate ||
    (props.record && nextProps.record && props.record.id !== nextProps.record.id) ||
    props.basePath !== nextProps.basePath ||
    (props.record == null && nextProps.record != null)
);

export default enhance(EditButtonCustom);
