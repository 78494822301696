import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Field, reduxForm } from 'redux-form';
import validate from './validate.js';
import TextField from 'components/CustomFields/BorderTextField';
import { BUTTON_LABEL, ADMIN_MANAGEMENT, LABEL } from 'helpers/Const';
class InvitationForm extends Component {
  render() {
    const { classes = {}, handleSubmit, className = '' } = this.props;
    return (
      <div className={`${classes.formContainer} ${className}`}>
        <div className={classes.formTitle}>{ADMIN_MANAGEMENT.INVITE_FORM_TITLE}</div>
        <hr className={classes.formDivider} />
        <form onSubmit={handleSubmit}>
          <Field label={LABEL.USER_NAME} name="adminName" component={TextField} required />
          <Field label={LABEL.EMAIL_ADDRESS} name="adminAddress" component={TextField} required />
          <Field label={LABEL.EMAIL_ADDRESS} name="adminAddressConfirm" component={TextField} required />
          <Field label={LABEL.AUTHORITY} name="role" component={TextField} required disabled />
          <div className={classes.btnContainer}>
            <Button href="/admin" className={`${classes.btn} ${classes.cancelBtn}`}>
              {BUTTON_LABEL.CANCEL}
            </Button>
            <Button type="submit" className={`${classes.btn} ${classes.okBtn} `}>
              {BUTTON_LABEL.INVITE_EMAIL}
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

InvitationForm.propTypes = {
  classes: PropTypes.object,
  handleSubmit: PropTypes.func,
  className: PropTypes.string
};
const styles = () => ({
  formContainer: {
    width: '464px',
    backgroundColor: '#ffffff',
    padding: '24px 48px',
    paddingBottom: '48px',
    fontFamily: '"Noto Sans JP", sans-serif',
    '@media (max-width: 1366px)': {
      width: 'calc(100% - 96px)'
    }
  },
  formTitle: {
    fontWeight: 'bold',
    fontSize: '20px'
  },
  formDivider: {
    height: '4px',
    backgroundColor: '#00a3df',
    border: 'none',
    marginTop: '24px',
    marginBottom: '0px'
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '40px'
  },
  btn: {
    width: '224px',
    height: '48px',
    borderRadius: '4px',
    fontFamily: '"Noto Sans JP", sans-serif'
  },
  cancelBtn: {
    border: 'solid 1px #202a6a',
    backgroundColor: '#ffffff'
  },
  okBtn: {
    marginLeft: '16px',
    backgroundColor: '#202a6b',
    color: '#ffffff',
    '@media (max-width: 1024px)': {
      marginLeft: '0px'
    }
  }
});
export default reduxForm({
  form: 'invitationForm',
  destroyOnUnmount: true,
  validate,
  enableReinitialize: true
})(withStyles(styles)(InvitationForm));
