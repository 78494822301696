import React, { Component } from 'react';
import _ from 'lodash';
import DeleteIcon from 'assets/red-delete.svg';
import { DELETE_MESSAGE, ADMIN_MANAGEMENT } from 'helpers/Const';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import STYLE from 'styles/_constants.scss';
import { crudDeleteMany } from 'react-admin';
import { setPopup } from 'containers/Popup/actions';
import DeleteAdminIcon from 'assets/adminreviewing.svg';
import { deletePendingAdmin } from 'containers/Admin/actions';
import PropTypes from 'prop-types';
const styles = () => ({
  btnContainer: {
    '@media (max-width: 1580px)': {
      marginLeft: '10px'
    }
  },
  deleteBtn: {
    color: '#9b1d20',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  deleteText: {
    marginLeft: '8px',
    lineHeight: '24px',
    fontFamily: STYLE.$fontFamily,
    fontSize: '16px',
    color: '#9b1d20',
    '@media (max-width: 1400px)': {
      display: 'none'
    }
  },
  deleteIcon: {
    width: '32px',
    height: '32px',
    minWidth: '32px',
    minHeigth: '32px'
  }
});
class DeleteAdminBtn extends Component {
  handleDeleteItems = () => {
    const { crudDeleteMany } = this.props;
    const record = _.get(this.props, 'record');
    const objectId = _.get(this.props, 'record.objectId');
    const name = _.get(this.props, 'record.representative');
    const email = _.get(this.props, 'record.email');
    const basePath = _.get(this.props, 'basePath');
    const popupData = {
      title: ADMIN_MANAGEMENT.DELETE_TITLE,
      content: ADMIN_MANAGEMENT.DELETE_WARNING,
      warningText: `${name ? name : email}を削除する`,
      icon: <img alt="adminIcon" src={DeleteAdminIcon} />,
      hasCheck: false,
      resource: 'admin',
      selectedIds: [objectId],
      deleteCallback: () => {
        crudDeleteMany('admin', [objectId], basePath, true);
        this.props.setPopup('deletePopup', false);
      }
    };
    if (objectId) {
      this.props.setPopup('deletePopup', popupData);
    } else {
      popupData.warningText = `${record.adminName ? record.adminName : record.adminAddress}を削除する`;
      popupData.selectedIds = record;
      popupData.deleteCallback = () => {
        this.props.deletePendingAdmin(record);
        this.props.setPopup('deletePopup', false);
      };
      this.props.setPopup('deletePopup', popupData);
    }
  };
  render() {
    const { classes = {}, className } = this.props;
    return (
      <div classes={classes.btnContainer}>
        <div onClick={this.handleDeleteItems} className={`${classes.deleteBtn} ${className}`}>
          <img className={classes.deleteIcon} alt="bulkdelete" src={DeleteIcon} />
          <div className={classes.deleteText}>{DELETE_MESSAGE.DELETE}</div>
        </div>
      </div>
    );
  }
}

DeleteAdminBtn.propTypes = {
  crudDeleteMany: PropTypes.func,
  deletePendingAdmin: PropTypes.func,
  classes: PropTypes.object,
  className: PropTypes.string,
  setPopup: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    setPopup: (popupName, state) => dispatch(setPopup(popupName, state)),
    crudDeleteMany: (resource, ids, basePath, refresh) => dispatch(crudDeleteMany(resource, ids, basePath, refresh)),
    deletePendingAdmin: data => dispatch(deletePendingAdmin(data))
  };
};
export default withStyles(styles)(connect(null, mapDispatchToProps)(DeleteAdminBtn));
