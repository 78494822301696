import React, { Children, cloneElement, isValidElement } from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import UserMenuAction from 'assets/down.svg';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { crudGetOne } from 'react-admin';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getAuthToken } from 'helpers/Auth';
import STYLE from 'styles/_constants.scss';

const DropDownIcon = () => {
  return <img alt="downIcon" src={UserMenuAction}></img>;
};
const styles = () => ({
  userMenu: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '24px',
    marginRight: '111px'
  },
  userName: {
    fontFamily: `${STYLE.fontFamily}`,
    fontSize: '16px',
    fontWeight: 500,
    color: '#3c3c3c',
    marginRight: '4px'
  },
  icon: {
    height: '16px',
    cursor: 'pointer'
  },
  menuList: {
    marginTop: '50px'
  }
});
class UserMenu extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    logout: PropTypes.node,
    icon: PropTypes.node,
    classes: PropTypes.object,
    crudGetOne: PropTypes.func
  };

  static defaultProps = {
    label: 'ra.auth.user_menu',
    icon: <DropDownIcon />
  };

  state = {
    auth: true,
    anchorEl: null
  };

  handleChange = (event, checked) => {
    this.setState({ auth: checked });
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  componentDidMount() {
    if (getAuthToken()) {
      this.fetchUser();
    }
  }
  fetchUser = () => {
    this.props.crudGetOne('user', 'currentUser', '/', false);
  };
  render() {
    const userName = _.get(this.props, 'user.username');
    const { children, icon, logout, classes = {} } = this.props;
    if (!logout && !children) return null;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    return (
      <div className={classes.userMenu}>
        <Typography classes={{ root: classes.userName }}>{userName}</Typography>
        <div className={classes.icon} onClick={this.handleMenu}>
          {icon}
        </div>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          classes={{ paper: classes.menuList }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={open}
          onClose={this.handleClose}
        >
          {Children.map(children, menuItem =>
            isValidElement(menuItem) ? cloneElement(menuItem, { onClick: this.handleClose }) : null
          )}
          {logout}
        </Menu>
      </div>
    );
  }
}
UserMenu.propTypes = {};
const mapStateToProps = state => {
  const resource = 'user';
  const id = 'currentUser';

  return {
    user: state.admin.resources[resource] ? state.admin.resources[resource].data[id] : null
  };
};

export default compose(withStyles(styles), connect(mapStateToProps, { crudGetOne }))(UserMenu);
