export const GET_PARENT_CATEGORIES_REQUEST = 'merchandise/GET_PARENT_CATEGORIES_REQUEST';
export const GET_PARENT_CATEGORIES_SUCCESS = 'merchandise/GET_PARENT_CATEGORIES_SUCCESS';
export const GET_PARENT_CATEGORIES_ERROR = 'merchandise/GET_PARENT_CATEGORIES_ERROR';

export const GET_SUB_CATEGORIES_REQUEST = 'merchandise/GET_SUB_CATEGORIES_REQUEST';
export const GET_SUB_CATEGORIES_SUCCESS = 'merchandise/GET_SUB_CATEGORIES_SUCCESS';
export const GET_SUB_CATEGORIES_ERROR = 'merchandise/GET_SUB_CATEGORIES_ERROR';

export const GET_MANUFACTURERS_REQUEST = 'merchandise/GET_MANUFACTURERS_REQUEST';
export const GET_MANUFACTURERS_SUCCESS = 'merchandise/GET_MANUFACTURERS_SUCCESS';
export const GET_MANUFACTURERS_ERROR = 'merchandise/GET_MANUFACTURERS_ERROR';

export const SET_IMPORT_FILE = 'merchandise/SET_IMPORT_FILE';
export const SET_IMPORT_FILE_SUCCESS = 'merchandise/SET_IMPORT_FILE_SUCCESS';
export const SET_IMPORT_FILE_ERROR = 'merchandise/SET_IMPORT_FILE_ERROR';
export const REMOVE_IMPORT_FILE = 'merchandise/REMOVE_IMPORT_FILE';

export const TAKE_IMPORT_FILE = 'merchandise/TAKE_IMPORT_FILE';

export const GET_PREFECTURES_REQUEST = 'merchandise/GET_PREFECTURES_REQUEST';
export const GET_PREFECTURES_SUCCESS = 'merchandise/GET_PREFECTURES_SUCCESS';
export const GET_PREFECTURES_ERROR = 'merchandise/GET_PREFECTURES_ERROR';
