import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { change } from 'redux-form';
import { EditController, Edit, SimpleForm, FormDataConsumer, REDUX_FORM_NAME } from 'react-admin';
import { Grid, Typography, Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import validate from 'containers/Merchandise/MerchandiseEdit/validate';
import {
  getParentCategoriesRequest,
  getSubCategoriesRequest,
  getManufacturersRequest,
  getPrefecturesRequest
} from 'containers/Merchandise/actions';
import utils from 'containers/Merchandise/MerchandiseEdit/utils';
import { MANUFACTURER, LABEL, DATE_FIELD_TYPES, ADJUSTMENT } from 'helpers/Const';
import ImageUploader from 'containers/Merchandise/MerchandiseEdit/components/ImageUploader';

import BorderTextField from '../../../components/CustomFields/BorderTextField';
import SelectField from '../../../components/CustomFields/SelectField';
import CustomDateField from '../../../components/CustomFields/CustomDateField';
import LongTextField from '../../../components/CustomFields/LongTextField';

import './style.scss';
import EditCustomToolbar from '../../../components/CustomCreateToolbar';
import CustomTextField from '../../../components/CustomFields/CustomTextField';
import { formatPrice, normalizeAmount } from 'helpers/Utils';
import carryingValue from 'helpers/CarryingValueCalculation';
import STYLE from 'styles/_constants.scss';
import PriceSuggestionPoup from 'components/PriceSuggestionPopup/index';
import { setPopup } from 'containers/Popup/actions';

const {
  HOLDING_CATEGORY,
  TARGET_OF_CALIBRATION,
  CALIBRATION_RESULT,
  FIXED_ASSETS,
  TYPE
} = require('../MerchandiseCreate/constants');

const { SELLSTATE, DATE_FORMAT, BUTTON_LABEL } = require('../../../helpers/Const');

const styles = theme => ({
  gridContainer: {
    padding: '0 2.5rem !important'
  },
  gridTextField: {
    borderRight: '1px solid #ddd',
    height: 40
  },
  spacing: {
    ...theme.mixins.toolbar
  },
  blockContainer: {
    backgroundColor: '#fff',
    padding: '1.5rem 0'
  },
  headingSpacing: {
    paddingBottom: '1.5rem',
    fontFamily: `${STYLE.fontFamily}`,
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#3c3c3c'
  },
  dividerRoot: {
    backgroundColor: '#6bc5e8',
    height: '4px'
  },
  fieldLayout: {
    backgroundColor: '#f5f6fa'
  }
});

class MerchandiseEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      merchandiseDetail: {},
      parentCategoriesData: [],
      subCategoriesData: [],
      manufacturersData: []
    };
  }

  componentDidMount = () => {
    this.props.getPrefectures();
    this.props.getParentCategoriesRequest();
    const { merchandises, id } = this.props;

    //EditPage 's just available only for merchandise whose saleState is suspend or selling
    const sellState = _.get(merchandises[id], 'sellState');
    const validSellState = [SELLSTATE.SUSPENDED, SELLSTATE.SELLING];
    if (sellState && validSellState.indexOf(sellState) < 0) {
      this.props.history.replace('/merchandise');
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { merchandises, id, getSubCategoriesRequest, getManufacturersRequest } = nextProps;
    const { parentCategories, subCategories, manufacturers } = nextProps;

    let parentCategoriesData = [];
    let subCategoriesData = [];
    let manufacturersData = [];

    if (
      (!merchandises[id] || _.isEqual(merchandises[id], prevState.merchandiseDetail)) &&
      _.isEqual(parentCategories, prevState.parentCategories) &&
      _.isEqual(subCategories, prevState.subCategories) &&
      _.isEqual(manufacturers, prevState.manufacturers)
    ) {
      return null;
    }

    if (merchandises[id] && !_.isEqual(merchandises[id], prevState.merchandiseDetail)) {
      _.get(merchandises[id], 'category.parent.objectId') &&
        getSubCategoriesRequest({ parentId: _.get(merchandises[id], 'category.parent.objectId', '') });
      getManufacturersRequest({ categoryId: _.get(merchandises[id], 'categoryId', '') });

      return {
        merchandiseDetail: merchandises[id]
      };
    }

    if (!_.isEqual(manufacturers, prevState.manufacturers)) {
      manufacturersData =
        _.isArray(manufacturers) &&
        manufacturers.map(item => ({
          id: _.get(item, 'objectId'),
          name: _.get(item, 'name')
        }));
    }

    if (!_.isEqual(parentCategories, prevState.parentCategories)) {
      parentCategoriesData =
        _.isArray(parentCategories) &&
        parentCategories.map(item => ({
          id: _.get(item, 'objectId'),
          name: _.get(item, 'name')
        }));
    }

    if (!_.isEqual(subCategories, prevState.subCategories)) {
      subCategoriesData =
        _.isArray(subCategories) &&
        subCategories.map(item => ({
          id: _.get(item, 'objectId'),
          name: _.get(item, 'name')
        }));
    }

    return {
      parentCategoriesData,
      subCategoriesData,
      manufacturersData
    };
  }

  onFormChange = event => {
    const serviceLife = Number(_.get(event, 'serviceLife'));
    const originalPrice = Number(_.get(event, 'originalPrice'));
    const purchasedDate = moment(_.get(event, 'purchasedDate'), DATE_FORMAT.SIMPLE);
    const age = purchasedDate.isValid() ? moment().diff(purchasedDate, 'years') : NaN;
    if (!isNaN(serviceLife) && !isNaN(originalPrice) && !isNaN(age)) {
      event.realMarketValue = carryingValue(originalPrice, serviceLife, age);
    } else {
      event.realMarketValue = '';
    }
  };
  openPriceSuggestionPoup = () => {
    this.props.setPopup('priceSuggestionPoup', true);
  };

  render() {
    const { manufacturers, classes, sellState } = this.props;
    const { parentCategoriesData, subCategoriesData, manufacturersData } = this.state;
    const otherManufacturer = _.find(manufacturers, { name: MANUFACTURER.OTHER });
    const pathname = _.get(this.props, 'location.pathname');
    const required = sellState === SELLSTATE.SELLING ? true : false;
    return (
      <Fragment>
        <PriceSuggestionPoup />
        <EditController {...this.props}>
          {controllerProps => {
            return (
              <Edit {...this.props} {...controllerProps} actions={null} undoable={false}>
                <SimpleForm
                  class="simple-form-create"
                  validate={validate}
                  toolbar={
                    <EditCustomToolbar
                      cancelRedirect={`${pathname}/show`}
                      saveRedirect={`${pathname}/show`}
                      saveBtnLabel={BUTTON_LABEL.SAVE}
                    />
                  }
                  onChange={this.onFormChange}
                >
                  <FormDataConsumer>
                    {({ formData, dispatch, ...rest }) => (
                      <Fragment>
                        <header className="header">編集</header>
                        <Grid classes={{ container: classes.blockContainer }} container spacing={32}>
                          <Grid className={`${classes.gridContainer} ${classes.gridTextField}`} item xs={3}>
                            <CustomTextField source="id" {...rest} />
                          </Grid>
                          <Grid className={`${classes.gridContainer} ${classes.gridTextField}`} item xs={3}>
                            <CustomTextField source="createdAt" label={LABEL.CREATED_DATE} {...rest} />
                          </Grid>
                          <Grid className={`${classes.gridContainer} ${classes.gridTextField}`} item xs={3}>
                            <CustomTextField
                              source="createdUser.representative"
                              label={LABEL.REPRESENTATIVE}
                              {...rest}
                            />
                          </Grid>
                          <Grid className={classes.gridContainer} item xs={3}>
                            <CustomTextField source="sellState" label={LABEL.STATUS} {...rest} />
                          </Grid>
                        </Grid>
                        <div className={classes.spacing} />
                        <Grid classes={{ container: classes.blockContainer }} container spacing={32}>
                          <Grid className={classes.gridContainer} item xs={12}>
                            <Typography component="h2" variant="h2" className={classes.headingSpacing} noWrap>
                              機器情報
                            </Typography>
                            <Divider classes={{ root: classes.dividerRoot }} />
                          </Grid>
                          <Grid className={classes.gridContainer} item xs={6}>
                            <SelectField
                              source="holdingCategory"
                              choices={[
                                {
                                  id: HOLDING_CATEGORY.IN_HOUSE_PURCHASE,
                                  name: HOLDING_CATEGORY.IN_HOUSE_PURCHASE
                                },
                                { id: HOLDING_CATEGORY.RENTAL, name: HOLDING_CATEGORY.RENTAL },
                                { id: HOLDING_CATEGORY.LEASE, name: HOLDING_CATEGORY.LEASE }
                              ]}
                              fullWidth
                              label={LABEL.HOLDING_CATEGORY}
                            />
                            <BorderTextField
                              className="container"
                              source="assetNumber"
                              label={LABEL.CONTROL_NUMBER}
                              fullWidth
                            />
                            <BorderTextField source="name" label={LABEL.NAME} fullWidth required={required} />
                            <BorderTextField source="serialNumber" label={LABEL.SERIAL_NUMBER} fullWidth />
                          </Grid>
                          <Grid className={classes.gridContainer} item xs={6}>
                            <SelectField
                              required={required}
                              label={LABEL.MAIN_CATEGORY}
                              source="categoryParentId"
                              choices={parentCategoriesData}
                              fullWidth
                              onChange={(e, value) => {
                                dispatch(getSubCategoriesRequest({ parentId: value }));
                                dispatch(change(REDUX_FORM_NAME, 'categoryId', ''));
                              }}
                            />
                            <SelectField
                              required={required}
                              label={LABEL.SUB_CATEGORY}
                              source="categoryId"
                              choices={subCategoriesData}
                              onChange={(e, value) => {
                                dispatch(getManufacturersRequest({ categoryId: value }));
                                dispatch(change(REDUX_FORM_NAME, 'manufacturerId', ''));
                              }}
                              fullWidth登録画面
                            />
                            <SelectField
                              required={required}
                              source="manufacturerId"
                              label={LABEL.MAKER}
                              choices={manufacturersData}
                              fullWidth
                            />
                            {_.get(formData, 'manufacturerId') &&
                              _.get(formData, 'manufacturerId') === _.get(otherManufacturer, 'objectId') && ( // eslint-disable-line
                                <BorderTextField source="otherManufacturer" label={LABEL.OTHER_MAKER} fullWidth /> // eslint-disable-line
                              ) /* eslint-disable-line */}
                            <BorderTextField
                              source="modelNumber"
                              label={LABEL.MAKER_MODEL_NUMBER}
                              fullWidth
                              required={required}
                            />
                          </Grid>
                        </Grid>
                        <div className={classes.spacing} />
                        <Grid classes={{ container: classes.blockContainer }} container spacing={32}>
                          <Grid className={classes.gridContainer} item xs={12}>
                            <Typography component="h2" variant="h2" className={classes.headingSpacing} noWrap>
                              管理情報
                            </Typography>
                            <Divider classes={{ root: classes.dividerRoot }} />
                          </Grid>
                          <Grid className={classes.gridContainer} item xs={6}>
                            <BorderTextField source="officer" label={LABEL.OFFICER} fullWidth />
                          </Grid>
                          <Grid className={classes.gridContainer} item xs={6}>
                            <SelectField
                              source="storageLocation"
                              label={LABEL.STORAGE_LOCATION}
                              choices={this.props.prefectures}
                              fullWidth
                              required={true}
                            />
                            <BorderTextField
                              source="storageAndAvailableArea"
                              label={LABEL.STORAGE_AND_AVAILABLE_AREA}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                        <div className={classes.spacing} />
                        <Grid classes={{ container: classes.blockContainer }} container spacing={32}>
                          <Grid className={classes.gridContainer} item xs={12}>
                            <Typography component="h2" variant="h2" className={classes.headingSpacing} noWrap>
                              校正状況
                            </Typography>
                            <Divider classes={{ root: classes.dividerRoot }} />
                          </Grid>
                          <Grid className={classes.gridContainer} item xs={6}>
                            <SelectField
                              source="targetOfCalibration"
                              label={LABEL.TARGET_OF_CALIBRATION}
                              choices={[
                                {
                                  id: TARGET_OF_CALIBRATION.TARGET,
                                  name: TARGET_OF_CALIBRATION.TARGET
                                },
                                {
                                  id: TARGET_OF_CALIBRATION.EXCLUDED,
                                  name: TARGET_OF_CALIBRATION.EXCLUDED
                                }
                              ]}
                              fullWidth
                            />
                            {_.get(formData, 'targetOfCalibration') === TARGET_OF_CALIBRATION.TARGET && (
                              <Fragment>
                                <CustomDateField
                                  type={DATE_FIELD_TYPES.YY}
                                  label={LABEL.CALIBRATION_CYCLE}
                                  source="calibrationCycle"
                                  required={required}
                                />
                                <CustomDateField
                                  type={DATE_FIELD_TYPES.YYYY_MM_DD}
                                  label={LABEL.CALIBRATION_APPLIED_DATE}
                                  source="calibrationAppliedDate"
                                  required={required}
                                />
                                <CustomDateField
                                  type={DATE_FIELD_TYPES.YYYY_MM}
                                  label={LABEL.CALIBRATION_DATE}
                                  source="calibrationDate"
                                  required={required}
                                />
                              </Fragment>
                            )}
                          </Grid>
                          <Grid className={classes.gridContainer} item xs={6}>
                            <SelectField
                              source="calibrationResult"
                              label={LABEL.CALIBRATION_RESULT}
                              choices={[
                                { id: CALIBRATION_RESULT.PASS, name: CALIBRATION_RESULT.PASS },
                                { id: CALIBRATION_RESULT.FAILURE, name: CALIBRATION_RESULT.FAILURE }
                              ]}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                        <div className={classes.spacing} />
                        <Grid classes={{ container: classes.blockContainer }} container spacing={32}>
                          <Grid className={classes.gridContainer} item xs={12}>
                            <Typography component="h2" variant="h2" className={classes.headingSpacing} noWrap>
                              簿価情報
                            </Typography>
                            <Divider classes={{ root: classes.dividerRoot }} />
                          </Grid>
                          <Grid className={classes.gridContainer} item xs={6}>
                            <SelectField
                              source="fixedAssets"
                              label={LABEL.FIXED_ASSETS}
                              choices={[
                                { id: FIXED_ASSETS.TARGET, name: FIXED_ASSETS.TARGET },
                                { id: FIXED_ASSETS.EXCLUDED, name: FIXED_ASSETS.EXCLUDED }
                              ]}
                              fullWidth
                            />
                            {_.get(formData, 'fixedAssets') === FIXED_ASSETS.TARGET && (
                              <Fragment>
                                <BorderTextField
                                  format={formatPrice}
                                  normalize={normalizeAmount}
                                  source="originalPrice"
                                  label={`${LABEL.ORIGINAL_PRICE}`}
                                  fullWidth
                                  required={required}
                                />
                                <BorderTextField source="serviceLife" label={LABEL.SERVICE_LIFE} fullWidth />
                                <BorderTextField
                                  disabled
                                  format={formatPrice}
                                  normalize={normalizeAmount}
                                  source="realMarketValue"
                                  label={`${LABEL.REAL_MARKET_VALUE}`}
                                  message="耐用年数・購入価格・購入日を入力すると定額法で算出されます"
                                  fullWidth
                                />
                              </Fragment>
                            )}
                          </Grid>
                          <Grid className={classes.gridContainer} item xs={6}>
                            <CustomDateField
                              type={TYPE.PURCHASED_DATE}
                              label={LABEL.PURCHASED_DATE}
                              source="purchasedDate"
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                        <div className={classes.spacing} />
                        <Grid classes={{ container: classes.blockContainer }} container spacing={32}>
                          <Grid className={classes.gridContainer} item xs={12}>
                            <Typography component="h2" variant="h2" className={classes.headingSpacing} noWrap>
                              商品画像
                            </Typography>
                            <Divider classes={{ root: classes.dividerRoot }} />
                          </Grid>
                          <Grid className={classes.gridContainer} item xs={12}>
                            <ImageUploader source="images" label={LABEL.MERCHANDISE_IMAGE} />
                          </Grid>
                        </Grid>
                        <div className={classes.spacing} />
                        <Grid classes={{ container: classes.blockContainer }} container spacing={32}>
                          <Grid className={classes.gridContainer} item xs={12}>
                            <Typography component="h2" variant="h2" className={classes.headingSpacing} noWrap>
                              出品に必要な情報
                            </Typography>
                            <Divider classes={{ root: classes.dividerRoot }} />
                          </Grid>
                          <Grid className={classes.gridContainer} item xs={6}>
                            <SelectField
                              required={required}
                              source="statusOfAdjustment"
                              label={LABEL.STATUS_OF_ADJUSTMENT}
                              choices={utils.calibrations}
                              optionText="text"
                              optionValue="value"
                              fullWidth
                            />
                            {sellState === SELLSTATE.SELLING && (
                              <CustomDateField
                                type={DATE_FIELD_TYPES.YYYY_MM}
                                label={LABEL.EXPIRATION_OF_ADJUSTMENT}
                                source="expirationOfAdjustment"
                                required={
                                  _.get(formData, 'statusOfAdjustment') === ADJUSTMENT.CALIBRATED ? true : false
                                }
                              />
                            )}
                            <LongTextField
                              source="description"
                              label={LABEL.DESCRIPTION}
                              fullWidth
                              required={required}
                            />
                          </Grid>
                          <Grid className={classes.gridContainer} item xs={6}>
                            <CustomDateField source="year" label={LABEL.YEAR} type={DATE_FIELD_TYPES.YYYY} />
                            <LongTextField source="usaged" label={LABEL.USAGED} fullWidth required={required} />
                            <SelectField
                              source="delivery"
                              choices={utils.timeoptions}
                              optionText="text"
                              optionValue="value"
                              label={LABEL.DELIVERY}
                              fullWidth
                              required={required}
                            />
                            <BorderTextField
                              handleClick={this.openPriceSuggestionPoup}
                              source="price"
                              label={LABEL.PRICE}
                              suggest="価格設定に困ったらこちら"
                              fullWidth
                              required={required}
                            />
                          </Grid>
                        </Grid>
                      </Fragment>
                    )}
                  </FormDataConsumer>
                </SimpleForm>
              </Edit>
            );
          }}
        </EditController>
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getParentCategoriesRequest: () => dispatch(getParentCategoriesRequest()),
  getSubCategoriesRequest: data => dispatch(getSubCategoriesRequest(data)),
  getManufacturersRequest: data => dispatch(getManufacturersRequest(data)),
  setPopup: (formName, state) => dispatch(setPopup(formName, state)),
  getPrefectures: () => dispatch(getPrefecturesRequest())
});

const mapStateToProps = (state, ownProps) => ({
  parentCategories: _.get(state, 'merchandiseReducer.parentCategories', []),
  subCategories: _.get(state, 'merchandiseReducer.subCategories', []),
  manufacturers: _.get(state, 'merchandiseReducer.manufacturers', []),
  merchandises: _.get(state, `admin.resources.${ownProps.resource}.data`),
  sellState: _.get(state, 'form.record-form.values.sellState'),
  prefectures: _.get(state, 'merchandiseReducer.prefectures', [])
});

MerchandiseEdit.propTypes = {
  classes: PropTypes.any,
  getParentCategoriesRequest: PropTypes.func,
  getSubCategoriesRequest: PropTypes.func,
  getManufacturersRequest: PropTypes.func,
  parentCategories: PropTypes.array,
  subCategories: PropTypes.array,
  manufacturers: PropTypes.array,
  merchandises: PropTypes.object,
  id: PropTypes.any,
  history: PropTypes.any,
  crudGetOne: PropTypes.func,
  sellState: PropTypes.string,
  setPopup: PropTypes.func,
  getPrefectures: PropTypes.func,
  prefectures: PropTypes.array
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(MerchandiseEdit);
