import { GET_ONE } from 'react-admin';
import _ from 'lodash';

export default async (axiosInstance, type, params) => {
  switch (type) {
    case GET_ONE: {
      return axiosInstance.post('/functions/getCurrentUser').then(response => {
        const data = _.get(response, 'data.result', {});
        _.set(data, 'id', params.id);
        return Promise.resolve({
          data
        });
      });
    }
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }
};
