import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import EmptyIcon from 'assets/empty-user.svg';
import STYLE from 'styles/_constants.scss';
import { ADMIN_MANAGEMENT } from 'helpers/Const';
const styles = () => ({
  emptyList: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  emptyText: {
    lineHeight: '1.29',
    fontFamily: STYLE.fontFamily,
    fontSize: '14px',
    textAlign: 'center',
    color: '#aaaaaa',
    whiteSpace: 'pre-wrap'
  },
  emptyListIcon: {}
});
const EmptyList = props => {
  const { classes = {} } = props;
  return (
    <div className={classes.emptyList}>
      <img alt="empty" className={classes.emptyListIcon} src={EmptyIcon} />
      <div className={classes.emptyText}>{ADMIN_MANAGEMENT.EMPTY_TEXT}</div>
    </div>
  );
};
EmptyList.propTypes = {
  classes: PropTypes.object
};
export default withStyles(styles)(EmptyList);
