import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { CreateButton } from 'react-admin';
import CreateIcon from 'assets/create.svg';
import { withStyles } from '@material-ui/core';
import { LABEL } from 'helpers/Const';
import STYLE from 'styles/_constants.scss';
import compose from 'recompose/compose';
const Create = () => <img alt="create" src={CreateIcon} />;
const styles = () => ({
  btnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '24px',
    fontFamily: `${STYLE.fontFamily}`
  },
  rightBtn: {
    display: 'flex'
  },
  leftBtn: {
    display: 'flex',
    alignItems: 'center'
  },
  btn: {
    height: '46px',
    borderRadius: '4px',
    padding: 0,
    fontFamily: `${STYLE.fontFamily}`,
    fontSize: '14px',
    lineHeight: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },
  createBtn: {
    width: '224px',
    marginLeft: '16px',
    backgroundColor: '#202a6b',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#202a6b'
    },
    '@media (max-width: 1077px)': {
      width: '145px'
    }
  }
});

class PostActions extends React.Component {
  render() {
    const {
      bulkActions,
      basePath,
      displayedFilters,
      filters,
      filterValues,
      onUnselectItems,
      resource,
      selectedIds,
      showFilter,
      classes
    } = this.props;
    return (
      <Fragment>
        {bulkActions &&
          React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems
          })}
        {filters &&
          React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button'
          })}
        <div className={classes.btnContainer}>
          <div className={classes.leftBtn}></div>
          <div className={classes.rightBtn}>
            <CreateButton
              icon={<Create />}
              label={LABEL.CREATE_BTN}
              className={`${classes.createBtn} ${classes.btn}`}
              basePath={basePath}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}
PostActions.propTypes = {
  bulkActions: PropTypes.any,
  basePath: PropTypes.any,
  displayedFilters: PropTypes.any,
  filters: PropTypes.any,
  filterValues: PropTypes.any,
  onUnselectItems: PropTypes.any,
  resource: PropTypes.any,
  selectedIds: PropTypes.array,
  showFilter: PropTypes.any,
  total: PropTypes.any,
  classes: PropTypes.object,
  setPopup: PropTypes.func
};
export default compose(withStyles(styles))(PostActions);
