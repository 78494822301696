import React from 'react';
import PropTypes from 'prop-types';

import { List } from 'components/CustomList';
import { withStyles } from '@material-ui/core/styles';
import TransactionFilter from './components/TransactionFilter';
import { DATE_FORMAT, RESOURCES, LABEL } from 'helpers/Const';
import { CustomDatagrid } from 'components/CustomDatagrid';
import _ from 'lodash';
import TransactionStatus from 'components/StatusField/TransactionStatus';
import TextFieldForGrid from 'components/CustomFieldForGrid/TextField';
import PostShow from './components/ExpandDetail';
import LogIdField from 'components/CustomFieldForGrid/LogIdField';
import './style.scss';
import DateFormatField from 'components/DateFormatField';
import ImageField from 'components/CustomFieldForGrid/ImageField';

const styles = {
  image: { maxHeight: '1rem' },
  card: {
    minWidth: 275
  },
  toolbar: {
    display: 'unset',
    padding: 0
  }
};

const MerchandiseList = props => {
  const { classes } = props;
  const listProps = _.omit(props, ['dispatch', 'classes']);
  const gridProps = _.omit(listProps, ['hasShow', 'hasList', 'hasEdit']);
  return (
    <List
      bulkActionButtons={false}
      filters={<TransactionFilter title={RESOURCES.TRANSACTION.label} resource={RESOURCES.TRANSACTION.name} />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      classes={{ card: classes.card, toolbar: classes.toolbar }}
      {...listProps}
    >
      <CustomDatagrid
        tableClassName="transaction-table"
        expand={<PostShow />}
        fixedColNumber={0}
        colWidth={155}
        {...gridProps}
        rowClick="show"
      >
        <LogIdField className="cell-content id-text-field" source="logId" label={LABEL.LOG_ID_TITLE} />
        <DateFormatField
          className="cell-content"
          source="createdAt"
          label={LABEL.TRADING_DATE}
          dateFormat={DATE_FORMAT.DIV}
        />
        <ImageField label={LABEL.TRANSACTION_IMAGE} source="merchImg" />
        <TextFieldForGrid className="cell-content" source="merchandiseName" label={LABEL.MERCHANDISE_NAME} />
        <TextFieldForGrid className="cell-content" source="buyerCompany" label={LABEL.BUYER_COMPANY_TITLE} />
        <TransactionStatus className="cell-content" source="status" label={LABEL.STATUS} />
      </CustomDatagrid>
    </List>
  );
};

MerchandiseList.propTypes = {
  selectedIds: PropTypes.any,
  dispatch: PropTypes.any,
  classes: PropTypes.object
};

export default withStyles(styles)(MerchandiseList);
