module.exports = {
  TYPE: {
    DATE_OF_USE: 'DATE_OF_USE',
    CALIBRATION_CYCLE: 'CALIBRATION_CYCLE',
    CALIBRATION_APPLIED_DATE: 'CALIBRATION_APPLIED_DATE',
    CALIBRATION_DATE: 'CALIBRATION_DATE',
    PURCHASED_DATE: 'PURCHASE_DATE'
  },
  HOLDING_CATEGORY: {
    IN_HOUSE_PURCHASE: '自社購入',
    RENTAL: 'レンタル',
    LEASE: 'リース'
  },
  TARGET_OF_CALIBRATION: {
    TARGET: '対象',
    EXCLUDED: '対象外'
  },
  CALIBRATION_RESULT: {
    PASS: '合格',
    FAILURE: '不合格'
  },
  FIXED_ASSETS: {
    TARGET: '対象',
    EXCLUDED: '対象外'
  }
};
