import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import _ from 'lodash';
import { setPopup } from 'containers/Popup/actions';
import PropTypes from 'prop-types';
import Close from 'assets/quit.svg';
import STYLE from 'styles/_constants.scss';
import { Button } from '@material-ui/core';

const styles = () => ({
  root: {
    width: '800px',
    margin: 0,
    maxWidth: 'unset',
    borderRadius: '4px',
    backgroundColor: '#FFFFFF'
  },
  dialogTitle: {
    marginTop: '56px',
    padding: '0px',
    fontSize: '40px',
    color: '#3c3c3c',
    lineHeight: '56px',
    fontFamily: "'Sawarabi Mincho', serif",
    display: 'flex',
    justifyContent: 'center'
  },
  closeBtn: {
    position: 'absolute',
    top: '33px',
    right: '33px',
    cursor: 'pointer'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '40px',
    padding: 0,
    fontFamily: STYLE.fontFamily
  },
  normal: {
    fontSize: '14px',
    color: '#3c3c3c'
  },
  warning: {
    color: '#9b1d20',
    fontSize: '16px'
  },
  text: {
    textAlign: 'center',
    whiteSpace: 'pre-line'
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '40px'
  },
  subBtnContainer: {
    marginTop: '16px'
  },
  okBtn: {
    '&:hover': {
      backgroundColor: '#202a6a'
    },
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '4px',
    backgroundColor: '#202a6a',
    color: '#ffffff',
    width: '224px',
    height: '48px',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: '40px'
  }
});
class ConfirmPopup extends Component {
  handleClose = () => {
    this.props.setPopup('confirmPopup', false);
  };
  handleClick = () => {
    const callback = _.get(this.props, 'confirmPopup.callback', () => {});
    this.props.setPopup('confirmPopup', false);
    callback();
  };
  render() {
    const { confirmPopup = false, classes = {} } = this.props;
    const show = confirmPopup ? true : false;
    const icon = _.get(this.props, 'confirmPopup.icon');
    const disabled = _.get(this.props, 'confirmPopup.disabled', false);
    const { title, content, subBtn, showClose = true } = confirmPopup;
    const type = _.get(this.props, 'confirmPopup.type', 'normal');
    return (
      <Dialog classes={{ paper: classes.root }} className="common-dialog" fullWidth open={show}>
        {showClose && <img onClick={this.handleClose} alt="close-btn" className={classes.closeBtn} src={Close} />}
        <div className={classes.contentContainer}>
          {title && (
            <DialogTitle disableTypography={true} classes={{ root: classes.dialogTitle }}>
              {title}
            </DialogTitle>
          )}
          <DialogContent className={classes.content}>
            {icon && icon}
            {content && <div className={`${classes.text} ${classes[type]}`}>{content}</div>}
            {subBtn && <div className={classes.subBtnContainer}>{subBtn}</div>}
            <div className={classes.btnContainer}>
              <Button disabled={disabled} className={classes.okBtn} onClick={this.handleClick}>
                OK
              </Button>
            </div>
          </DialogContent>
        </div>
      </Dialog>
    );
  }
}
ConfirmPopup.propTypes = {
  confirmPopup: PropTypes.any,
  classes: PropTypes.object,
  setPopup: PropTypes.func
};
const mapDispatchToProps = dispatch => {
  return {
    setPopup: (popupName, state) => dispatch(setPopup(popupName, state))
  };
};
const mapStateToProps = state => {
  return {
    confirmPopup: _.get(state, 'popup.confirmPopup')
  };
};
export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(ConfirmPopup);
