import {
  GET_PENDING_MEMBERS,
  GET_PENDING_MEMBERS_SUCCESS,
  GET_PENDING_MEMBERS_ERROR,
  SEND_ADMIN_INVITE,
  SEND_ADMIN_INVITE_SUCCESS,
  SEND_ADMIN_INVITE_ERROR,
  DELETE_PEDNING_ADMIN,
  DELETE_PEDNING_ADMIN_SUCCESS,
  DELETE_PEDNING_ADMIN_ERROR
} from './constants';
export const getPendingMembersRequest = data => {
  return {
    type: GET_PENDING_MEMBERS,
    data
  };
};

export const getPendingMembersSuccess = data => {
  return {
    type: GET_PENDING_MEMBERS_SUCCESS,
    data
  };
};
export const getPendingMembersError = data => {
  return {
    type: GET_PENDING_MEMBERS_ERROR,
    data
  };
};

export const submitAdminInviteRequest = data => {
  return {
    type: SEND_ADMIN_INVITE,
    data
  };
};

export const submitAdminInviteSuccess = data => {
  return {
    type: SEND_ADMIN_INVITE_SUCCESS,
    data
  };
};

export const submitAdminInviteError = data => {
  return {
    type: SEND_ADMIN_INVITE_ERROR,
    data
  };
};
export const deletePendingAdmin = data => {
  return {
    type: DELETE_PEDNING_ADMIN,
    data
  };
};
export const deletePendingAdminSuccess = data => {
  return {
    type: DELETE_PEDNING_ADMIN_SUCCESS,
    data
  };
};
export const deletePendingAdminError = error => {
  return {
    type: DELETE_PEDNING_ADMIN_ERROR,
    error
  };
};
