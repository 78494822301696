import {
  GET_PENDING_MEMBERS,
  GET_PENDING_MEMBERS_SUCCESS,
  GET_PENDING_MEMBERS_ERROR,
  SEND_ADMIN_INVITE,
  SEND_ADMIN_INVITE_SUCCESS,
  SEND_ADMIN_INVITE_ERROR,
  DELETE_PEDNING_ADMIN_SUCCESS,
  DELETE_PEDNING_ADMIN_ERROR
} from './constants';
import produce from 'immer';

const initialState = {
  pendingMembers: [],
  error: null
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case GET_PENDING_MEMBERS:
      state.pendingMembers = [];
      return;
    case GET_PENDING_MEMBERS_SUCCESS:
      state.pendingMembers = action.data;
      return;
    case GET_PENDING_MEMBERS_ERROR:
      state.error = action.error;
      return;
    case SEND_ADMIN_INVITE:
      return;
    case SEND_ADMIN_INVITE_SUCCESS:
      state.pendingMembers = action.data;
      return;
    case SEND_ADMIN_INVITE_ERROR:
      state.error = action.error;
      return;
    case DELETE_PEDNING_ADMIN_SUCCESS:
      state.pendingMembers = action.data;
      return;
    case DELETE_PEDNING_ADMIN_ERROR:
      state.error = action.error;
      return;
    default:
      return state;
  }
});
const userReducer = {
  userReducer: reducer
};

export default userReducer;
