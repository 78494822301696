import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MenuItemLink, getResources, Responsive } from 'react-admin';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import compose from 'recompose/compose';
import _ from 'lodash';
import { TERMS_OF_USE, POLICY, TERMS_OF_USE_URL, POLICY_URL, LIMIT_PER_PAGE } from 'helpers/Const';
import STYLE from 'styles/_constants.scss';

const styles = () => ({
  horiLine: {
    width: '256px',
    border: '0.5px solid  #dddddd',
    marginTop: '0px',
    marginBottom: '40px'
  },
  linkSection: {
    display: 'flex',
    color: '#fff',
    justifyContent: 'center',
    fontSize: '14px',
    fontFamily: `${STYLE.fontFamily}`
  },
  externalLink: {
    color: '#fff',
    textDecoration: 'none',
    fontSize: '14px'
  },
  externalLinkSection: {
    position: 'absolute',
    bottom: 40,
    width: '100%'
  },
  displayNone: {
    display: 'none'
  },
  customDrawer: {
    width: '320px',
    height: 'calc(100vh - 96px)',
    overflow: 'hidden',
    '@media (max-width: 1024px)': {
      width: '240px'
    }
  },
  MenuItemLink: {
    height: '72px',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    marginLeft: '16px',
    paddingLeft: '16px',
    fontFamily: `${STYLE.fontFamily}`,
    marginTop: '16px',
    marginRight: '16px'
  },
  noIcon: {
    paddingLeft: '64px'
  }
});

class CustomMenu extends React.Component {
  state = {
    expanded: undefined,
    resourceCounts: {}
  };

  toggleCategory = category => () => {
    this.setState({ expanded: category });
  };

  onMenuClick = collapseMenus => () => {
    collapseMenus && this.setState({ expanded: undefined });
    this.props.onMenuClick && this.props.onMenuClick();
  };

  render() {
    let { resources, logout, classes, toggleState } = this.props;
    return (
      <div className={classes.customDrawer}>
        {resources.map((resource, index) => {
          if (!resource.options.label) {
            return undefined;
          }
          if (resource.options && resource.options.category) {
            if (this.state.expanded !== resource.options.category) {
              return undefined;
            }
          }
          const linkText = resource.options && resource.options.label ? resource.options.label : resource.name;
          const linkTo = resource.options && resource.options.linkTo ? resource.options.linkTo : resource.name;
          return (
            <MenuItemLink
              classes={{
                root: `${classes.MenuItemLink} ${!resource.icon && classes.noIcon}`
              }}
              key={index}
              style={
                (resource.options.category && {
                  backgroundColor: '#d6d6d6'
                },
                {
                  color: 'white'
                })
              }
              leftIcon={resource.icon ? <resource.icon /> : undefined}
              to={`/${linkTo}?filter=%7B%7D&order=DESC&page=1&perPage=${LIMIT_PER_PAGE}&sort=createdAt`}
              primaryText={linkText}
              onClick={
                resource.options.controlCategory
                  ? this.toggleCategory(resource.options.controlCategory)
                  : this.onMenuClick(!resource.options.category)
              }
            />
          );
        })}
        <div className={classes.externalLinkSection}>
          <hr className={classes.horiLine} />
          <div className={classnames(classes.linkSection, !toggleState && classes.displayNone)}>
            <Link className={classes.externalLink} to={TERMS_OF_USE_URL}>
              {TERMS_OF_USE}
            </Link>
            /
            <Link className={classes.externalLink} to={POLICY_URL}>
              {POLICY}
            </Link>
          </div>
        </div>
        <Responsive xsmall={logout} medium={null} />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  resources: getResources(state),
  toggleState: _.get(state, 'admin.ui.sidebarOpen')
});

CustomMenu.propTypes = {
  onMenuClick: PropTypes.func,
  resources: PropTypes.array,
  logout: PropTypes.object,
  classes: PropTypes.object,
  toggleState: PropTypes.bool
};

export default withRouter(compose(connect(mapStateToProps, null), withStyles(styles))(CustomMenu));
