import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core';
import { change, submit } from 'redux-form';
import { connect } from 'react-redux';
import _ from 'lodash';
import { setPopup } from 'containers/Popup/actions';
import PropTypes from 'prop-types';
import Close from 'assets/quit.svg';
import { bulkDelete } from 'containers/Merchandise/MerchandiseList/actions';
import Unchecked from 'assets/uncheck.svg';
import Checked from 'assets/checked.svg';
import { DELETE_MESSAGE } from 'helpers/Const';
import STYLE from 'styles/_constants.scss';
const styles = () => ({
  root: {
    width: '800px',
    margin: 0,
    maxWidth: 'unset',
    borderRadius: '4px',
    backgroundColor: '#FFFFFF'
  },
  closeBtn: {
    position: 'absolute',
    top: '33px',
    right: '33px',
    cursor: 'pointer'
  },
  contentContainer: {
    margin: '56px 168px 40px 168px'
  },
  dialogTitle: {
    padding: '0px',
    fontSize: '40px',
    color: '#3c3c3c',
    lineHeight: '56px',
    fontFamily: STYLE.fontFamilyTitle,
    display: 'flex',
    justifyContent: 'center'
  },
  dialogContent: {
    color: '#3c3c3c',
    marginTop: '40px',
    fontSize: '16px',
    padding: 0,
    fontFamily: STYLE.fontFamily
  },
  checkboxSection: {
    padding: '4px 16px 4px 4px',
    marginTop: '32px',
    backgroundColor: '#f5f6fa',
    width: '364px',
    height: '80px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: 'auto',
    marginLeft: 'auto'
  },
  noCheckbox: {
    width: '248',
    justifyContent: 'center'
  },
  leftSection: {
    display: 'flex',
    alignItems: 'center'
  },
  amountText: {
    fontWeight: 'bold',
    marginLeft: '11.5px'
  },
  checkbox: {
    width: '24px',
    height: '24px',
    marginRight: '16px',
    cursor: 'pointer'
  },
  unchecked: {
    backgroundImage: `url(${Unchecked})`
  },
  checked: {
    backgroundImage: `url(${Checked})`
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '40px'
  },
  btn: {
    width: '224px',
    height: '48px',
    borderRadius: '4px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  cancelBtn: {
    backgroundColor: '#ffffff',
    border: 'solid 1px #202a6a',
    color: '#202a6a',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 'bold'
  },
  deleteBtn: {
    backgroundColor: '#9b1d20',
    color: '#ffffff',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '24px',
    marginLeft: '16px'
  },
  disabled: {
    opacity: 0.5
  },
  textContent: {
    whiteSpace: 'pre-line',
    textAlign: 'center'
  }
});
class DeletePopup extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      checked: false
    };
  }
  handleClose = () => {
    this.props.setPopup('deletePopup', false);
    this.setState({ checked: false });
  };
  handleCheck = () => {
    this.setState({ checked: !this.state.checked });
  };
  bulkDelete = selectedIds => {
    const search = _.get(this.props, 'search');
    this.props.bulkDelete(selectedIds, '/merchandise', search);
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.deletePopup && this.props.deletePopup === false) {
      this.setState({ checked: false });
    }
    const hasCheck = _.get(this.props.deletePopup, 'hasCheck', true);
    if (!hasCheck && prevState.checked === false) {
      this.setState({ checked: true });
    }
  }
  render() {
    const { deletePopup = false, classes = {} } = this.props;
    const { title, content, icon, warningText, hasCheck = true, deleteCallback = () => {} } = deletePopup;
    const { checked } = this.state;
    const show = deletePopup ? true : false;
    return (
      <Dialog classes={{ paper: classes.root }} className={classes.deteteDialog} fullWidth open={show}>
        <img onClick={this.handleClose} alt="close-btn" className={classes.closeBtn} src={Close} />
        <div className={classes.contentContainer}>
          {title && (
            <DialogTitle disableTypography={true} classes={{ root: classes.dialogTitle }}>
              {title}
            </DialogTitle>
          )}
          <DialogContent className={classes.dialogContent}>
            {content && <div className={classes.textContent}>{content}</div>}
            <div className={`${classes.checkboxSection} ${!hasCheck && classes.noCheckbox}`}>
              <div className={classes.leftSection}>
                {icon && icon}
                <div className={classes.amountText}>{warningText}</div>
              </div>
              {hasCheck === true && (
                <div
                  onClick={this.handleCheck}
                  className={`${checked ? classes.checked : classes.unchecked} ${classes.checkbox}`}
                ></div>
              )}
            </div>
            <div className={classes.btnContainer}>
              <div onClick={this.handleClose} className={`${classes.cancelBtn} ${classes.btn}`}>
                {DELETE_MESSAGE.CANCEL}
              </div>
              <div
                onClick={deleteCallback}
                className={`${!checked && classes.disabled} ${classes.btn} ${classes.deleteBtn}`}
              >
                {DELETE_MESSAGE.DELETE}
              </div>
            </div>
          </DialogContent>
        </div>
      </Dialog>
    );
  }
}
DeletePopup.propTypes = {
  deletePopup: PropTypes.any,
  classes: PropTypes.object,
  setPopup: PropTypes.func,
  bulkDelete: PropTypes.func
};
const mapDispatchToProps = dispatch => {
  return {
    bulkDelete: (ids, basepath) => dispatch(bulkDelete(ids, basepath, dispatch)),
    changeFormValue: (formName, fieldName, value) => dispatch(change(formName, fieldName, value)),
    submit: formName => dispatch(submit(formName)),
    setPopup: (formName, state) => dispatch(setPopup(formName, state))
  };
};
const mapStateToProps = state => {
  const resource = _.get(state, 'popup.deletePopup.resource');
  const selectedIds = _.get(state, 'popup.deletePopup.selectedIds');
  return {
    deletePopup: _.get(state, 'popup.deletePopup'),
    selectedIds: selectedIds ? selectedIds : _.get(state, `admin.resources.${resource}.list.selectedIds`),
    search: _.get(state, 'router.location.search')
  };
};
export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(DeletePopup);
