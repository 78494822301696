import produce from 'immer';
import { SET_POPUP } from './actions';

const initialState = {};
const popup = produce((state = initialState, action) => {
  switch (action.type) {
    case SET_POPUP:
      state[action.key] = action.open;
      return;
    default:
      return state;
  }
});
const popupReducer = {
  popup
};

export default popupReducer;
