import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import InvitationForm from './InvitationForm';
import './style.scss';
import { getPendingMembersRequest, submitAdminInviteRequest } from '../actions';
import { connect } from 'react-redux';
import PendingAdminList from 'containers/Admin/AdminList/components/PendingAdminList';
import { withStyles } from '@material-ui/core';
import STYLE from 'styles/_constants.scss';
import { ADMIN_MANAGEMENT } from 'helpers/Const';
import DeletePopup from 'components/DeletePopup';
import ConfirmPopup from 'components/ConfirmPopup';
const styles = () => ({
  adminListPage: {
    width: 'calc(100vw - 436px)'
  },
  contentContainer: {
    display: 'flex',
    marginTop: '32px',
    '@media (max-width: 1280px)': {
      display: 'block',
      width: 'calc(100vw - 436px)'
    },
    '@media (max-width: 1024px)': {
      display: 'block',
      width: 'calc(100vw - 339px)'
    }
  },
  pendingTable: {
    marginLeft: '48px',
    height: 'fit-content',
    width: 'calc(100% - 560px)',
    '@media (max-width: 1280px)': {
      width: '100%',
      marginLeft: '0px',
      marginTop: '24px'
    }
  },
  pageTitle: {
    fontFamily: STYLE.fontFamilyTitle,
    color: '#3c3c3c',
    lineHeight: '56px',
    fontSize: '40px'
  }
});
class AdminList extends React.Component {
  componentDidMount() {
    this.props.getPendingMembersRequest();
    const { currentUserRole = '' } = this.props;
    if (currentUserRole === 'SUBMEMBER') {
      this.props.history.push('/admin');
    }
  }
  handleSubmit = async values => {
    const params = _.pick(values, 'adminAddress', 'adminName');
    await this.props.submitAdminInviteRequest(params);
  };
  render() {
    const initialValues = { role: 'アドミン' };
    const { classes = {} } = this.props;
    return (
      <div className={classes.adminListPage}>
        <DeletePopup />
        <ConfirmPopup />
        <div className={classes.pageTitle}>{ADMIN_MANAGEMENT.REG_AND_DEL_TITLE}</div>
        <div className={classes.contentContainer}>
          <InvitationForm className={classes.inviteForm} onSubmit={this.handleSubmit} initialValues={initialValues} />
          <PendingAdminList className={classes.pendingTable} />
        </div>
      </div>
    );
  }
}

AdminList.propTypes = {
  selectedIds: PropTypes.any,
  dispatch: PropTypes.any,
  getPendingMembersRequest: PropTypes.func,
  submitAdminInviteRequest: PropTypes.func,
  history: PropTypes.object,
  classes: PropTypes.object,
  currentUserRole: PropTypes.string
};
const mapStateToProps = state => {
  return {
    currentUserRole: _.get(state, 'admin.resources.user.data.currentUser.roleName')
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getPendingMembersRequest: () => dispatch(getPendingMembersRequest()),
    submitAdminInviteRequest: values => dispatch(submitAdminInviteRequest(values))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AdminList));
