import { GET_MANUFACTURERS_REQUEST } from 'containers/Merchandise/constants';
import _ from 'lodash';

export default (axiosInstance, type, params) => {
  switch (type) {
    case GET_MANUFACTURERS_REQUEST:
      return axiosInstance.post('/functions/getListManufacturer', params).then(response => {
        const data = _.get(response, 'data.result');
        return data.data;
      });
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }
};
