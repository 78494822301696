module.exports = {
  ra: {
    action: {
      add_filter: 'フィルターを追加',
      add: '追加',
      back: '戻る',
      bulk_actions: '1つのアイテムを選択 |||| %{smart_count}選択されたアイテム',
      cancel: 'キャンセル',
      clear_input_value: '値をクリア',
      clone: 'コピー',
      confirm: '確認する',
      create: '新しく追加する',
      delete: '削除',
      edit: '編集',
      export: 'エクスポート',
      list: 'リスト',
      refresh: 'リフレッシュ',
      remove_filter: 'このフィルターを削除',
      remove: '削除',
      save: 'セーブ',
      search: '検索',
      show: '表示',
      sort: 'ソート',
      undo: '元に戻す',
      expand: '展開する',
      close: '閉じる'
    },
    boolean: {
      true: 'Yes',
      false: 'No'
    },
    page: {
      create: '%{name}作成する',
      dashboard: 'ダッシュボード',
      edit: '%{name} #%{id}',
      error: '原因不明のエラー',
      list: '%{name}',
      loading: 'ローディング',
      not_found: '見つかりませんでした',
      show: '%{name} #%{id}'
    },
    input: {
      file: {
        upload_several: 'アップロードするファイルをドラッグ＆ドロップまたはクリックして選択してください',
        upload_single: 'アップロードするファイルをドラッグ＆ドロップまたはクリックして選択してください'
      },
      image: {
        upload_several: 'アップロードする画像をドラッグ＆ドロップまたはクリックして選択してください',
        upload_single: 'アップロードする画像をドラッグ＆ドロップまたはクリックして選択してください'
      },
      references: {
        all_missing: '参照データが見つかりません',
        many_missing: '少なくとも1つの関連する参照が使用できなくなっています',
        single_missing: '関連する参照は使用できなくなっています'
      }
    },
    message: {
      about: 'About',
      are_you_sure: '続行してもよろしいですか?',
      bulk_delete_content: '削除してもよろしいですか ||||これらを削除してもよろしいですか？',
      bulk_delete_title: '%{name} 削除|||| 削除 %{smart_count} %{name}',
      delete_content: 'このアイテムを削除してもよろしいですか？',
      delete_title: '削除 %{name} #%{id}',
      details: 'Details',
      error: 'エラーが発生し処理を完了できませんでした',
      invalid_form: '出品に必要な項目が未入力です。エラーをご確認ください',
      loading: 'ロードしています。しばらくお待ちください',
      no: 'No',
      not_found: '指定されたページは存在しませんでした',
      yes: 'Yes'
    },
    navigation: {
      no_results: '出品中の商品はありません',
      no_more_results: 'ページ番号％{page}は境界外です。前のページをお試しください',
      page_out_of_boundaries: 'ページ番号％{page}は境界外です',
      page_out_from_end: '次の最後のページに移動できません',
      page_out_from_begin: '1ページ前に移動できません',
      page_range_info: '%{offsetBegin}-%{offsetEnd} of %{total}',
      page_rows_per_page: '1ページに',
      next: 'Next',
      prev: 'Prev'
    },
    auth: {
      user_menu: 'プロフィール',
      username: 'ユーザーネーム',
      password: 'パスワード',
      sign_in: 'ログイン',
      sign_in_error: 'Authentication failed, please retry',
      logout: 'ログアウト'
    },
    notification: {
      updated: '登録情報を更新しました |||| %{smart_count} 登録情報を更新しました',
      created: '商品情報を登録しました',
      deleted: '情報を削除しました',
      bad_item: '不正な情報',
      item_doesnt_exist: 'その情報は存在しません',
      http_error: 'サーバー通信エラー',
      data_provider_error: '詳細についてはコンソールを確認してください',
      canceled: 'アクションがキャンセルされました',
      logged_out: 'セッションが終了しました。再接続してください'
    },
    validation: {
      required: '必須',
      minLength: '最少％{min}文字が必要です',
      maxLength: '最大％{max}文字までです',
      minValue: '少なくとも％{min}でなければなりません',
      maxValue: '％{max}以下でなければなりません',
      number: '数字で入力してください',
      email: '有効なメールにしてください',
      oneOf: '次のいずれかでなければなりません： %{options}',
      regex: '特定の形式に一致させる必要があります（regexp）: %{pattern}'
    }
  }
};
