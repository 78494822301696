import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';
import STYLE from 'styles/_constants.scss';
import DownloadIcon from 'assets/download.svg';
import PropsTypes from 'prop-types';
const styles = () => ({
  btn: {
    minWidth: '112px',
    minHeight: '32px',
    fontWeight: 'bold',
    fontSize: '12px',
    padding: '0px 0px',
    fontFamily: `${STYLE.fontFamily}`,
    color: '#202a6a',
    border: 'solid 1px #202a6a',
    backgroundColor: '#ffffff',
    '&:hover': {
      backgroundColor: '#ffffff'
    }
  },
  btnIcon: {
    width: '16px',
    height: '16px',
    marginRight: '4px'
  }
});

class DownloadBtn extends React.Component {
  render() {
    const { text = '', link = '', classes = {}, className = '', download = true } = this.props;
    return (
      <Button href={link} download={download} className={`${classes.btn} ${className}`}>
        <img className={classes.btnIcon} alt="download" src={DownloadIcon} />
        {text}
      </Button>
    );
  }
}
DownloadBtn.propTypes = {
  text: PropsTypes.string,
  link: PropsTypes.string,
  classes: PropsTypes.object,
  className: PropsTypes.string,
  fileUrl: PropsTypes.string,
  download: PropsTypes.bool
};
export default withStyles(styles)(DownloadBtn);
